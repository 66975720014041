import React, {useState, useEffect} from 'react';
import {Tag} from 'antd';

const TagRenderer = (props) => {
    const {context, value} = props;
    const [eventId, setEventId] = useState(context.eventId);
    const [tagsValue, setTagsValue] = useState({});

    useEffect(() => {
        if (context.eventId !== eventId) {
            setEventId(context.eventId);
        }
        if (value && typeof value === 'object') {
            setTagsValue(value[context.eventId] || {});
        }
    }, [context.eventId, eventId, value]);

    const renderTags = () => {

        const tagsArray = Object.entries(tagsValue);

        // Sort the array by value in descending order
        tagsArray.sort((a, b) => b[1] - a[1]);

        // Map the sorted array to the desired format
        return tagsArray.map(([key, value]) => {
            let color = '';
            let opacity = 0.8;

            // Define color based on value
            if (value === '2') {
                color = '#90ee90';
            } else if (value === '1') {
                color = '#c0f3c0';
            } else if (value === '-1') {
                color = '#efc1c1';
            } else if (value === '-2') {
                color = '#f08080';
            }


            // // Define color based on value
            // if (value === '2') {
            //     color = '#767676';
            // } else if (value === '1') {
            //     color = '#9f9f9f';
            // } else if (value === '-1') {
            //     color = '#c0c0c0';
            // } else if (value === '-2') {
            //     color = '#ffffff';
            // }


            // Adjust opacity for all colors
            const tagStyle = {
                marginTop: -8,
                marginBottom: 0,
                backgroundColor: color,
                opacity: opacity,
            };

            // Render tag only if value is not '0'
            // return value !== '0' && (
            return (
                <Tag key={key} style={tagStyle}>
                    {`${key}`}
                </Tag>
            );
        });
    };

    return (
        <div style={{whiteSpace: 'normal'}}>
            {renderTags()}
        </div>
    );
};

export default TagRenderer;