import React from "react";
import { createRoot } from 'react-dom/client';
import ImageRenderer from "../ImageRenderer/ImageRenderer"

const createImageSpan = (imagesObj) => {
  const imageTable = document.createElement('table');
  const cellsPerRow = 4
  let imageRow = imageTable.insertRow();
  if (imagesObj) {
    for (let i = 0; i < imagesObj.length; i++) {

      if (i % cellsPerRow === 0 && i > 0) {
        imageRow = imageTable.insertRow();
      }

      const imageCell = imageRow.insertCell();
      const imageElementDiv = document.createElement('div');
      imageElementDiv.className = "popup";
      const imageElement = createRoot(imageElementDiv);
      imageElement.render(<ImageRenderer value={imagesObj[i]}></ImageRenderer>);
      imageCell.appendChild(imageElementDiv);
    }
  }
  return imageTable;
};

export default class MultiImageRenderer {
  init(props) {
    if ("value" in props) {
      const images = JSON.parse(props.value);
      this.eGui = createImageSpan(images);
    }
  }

  getGui() {
    return this.eGui;
  }
}

