import React, {useState, useEffect} from 'react';

import { Line } from '@ant-design/plots';
import {Descriptions, Table, Card, Form, Input, Popover, Button, Col} from 'antd';

const LineZoomChart = (props) => {

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const data = props.data

  let config = {
          data,
          autoFit: false,
          xField: 'time',
          yField: props.yValue,
          interactions: [{ type: 'element-selected' }],
          xAxis: props.showXAxis ? {} : { label: null }, // add the xAxis property conditionally
          yAxis: {
             title: { text: props.yValue },
      }
  };
  if (props.yValue === 'GPS') {
      config = {
          data,
          autoFit: false,
          xField: 'time',
          yField: 'value',
          seriesField: 'category',
          interactions: [{ type: 'element-selected' }],
          xAxis: props.showXAxis ? {} : { label: null }, // add the xAxis property conditionally
          yAxis: {
             title: { text: "GPS Speed" },
          },
          legend: false // add this line to deactivate the legend
      };
  }

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <>
        <div style={{ height: props.height }}>
          <Line
            {...config}
            key={windowSize.width}
          />
        </div>
    </>
  )
    ;
};

export default LineZoomChart;