import React, {useState, useEffect} from 'react';
import {Tag, Tooltip} from 'antd';
import {useAuth} from "react-oidc-context";

const CommentRenderer = (props) => {
    const {context, value} = props;
    const eventId = context.eventId;
    const auth = useAuth();

    const getTooltipContent = (user, created) => {
        if (user && created) {
            const formattedDatetime = new Date(created).toLocaleString('de-DE', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                hour12: false
            }).replace(',', '');

            if (user !== auth?.user?.profile?.name) {
                return `Created by ${user} - ${formattedDatetime}`
            } else {
                return `Created by you - ${formattedDatetime}`
            }
        }
        return null;
    };

    const renderTags = (commentsDict) => {

        const comments = commentsDict[eventId]
        if (!comments || !Array.isArray(comments)) {
            return null;
        }

        return comments.map((comment, index) => {
            const {text, user, date} = comment;

            // Example style adjustment, you can modify as needed
            const tagStyle = {
                marginTop: 5,
                marginBottom: 5,
                marginRight: 5,
            };

            return (
                <div key={index}>
                    <Tooltip
                        title={
                            <>
                                {getTooltipContent(user, date)}<br/><br/>
                                {text.length > 500 ? `${text.slice(0, 500)}...` : text}
                            </>
                        }
                        key={text}>
                        <Tag style={tagStyle}>
                            {text.length > 30 ? `${text.slice(0, 30)}...` : text}
                        </Tag>
                    </Tooltip>
                </div>
            );
        });
    };

    return (
        <div style={{whiteSpace: 'normal'}}>
            {renderTags(value)} {/* Assuming 'value' is your comments array */}
        </div>
    );
};

export default CommentRenderer;
