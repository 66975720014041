export const carParts = {
  FRONT_HEADLIGHT_LEFT: {
    codes: ["145"],
    size: 1,
  },
  FRONT_HEADLIGHT_RIGHT: {
    codes: ["135"],
    size: 1,
  },
  FRONT_APRON: {
    codes: ["115"],
    size: 1,
  },
  DOOR_OR_WINDOW_REAR_LEFT: {
    codes: ["425", "428"],
    size: 1,
  },
  DOOR_OR_WINDOW_REAR_RIGHT: {
    codes: ["325", "328"],
    size: 1,
  },
  ROOF: {
    codes: ["005"],
    size: 1,
  },
  ENGINE_HOOD: {
    codes: ["015"],
    size: 1,
  },
  DOOR_OR_WINDOW_FRONT_LEFT: {
    codes: ["415", "418"],
    size: 1,
  },
  DOOR_OR_WINDOW_FRONT_RIGHT: {
    codes: ["315", "318"],
    size: 1,
  },
  SIDE_MIRROR_LEFT: {
    codes: ["999"],
    size: 1,
  },
  SIDE_MIRROR_RIGHT: {
    codes: ["998"],
    size: 1,
  },
  REAR_TIER_OR_WHEEL_LEFT: {
    codes: ["426", "427"],
    size: 1,
  },
  REAR_TIER_OR_WHELL_RIGHT: {
    codes: ["326", "327"],
    size: 1,
  },
  FRONT_TIER_OR_WHEEL_LEFT: {
    codes: ["416", "417"],
    size: 1,
  },
  FRONT_TIER_OR_WHEEL_RIGHT: {
    codes: ["316", "317"],
    size: 1,
  },
  WINDSCREEN: {
    codes: ["018"],
    size: 1,
  },
  REAR_APRON_LEFT: {
    codes: ["245"],
    size: 1,
  },
  REAR_APRON_RIGHT: {
    codes: ["235"],
    size: 1,
  },
  REAR_APRON_MID: {
    codes: ["225"],
    size: 1,
  },
  TRUNK_INC_REAR_WINDSCREEN: {
    codes: ["028"],
    size: 1,
  },
  UNDERBODY_FRONT_LEFT: {
    codes: ["014"],
    size: 1,
  },
  UNDERBODY_REAR_LEFT: {
    codes: ["024"],
    size: 1,
  },
  UNDERBODY_MIDDLE_LEFT: {
    codes: ["034"],
    size: 1,
  },
  UNDERBODY_FRONT_RIGHT: {
    codes: ["013"],
    size: 1,
  },
  UNDERBODY_REAR_RIGHT: {
    codes: ["023"],
    size: 1,
  },
  UNDERBODY_MIDDLE_RIGHT: {
    codes: ["033"],
    size: 1,
  },
  RIM_LEFT_BELOW: {
    codes: ["023"],
    size: 1,
  },
};

export const carPartsGerman = {
    "028": "Kofferraum inkl. Heckscheibe hinten",
    "225": "Heckschürze (mittig) hinten",
    "235": "Heckschürze (rechts) hinten",
    "245": "Heckschürze (links) hinten",
    "018": "Windschutzscheibe vorne",
    "998": "Außenspiegel (rechts)",
    "015": "Motorhaube vorne",
    "005": "Dach mitte",
    "999": "Außenspiegel (links)",
    "115": "Frontschürze vorne",
    "135": "Frontscheinwerfer (rechts)",
    "145": "Frontscheinwerfer (links)",
    "316": "Kotflügel (rechtes Vorderrad) rechts",
    "317": "Rad (rechtes Vorderrad) rechts",
    "416": "Kotflügel (linkes Vorderrad) links",
    "417": "Rad (linkes Vorderrad) links",
    "326": "Kotflügel (rechtes Hinterrad) rechts",
    "327": "Rad (rechtes Hinterrad) rechts",
    "426": "Kotflügel (linkes Hinterrad) links",
    "427": "Rad (linkes Hinterrad) links",
    "318": "Seitenfenster (vordere, rechte Tür) rechts",
    "418": "Seitenfenster (vordere, linke Tür) links",
    "328": "Seitenfenster (hintere, rechte Tür) rechts",
    "428": "Seitenfenster (hintere, linke Tür) links",
    "315": "Tür rechts vorne mitte",
    "325": "Tür rechts hinten mitte",
    "415": "Tür links vorne mitte",
    "425": "Tür links hinten mitte",
    "014": "Unterboden (vorne links)",
    "013": "Unterboden (vorne rechts)",
    "023": "Unterboden (hinten rechts)",
    "024": "Unterboden (hinten links)",
    "033": "Unterboden (mitte rechts)",
    "034": "Unterboden (mitte links)",
    "300": "Schweller rechts",
    "400": "Schweller links"
}

export const carPartsNumbers = [
        {value: "028"}, {value: "225"}, {value: "235"}, {value: "245"}, {value: "018"},
        {value: "998"}, {value: "015"}, {value: "005"}, {value: "999"}, {value: "115"},
        {value: "135"}, {value: "145"}, {value: "316"}, {value: "317"}, {value: "416"},
        {value: "417"}, {value: "326"}, {value: "327"}, {value: "426"}, {value: "427"},
        {value: "318"}, {value: "418"}, {value: "328"}, {value: "428"}, {value: "315"},
        {value: "325"}, {value: "415"}, {value: "425"}, {value: "014"}, {value: "013"},
        {value: "023"}, {value: "024"}, {value: "033"}, {value: "034"}, {value: "300"},
        {value: "400"}
    ];


export const carPartsNames = [
        { value: "Kofferraum inkl. Heckscheibe hinten" },
        { value: "Heckschürze (mittig) hinten" },
        { value: "Heckschürze (rechts) hinten" },
        { value: "Heckschürze (links) hinten" },
        { value: "Windschutzscheibe vorne" },
        { value: "Außenspiegel (rechts)" },
        { value: "Motorhaube vorne" },
        { value: "Dach mitte" },
        { value: "Außenspiegel (links)" },
        { value: "Frontschürze vorne" },
        { value: "Frontscheinwerfer (rechts)" },
        { value: "Frontscheinwerfer (links)" },
        { value: "Kotflügel (rechtes Vorderrad) rechts" },
        { value: "Rad (rechtes Vorderrad) rechts" },
        { value: "Kotflügel (linkes Vorderrad) links" },
        { value: "Rad (linkes Vorderrad) links" },
        { value: "Kotflügel (rechtes Hinterrad) rechts" },
        { value: "Rad (rechtes Hinterrad) rechts" },
        { value: "Kotflügel (linkes Hinterrad) links" },
        { value: "Rad (linkes Hinterrad) links" },
        { value: "Seitenfenster (vordere, rechte Tür) rechts" },
        { value: "Seitenfenster (vordere, linke Tür) links" },
        { value: "Seitenfenster (hintere, rechte Tür) rechts" },
        { value: "Seitenfenster (hintere, linke Tür) links" },
        { value: "Tür rechts vorne mitte" },
        { value: "Tür rechts hinten mitte" },
        { value: "Tür links vorne mitte" },
        { value: "Tür links hinten mitte" },
        { value: "Unterboden (vorne links)" },
        { value: "Unterboden (vorne rechts)" },
        { value: "Unterboden (hinten rechts)" },
        { value: "Unterboden (hinten links)" },
        { value: "Unterboden (mitte rechts)" },
        { value: "Unterboden (mitte links)" },
        { value: "Schweller rechts" },
        { value: "Schweller links" }
    ];