import React, {useState, useEffect} from 'react';
import LineZoomChart from "./LineZoomChart";
import {Card, Form, Input, Popover, Button, Col, Row} from 'antd';
import axiosInstance from "services/axios";

const ChartTool = (props) => {

  console.log(props)

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const {id = null} = props;
  const uaid = props?.uaid
  const [data, setData] = useState({});
  const [includeGPS, setIncludeGPS] = useState(false);
  const [zoom, setZoom] = useState(30);
  const [allPlotHeight, setAllPlotHeight] = useState(window.innerHeight/10);

  useEffect(() => {
    if (id || uaid) {
      setLoading(true);
      setError(false);
      const request = {
        event_id: id,
        zoom: zoom,
        uaid: uaid
      }

      axiosInstance.post('/api/matching_tool/raw_data', request)
        .then(function (response) {

          console.log(response.data)
          setData(response.data.data);

          // if (Object.keys(response.data.gps_data).length > 0) {
          if (response.data.gps_data) {
            setAllPlotHeight(window.innerHeight/10)
            setIncludeGPS(true)
          } else {
            setAllPlotHeight(window.innerHeight/8.5)
            setIncludeGPS(false)
          }
          setLoading(false);
        })
        .catch(function (error) {
          console.log(error);
          setData({});
          setLoading(false);
          setError(true);
        })
    } else {
      setData({});
    }
  }, [id, uaid, zoom]);

  return (
    <>
      {loading ? (
        <Card>
          <div>Loading...</div>
        </Card>
      ) : error ? (
        <div>Error: Could not fetch data.</div>
      ) : (
        <Card bodyStyle={{paddingTop: '4px', paddingBottom: '4px', paddingRight: '4px', paddingLeft: '4px'}}>
          <Button onClick={() => setZoom(180)} disabled={zoom === 180} style={{marginBottom:20}}> Zoom 180s</Button>
          <Button onClick={() => setZoom(120)} disabled={zoom === 120} style={{marginBottom:20}}> Zoom 120s</Button>
          <Button onClick={() => setZoom(60)} disabled={zoom === 60} style={{marginBottom:20}}> Zoom 60s</Button>
          <Button onClick={() => setZoom(30)} disabled={zoom === 30} style={{marginBottom:20}}> Zoom 30s</Button>
          <Button onClick={() => setZoom(10)} disabled={zoom === 10} style={{marginBottom:20}}> Zoom 10s</Button>
          <Button onClick={() => setZoom(8)} disabled={zoom === 8} style={{marginBottom:20}}> Zoom 8s</Button>
          <Button onClick={() => setZoom(6)} disabled={zoom === 6} style={{marginBottom:20}}> Zoom 6s</Button>
          <Button onClick={() => setZoom(4)} disabled={zoom === 4} style={{marginBottom:20}}> Zoom 4s</Button>
          <Button onClick={() => setZoom(2)} disabled={zoom === 2} style={{marginBottom:20}}> Zoom 2s</Button>

          {props.type !== "b" ?
            <>
              <LineZoomChart data={data} height={200} yValue={props.type + '_x'} showXAxis={false}/>
              <LineZoomChart data={data} height={200} yValue={props.type + '_y'} showXAxis={false}/>
              <LineZoomChart data={data} height={200} yValue={props.type + '_z'} showXAxis={true}/>
            </>
          :
            <>
              <LineZoomChart data={data} height={allPlotHeight} yValue={'a_x'} showXAxis={false}/>
              <LineZoomChart data={data} height={allPlotHeight} yValue={'a_y'} showXAxis={false}/>
              <LineZoomChart data={data} height={allPlotHeight} yValue={'a_z'} showXAxis={false}/>
              <LineZoomChart data={data} height={allPlotHeight} yValue={'g_x'} showXAxis={false}/>
              <LineZoomChart data={data} height={allPlotHeight} yValue={'g_y'} showXAxis={false}/>
              {(!includeGPS) &&
               (<LineZoomChart data={data} height={allPlotHeight} yValue={'g_z'} showXAxis={true}/>)
              }
              {(includeGPS) &&
               (<LineZoomChart data={data} height={allPlotHeight} yValue={'g_z'} showXAxis={false}/>)}
              {(includeGPS) &&
               (<LineZoomChart data={data} height={allPlotHeight} yValue={'delta_velocity'} showXAxis={true}/>)}
            </>
          }
        </Card>
      )}
    </>
  );
};

export default ChartTool;