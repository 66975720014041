import React, {useState, useRef, useCallback, useEffect} from 'react';

import {
    Button,
    Card,
    Dropdown,
    Select,
    Col,
    Modal,
    notification,
    Row,
    Typography,
    Input,
    Spin,
    Switch,
    Divider,
    Popover,
    InputNumber
} from 'antd';
import Icon, {
    InfoCircleOutlined,
    SettingOutlined,
    ExclamationCircleOutlined,
    FilterOutlined,
    DatabaseOutlined, ReloadOutlined, ZoomOutOutlined
} from "@ant-design/icons";

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import Boost from 'highcharts/modules/boost';
// import Map from 'highcharts/modules/map';
import Exporting from 'highcharts/modules/exporting';
// import MouseWheelZoom from 'highcharts/modules/mouse-wheel-zoom';

import axiosInstance from "services/axios";

import TagRenderer from "../TagRenderer/TagRenderer";
import TagValues from "../TagValues/TagValues";
import AnomalyInfoCard from "../AnomalyTool/AnomalyInfoCard";
import AnomalyPredCard from "../AnomalyTool/AnomalyPredCard";
import AnomalyPlotCard from "../AnomalyTool/AnomalyPlotCard";
import AnomalyLabelCard from "./AnomalyLabelCard";
import FilterForm from "../MapTool/FilterForm";
import ZoomForm from "./ZoomForm";

const {Search} = Input;
const {Text, Paragraph} = Typography;

// Map(Highcharts)
Boost(Highcharts);
Exporting(Highcharts);
// MouseWheelZoom(Highcharts)

// (function(H) {
//   Highcharts.Chart.prototype.callbacks.push(function(chart) {
//     H.addEvent(chart.container, 'wheel', function(e) {
//       const xAxis = chart.xAxis[0],
//       xExtremes = xAxis.getExtremes();
//
//       const yAxis = chart.yAxis[0],
//       yExtremes = xAxis.getExtremes();
//
//       console.log(chart.yAxis)
//       console.log(yExtremes);
//
//       if (e.deltaY < 0) {
//           xAxis.setExtremes(xExtremes.min + 0.5, xExtremes.max - 0.5, true);
//           yAxis.setExtremes(yExtremes.min + 0.5, yExtremes.max - 0.5, true);
//       }
//       else {
//           xAxis.setExtremes(xExtremes.min - 0.5, xExtremes.max + 0.5, true);
//           yAxis.setExtremes(yExtremes.min - 0.5, yExtremes.max + 0.5, true);
//       }
//     });
//   });
// }(Highcharts));


const LabelTool = (props) => {

    const [data, setData] = useState(null);
    // const [seriesList, setSeriesList] = useState([]);
    const [selectedId, setSelectedId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [featureFile, setFeatureFile] = useState(null);
    // const [chartOptions, setChartOptions] = useState({});
    // const [chartSeries, setChartSeries] = useState([]);

    const [scalingFactor, setScalingFactor] = useState(1000);
    const [refreshCounter, setRefreshCounter] = useState(0);
    const [currentZoom, setCurrentZoom] = useState([-25, 25, -25, 25]);
    const [featureExtremes, setFeatureExtremes] = useState([-25, 25, -25, 25]);
    const [filterOptions, setFilterOptions] = useState([]);
    const [filterValues, setFilterValues] = useState([]);
    const [filterOpen, setFilterOpen] = useState(false);
    const [zoomOpen, setZoomOpen] = useState(false);

    const [filterActive, setFilterActive] = useState(false);
    const [neighbours, setNeighbours] = useState(null);
    const [notVisibleSeries, setNotVisibleSeries] = useState([]);

    const chartRef = useRef(null);

    const onChangeScaling = (value) => {
        setScalingFactor(value)
    };

    useEffect(() => {
        document.title = 'Label Tool';
    }, []);


    const settingsMenu = (
        <div style={{width: 280, height: 100}}>
            <Row>
                <Col>
                    <span>Distance scaling factor: </span>
                    <InputNumber
                        min={1}
                        max={10000}
                        defaultValue={1000}
                        style={{width: '100px'}}
                        onChange={onChangeScaling}
                        size={'small'}
                    />
                </Col>
            </Row>
            {/*<Row>*/}
            {/*    <span style={{marginRight: 13}}>Include stationary close to station:</span>*/}
            {/*    <Checkbox*/}
            {/*        onChange={onChangeStationInclude}*/}
            {/*        defaultChecked={props.searchSettings.include_station}*/}
            {/*        style={{verticalAlign: 'middle'}}*/}
            {/*    >*/}
            {/*    </Checkbox>*/}
            {/*</Row>*/}
        </div>
    );

    const onClickMenu = ({key}) => {
        console.log(`Click on item ${key}`);
        setFeatureFile(key)
        setLoading(true)
    };

    let menuItems = fileList.map((value, index) => ({
        label: value,
        key: value,
    }))

    useEffect(() => {
        const payload = {}

        axiosInstance.post('/api/label/features/list', payload)
            .then(res => {
                setFileList(res.data);
            })
            .catch(error => {
                console.log(error);
                notification['error']({
                        message: error.message,
                        description: error.response.data.message,
                        duration: 10,
                    }
                );
            })
    }, []);

    useEffect(() => {
        if (featureFile) {
            const payload = {feature_file: featureFile}

            axiosInstance.post('/api/label/features/filters', payload)
                .then(res => {
                    const filterList = res.data
                    setFilterOptions(filterList.map((value, index) => ({
                        label: value,
                        value: value,
                    })))
                })
                .catch(error => {
                    console.log(error);
                    notification['error']({
                            message: error.message,
                            description: error.response.data.message,
                            duration: 10,
                        }
                    );
                })
        }

    }, [featureFile]);

    const filterSelectOptions = [
        {
            label: 'equals',
            value: 'equals',
            tooltipContent: 'Equals - include exact matches',
        },
        {
            label: 'not equals',
            value: 'not_equals',
            tooltipContent: 'Not Equals - exclude exact matches',
        },
        {
            label: 'smaller',
            value: 'smaller',
            tooltipContent: 'Smaller Than - set an upper limit',
        },
        {
            label: 'greater',
            value: 'greater',
            tooltipContent: 'Greater Than - set a lower limit',
        },
        // {
        //     label: 'contains',
        //     value: 'contains',
        //     tooltipContent: 'Contains - include partial matches',
        // },
        // {
        //     label: 'not contains',
        //     value: 'not_contains',
        //     tooltipContent: 'Not Contains - exclude partial matches',
        // },
    ];

    useEffect(() => {
        if (featureFile) {
            const payload = {feature_file: featureFile}

            axiosInstance.post('/api/label/features/info', payload)
                .then(res => {
                    setFeatureExtremes([
                        res.data.min_x - 1, res.data.max_x + 1,
                        res.data.min_y - 1, res.data.max_y + 1])
                    console.log(res.data)
                })
                .catch(error => {
                    console.log(error);
                    notification['error']({
                            message: error.message,
                            description: error.response.data.message,
                            duration: 10,
                        }
                    );
                })
        }

    }, [featureFile]);

    useEffect(() => {
        if (featureFile) {
            const payload = {
                filter_values: filterValues,
                feature_file: featureFile,
                current_zoom: currentZoom,
                refresh_counter: refreshCounter,
            }

            axiosInstance.post('/api/label/map', payload)
                .then(res => {
                    setLoading(false);
                    setData(res.data.data_dict);
                })
                .catch(error => {
                    setLoading(false);
                    console.log(error);
                    notification['error']({
                            message: error.message,
                            description: error.response.data.message,
                            duration: 10,
                        }
                    );
                })
        }
    }, [featureFile, filterValues, refreshCounter]);

    useEffect(() => {
        if (selectedId !== null) {
            const payload = {
                UAID: selectedId,
                filter_values: props.filterValues,
                feature_file: featureFile,
                refresh_counter: refreshCounter,
                not_visible: notVisibleSeries,
            }

            axiosInstance.post('/api/label/knn', payload)
                .then(res => {
                    setNeighbours(res.data);
                })
                .catch(error => {
                    setNeighbours(null);
                    console.log(error);
                    notification['error']({
                            message: error.message,
                            description: error.response.data.message,
                            duration: 10,
                        }
                    );
                })
        } else {
            setNeighbours(null)
        }
    }, [selectedId, filterValues, refreshCounter, notVisibleSeries]);

    const handleFilterChange = (values) => {
        setFilterOpen(!filterOpen);

        if (values.filters.length > 0) {
            for (const filter of values.filters) {
                if (filter.field === "box") {
                    const oldValue = filter.value
                    filter.value = oldValue.replace(/^0+/, '');
                }
            }
            setLoading(true);
            setSelectedId(null)
            setFilterActive(true)
            setFilterValues(values.filters)
        } else {
            setLoading(true);
            setFilterActive(false)
            setFilterValues([]);
        }
    };

    const handleZoomChange = (values) => {
        setZoomOpen(!zoomOpen);
        // Convert input values to floats
        const xMin = parseFloat(values.xmin);
        const xMax = parseFloat(values.xmax);
        const yMin = parseFloat(values.ymin);
        const yMax = parseFloat(values.ymax);

        if (chartRef.current) {
            chartRef.current.chart.showResetZoom();
            chartRef.current.chart.xAxis[0].setExtremes(xMin, xMax, true, false);
            chartRef.current.chart.yAxis[0].setExtremes(yMin, yMax, true, false);
        }
    };

    const handleFilterOpen = () => {
        setFilterOpen(!filterOpen);
    };

    const handleZoomOpen = () => {
        setZoomOpen(!zoomOpen);
    };

    const handleRefreshClick = () => {
        setRefreshCounter(refreshCounter + 1)
    };

    const exportDataAsCSV = () => {
        if (chartRef.current) {
            const xExtremes = chartRef.current.chart.xAxis[0].getExtremes();
            const yExtremes = chartRef.current.chart.yAxis[0].getExtremes();

            const payload = {
                feature_file: featureFile,
                xMin: xExtremes.min,
                xMax: xExtremes.max,
                yMin: yExtremes.min,
                yMax: yExtremes.max,
            }

            axiosInstance.post('/api/label/export/elements', payload)
                .then(res => {
                    const resultList = res.data

                    if (resultList.length > 0) {
                        let csvContent = 'data:text/csv;charset=utf-8,';

                        resultList.forEach((item) => {
                            csvContent += `${item},`;
                            csvContent += '\n';
                        });

                        // Create a download link and trigger the download
                        const encodedUri = encodeURI(csvContent);
                        const link = document.createElement('a');
                        link.setAttribute('href', encodedUri);
                        link.setAttribute('download', 'UAID_list.csv');
                        document.body.appendChild(link); // Required for Firefox
                        link.click();
                    }
                })
                .catch(error => {
                    console.log(error);
                    notification['error']({
                        message: error.message,
                        description: error.response,
                        duration: 10,
                    });
                })
        }
    };

    const copyChartCoordinates = () => {
        if (chartRef.current) {
            const xExtremes = chartRef.current.chart.xAxis[0].getExtremes();
            const yExtremes = chartRef.current.chart.yAxis[0].getExtremes();
            const coordinatesText = `X Extremes: [${xExtremes.min}, ${xExtremes.max}] - Y Extremes: [${yExtremes.min}, ${yExtremes.max}]`;

            navigator.clipboard.writeText(coordinatesText)
                .then(() => {
                    console.log('Coordinates copied to clipboard');
                    notification['success']({
                        message: 'Copied coordinates',
                        description: coordinatesText,
                        duration: 5,
                    });
                })
                .catch((error) => {
                    console.error('Unable to copy coordinates to clipboard', error);
                    notification['error']({
                        message: 'Failed copying coordinates',
                        duration: 5,
                    });
                });
        }
    };

    const onSearch = (value) => {
        if (value) {
            const valueStr = value.trim()
            const parts = valueStr.split("-");
            if (valueStr.includes("UAID") && parts.length === 3 && parts[2].length === 12) {
                setSelectedId(valueStr)

                const payload = {
                    UAID: valueStr,
                    feature_file: featureFile
                }
                axiosInstance.post('/api/label/coordinates', payload)
                    .then(res => {

                        const coordinates = res.data
                        const offset = 0.5

                        if (chartRef.current) {
                            chartRef.current.chart.xAxis[0].setExtremes(
                                coordinates[0] - offset, coordinates[0] + offset, true, false);
                            chartRef.current.chart.yAxis[0].setExtremes(
                                coordinates[1] - offset, coordinates[1] + offset, true, false);
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        notification['error']({
                                message: error.message,
                                description: error.response.data.message,
                                duration: 10,
                            }
                        );
                    })
            } else {
                notification['error']({
                        message: 'Not a valid UAID',
                        duration: 5
                    }
                );
            }
        } else {
            setSelectedId(null)
        }
    }

    const nameArray = [
        'EV_high', 'EV_none', 'EV_low', 'EV_wrong', 'PP', 'FP_high', 'FP_none', 'FP_low',
        'unsure', 'excluded', 'no_feedback', 'no_label']
    const colorArray = [
        'red', 'gold', 'darkorange', 'royalblue', 'violet', 'darkgreen', 'lightskyblue', 'limegreen',
        'black', 'brown', 'darkgray', 'gray']
    const opacityArray = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0.15]
    const sizeArray = [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 3]
    const zIndexArray = [40, 30, 29, 28, 27, 26, 24, 23, 22, 21, 20, 0]

    // useEffect(() => {
    const seriesList = []

    for (let i = 0; i < nameArray.length; i++) {
        seriesList.push({
            data: data ? data[nameArray[i]] : null,
            keys: ['x', 'y', 'UAID', 'modified'],
            name: nameArray[i],
            allowPointSelect: true,
            type: 'scatter',
            fillOpacity: opacityArray[i],
            zIndex: zIndexArray[i],
            color: colorArray[i],
            events: {
                legendItemClick: function () {
                    const seriesName = this.name;

                    if (this.visible) {
                        // Add series to visibleSeries if it's not already present
                        if (!notVisibleSeries.includes(seriesName)) {
                            setNotVisibleSeries((prevVisibleSeries) => [...prevVisibleSeries, seriesName]);
                        }
                    } else {
                        // Remove series from visibleSeries
                        setNotVisibleSeries((prevVisibleSeries) => prevVisibleSeries.filter((name) => name !== seriesName));
                    }

                }
            },
            marker: {
                symbol: 'circle',
                radius: sizeArray[i],
                fillColor: colorArray[i]
            },
            tooltip: {
                useHTML: true,
                pointFormat: '{point.UAID} <br/> {point.modified}'
            },
            point: {
                events: {
                    click: function () {
                        setSelectedId(this.UAID);
                    },
                    mouseOver: function () {
                        if (this.series.halo) {
                            this.series.halo.attr({
                                'class': 'highcharts-tracker'
                            }).toFront();
                        }
                    }
                }
            }
        });
    }
    //     setSeriesList(newSeriesList)
    // }, [data]);

    const handleChartLoad = function () {
        const chart = this;
        const renderer = chart.renderer;

        chart.showResetZoom();

        const xAxis = chart.xAxis[0];
        const yAxis = chart.yAxis[0];

        const zoomInButton = renderer.button('+', 80, null, () => {
            chart.showResetZoom()
            const xExtremes = xAxis.getExtremes();
            const midpoint = (xExtremes.min + xExtremes.max) / 2;
            const newMin = Math.max(xExtremes.min, midpoint - (xExtremes.max - xExtremes.min) / 4);
            const newMax = Math.min(xExtremes.max, midpoint + (xExtremes.max - xExtremes.min) / 4);
            xAxis.setExtremes(newMin, newMax, true, false);

            const yExtremes = yAxis.getExtremes();
            const yMidpoint = (yExtremes.min + yExtremes.max) / 2;
            const yNewMin = Math.max(yExtremes.min, yMidpoint - (yExtremes.max - yExtremes.min) / 4);
            const yNewMax = Math.min(yExtremes.max, yMidpoint + (yExtremes.max - yExtremes.min) / 4);
            yAxis.setExtremes(yNewMin, yNewMax, true, false);

            setCurrentZoom([newMin, newMax, yNewMin, yNewMax]);
        }).attr().add();

        const zoomOutButton = renderer.button('-', 50, null, () => {
            chart.showResetZoom()
            const xExtremes = xAxis.getExtremes();
            const xRange = xExtremes.max - xExtremes.min;
            const newMinX = xExtremes.min - xRange / 2;
            const newMaxX = xExtremes.max + xRange / 2;
            xAxis.setExtremes(newMinX, newMaxX, true, false);

            const yExtremes = yAxis.getExtremes();
            const yRange = yExtremes.max - yExtremes.min;
            const newMinY = yExtremes.min - yRange / 2;
            const newMaxY = yExtremes.max + yRange / 2;
            yAxis.setExtremes(newMinY, newMaxY, true, false);
            setCurrentZoom([newMinX, newMaxX, newMinY, newMaxY]);
        }).attr().add();
    };

    const chartOptions =
        {
            chart: {
                type: 'scatter',
                zoomType: 'xy',
                height: '71%',
                panKey: "ctrl",
                panning: {
                    enabled: true,
                    type: 'xy'
                },
                events: {
                    // selection: function (event) {
                    //     // log the min and max of the primary, datetime x-axis
                    //     setCurrentZoom([event.xAxis[0].min, event.xAxis[0].max, event.yAxis[0].min, event.yAxis[0].max])
                    // },
                    load: handleChartLoad
                },
                zooming: {
                    resetButton: {
                        position: {
                            x: -30,
                            y: 0
                        }
                    }
                }
            },
            boost: {
                useGPUTranslations: true,
                usePreAllocated: true
            },
            series: seriesList,
            title: {
                text: ''
            },
            xAxis: {
                title: "",
                min: featureExtremes[0],
                max: featureExtremes[1],
                startOnTick: false,
                endOnTick: false,
                showLastLabel: false,
                zoomEnabled: true,
            },
            yAxis: {
                title: "",
                min: featureExtremes[2],
                max: featureExtremes[3],
                startOnTick: false,
                endOnTick: false,
                zoomEnabled: true,
            },
            exporting: {
                enabled: true,
                menuItemDefinitions: {
                    exportCSV: {
                        text: 'Download CSV UAIDs',
                        onclick: exportDataAsCSV
                    },
                    copyCoordinates: {
                        text: 'Copy Current Coordinates',
                        onclick: copyChartCoordinates
                    }
                },
                buttons: {
                    contextButton: {
                        menuItems: [
                            'downloadPNG',
                            'downloadSVG',
                            'separator', // Add a separator between default items and custom ones
                            'exportCSV', // Add the custom menu item defined earlier
                            'copyCoordinates'
                        ]
                    }
                }
            },
        }

    return (<>
            <Modal
                open={loading}
                closable={false}
                footer={null}
                centered={true}
                mask={true}
                maskClosable={false}
                bodyStyle={{backdropFilter: 'blur(3px)'}}
            >
                <div>
                    <Spin style={{marginRight: 20}} size="large"/>
                    Loading
                </div>
            </Modal>
            <Row gutter={8}>
                <Col span={8} style={{height: '100%'}}>
                    <Card
                        style={{
                            width: '100%',
                            boxSizing: 'border-box',
                            display: 'flex',
                            flexDirection: 'column',
                            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                            border: '1px solid #e8e8e8',
                            marginBottom: 8
                        }}
                        bodyStyle={{padding: '4px', display: 'flex', flexDirection: "column", flex: "1 1 auto"}}>
                        <Col span={24}>
                            <Search
                                placeholder="search for UAID"
                                onSearch={onSearch}
                                allowClear={true}
                                style={{
                                    width: 275,
                                    marginTop: 4,
                                    marginBottom: 4
                                }}
                            />
                            <Popover
                                trigger="click"
                                placement="bottom"
                                content={
                                    <FilterForm
                                        onFinish={handleFilterChange} fieldOptions={filterOptions}
                                        conditionOptions={filterSelectOptions}
                                    >
                                    </FilterForm>}
                                open={filterOpen}
                            >
                                <Button
                                    onClick={handleFilterOpen}
                                    icon={<FilterOutlined/>}
                                    style={{backgroundColor: filterActive ? "lightcoral" : "white", marginTop: 4}}
                                >
                                    Filter
                                </Button>
                            </Popover>
                            <Dropdown
                                menu={{items: menuItems, onClick: onClickMenu}}
                                placement="bottomLeft"
                                arrow={{pointAtCenter: true}}
                            >
                                <Button
                                    icon={<DatabaseOutlined/>}
                                >
                                    Features
                                </Button>
                            </Dropdown>
                            <Popover
                                trigger="click"
                                placement="bottom"
                                content={
                                    <ZoomForm
                                        onFinish={handleZoomChange}
                                    >
                                    </ZoomForm>}
                                open={zoomOpen}
                            >
                                <Button
                                    onClick={handleZoomOpen}
                                    icon={<ZoomOutOutlined/>}
                                    style={{backgroundColor: filterActive ? "lightcoral" : "white", marginTop: 4}}
                                >
                                </Button>
                            </Popover>
                            <Button
                                onClick={handleRefreshClick}
                                style={{backgroundColor: filterActive ? "lightcoral" : "white", marginTop: 4}}
                                icon={<ReloadOutlined/>}
                            >
                            </Button>
                            <Popover
                                title={'Settings'}
                                trigger="click"
                                content={settingsMenu}
                            >
                                <Button icon={<SettingOutlined/>}/>
                            </Popover>
                        </Col>
                    </Card>
                    <AnomalyLabelCard
                        UAID={selectedId}
                        filterValues={filterValues}
                        neighbours={neighbours}
                        scalingFactor={scalingFactor}
                        setSelectedId={onSearch}
                    />
                </Col>
                <Col span={16}>
                    <Card style={{
                        width: '100%',
                        height: '94vh', // Set height to viewport height
                        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                        border: '1px solid #e8e8e8'
                    }} bodyStyle={{padding: '5px'}}>
                        {!featureFile &&
                            <div>
                                <Paragraph>

                                </Paragraph>
                                <Text style={{marginLeft: 35}}>
                                    Please select a feature file to load
                                </Text>
                            </div>
                        }
                        {data && featureFile &&
                            <HighchartsReact
                                ref={chartRef}
                                highcharts={Highcharts}
                                options={chartOptions}
                            />
                        }
                    </Card>
                </Col>
            </Row>
        </>
    );
}

export default LabelTool;
