import React from "react";
import {Button, Result} from 'antd';
import {Typography} from 'antd';
import {useAuth} from "react-oidc-context";

const {Paragraph, Text} = Typography;

export const UnauthorizedScreen = () => {

  const auth = useAuth();

  let message;
  if (auth.isAuthenticated) {
    message = (
      <Typography>
        <Paragraph>You are authenticated as <Text strong>{auth.user?.profile.preferred_username}</Text> but you are
          probably missing required access roles.</Paragraph>
        <Paragraph>Please contact the support.</Paragraph>
        <Button onClick={() => void auth.signoutRedirect({state: '/signout'})}>Sign out</Button>
      </Typography>);
  } else {
    message = (
      <Typography>
        <Paragraph>You are not authenticated.</Paragraph>
      </Typography>);
  }

  return (
    <Result
      status="error"
      title="You are not allowed to access this page."
      extra={message}
    />
  );
}

