import React from 'react';
import {InfoWindow} from "@react-google-maps/api";

import InfoComponent from "./InfoComponent";

const InfoMarker = (props) => {

  return (
    <>
      {(props.anchor) && (
        <InfoWindow onCloseClick={props.onCloseClick} anchor={props.anchor}>
          <InfoComponent id={props.id} miniTooltip={props.miniTooltip}/>
        </InfoWindow>)}
    </>
  );
};

export default InfoMarker;