import React, {useState, useEffect} from 'react';
import {Progress} from 'antd';

const ReviewRenderer = (props) => {
    const {context, value, data} = props;
    const eventId = context.eventId;
    const [progressPercent, setProgressPercent] = useState(0);
    const [trueReviewTagsCount, setTrueReviewTagsCount] = useState(0);

    const reviewTags = value ? value[eventId] : null;
    const matchingValue = data.matching_analysis;
    const analysisTags = matchingValue ? matchingValue[eventId] : null;

    useEffect(() => {
        if (eventId && analysisTags) {

            // Calculate how many of these have a review tag (true or false)
            let trueReviewTagsCount = 0;
            let falseReviewTagsCount = 0;
            let reviewTagsCount = 0
            let analysisTagsCount = 0
            if (reviewTags) {
                Object.keys(analysisTags).forEach(tag => {
                    analysisTagsCount++;
                    if (reviewTags[tag] !== undefined) {
                        if (reviewTags[tag] === analysisTags[tag]) {
                            trueReviewTagsCount++;
                        } else {
                            falseReviewTagsCount++;
                        }
                        reviewTagsCount++;
                    }
                });
            }

            // Calculate the progress percentage
            const progress = Math.round((analysisTagsCount > 0) ? (reviewTagsCount / analysisTagsCount) * 100 : 0);
            setProgressPercent(progress);

            const successProgress = Math.round((analysisTagsCount > 0) ? (trueReviewTagsCount / analysisTagsCount) * 100 : 0);
            setTrueReviewTagsCount(successProgress)

            // // Set progress color based on presence of false tags
            // if (hasFalseTag && progress > 99) {
            //     setProgressStatus('exception'); // Red color if any tag is false
            // } else if (!hasFalseTag && progress > 99) {
            //     setProgressStatus('success'); // Green color if all are true or present
            // } else {
            //     setProgressStatus('normal'); // Green color if all are true or present
            // }

        }
    }, [value, context, eventId]);

    return (
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 8}}>
            {analysisTags && Object.keys(analysisTags).length > 0 &&
                <Progress
                    type="circle"
                    percent={progressPercent}
                    success={{percent: trueReviewTagsCount, strokeColor: '#383838'}}
                    size={'small'}
                    strokeColor={'#9f9f9f'}
                />
            }
        </div>
    );
};

export default ReviewRenderer;
