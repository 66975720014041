class TagValues {
    static get distinctPatternValues() {
        return [
            {value: "door left"},
            {value: "door right"},
            {value: "trunk"},
            {value: "sidewalk"},
            {value: "rails"},
            {value: "technician"},
            {value: "zoe_pattern"},
            {value: "zoe_short"},
            {value: "zoe_long"},
            {value: "id3_pattern"},
            {value: "corolla_pattern"},
            {value: "breaking"},
            {value: "repeated"},
            {value: "PF_Vito"},
            {value: "PF_Corolla"},
            {value: "data_error"},
            {value: "vito_break"},
            {value: "exclude_from_model_training"},
            {value: "RoadBump"}
        ];
    }

    static get distinctDamageValues() {
        return [
            {value: "abrasion"},
            {value: "dent"},
            {value: "scratch"},
            {value: "structure"},
            {value: "underbody"},
            {value: "fracture"},
            {value: "chassis"},
            {value: "deformation"},
            {value: "detached part"},
            {value: "missing part"},
            {value: "glass"},
            {value: "interior"},
            {value: "plastic"},
            {value: "puncture"},
        ];
    }

    static get distinctSituationValues() {
        return [
            {value: "head-on"},
            {value: "rear-end"},
            {value: "side-impact"},
            {value: "parking"},
            {value: "turning"},
            {value: "sideswipe"},
            {value: "single vehicle"},
            {value: "multi vehicle"},
            {value: "third-party"},
            {value: "vandalism"},
            {value: "workshop_relevant"},
            {value: "workshop_unrelevant"},
            {value: "workshop_unsure"},
        ];
    }

    static get distinctConfidenceValues() {
        return [
            {value: "1"},
            {value: "2"},
            {value: "3"},
            {value: "4"},
            {value: "0"},
            {value: "-1"},
            {value: "-2"},
            {value: "-3"},
            {value: "4"}
        ];
    }
}

export default TagValues;