import React, { useState, useEffect, useImperativeHandle, forwardRef, useCallback } from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';

const { RangePicker } = DatePicker;
//
// export const CustomDateFilter = ({ model, onModelChange }) => {
//     const [closeFilter, setCloseFilter] = useState();
//     const [unappliedModel, setUnappliedModel] = useState(model);
//
//     const doesFilterPass = useCallback((params) => {
//         // doesFilterPass only gets called if the filter is active,
//         // which is when the model is not null (e.g. >= 2010 in this case)
//         return params.data.year >= 2010;
//     }, []);
//
//     const afterGuiAttached = useCallback(({ hidePopup }) => {
//         setCloseFilter(() => hidePopup);
//     }, []);
//
//     console.log('TEST')
//
//     // register filter handlers with the grid
//     useGridFilter({
//         doesFilterPass,
//         afterGuiAttached,
//     });
//
//     useEffect(() => {
//         setUnappliedModel(model);
//     }, [model]);
//
//     const onYearChange = ({ target: { value }}) => {
//         setUnappliedModel(value === 'All' ? null : value);
//     }
//
//     const onClick = () => {
//         onModelChange(unappliedModel);
//         if (closeFilter) {
//             closeFilter();
//         }
//     };
//
//     return (
//         <div className="year-filter">
//             <div>Select Year Range</div>
//             <label>
//                 <input type="radio" name="year" value="All" checked={unappliedModel == null} onChange={onYearChange} /> All
//             </label>
//             <label>
//                 <input type="radio" name="year" value="2010" checked={unappliedModel != null} onChange={onYearChange} /> Since 2010
//             </label>
//             <button onClick={onClick}>Apply</button>
//         </div>
//     );
// };

export function AnomalyURLRenderer(params) {
    const currentURL = window.location.href;
    let urlBase = "https://matching.carvaloo.com"
    if (currentURL.includes("localhost")) {
        urlBase = "http://localhost:3000";
    }

    return <a href={urlBase + `/anomaly_tool/${params.data?.UAID}`} target="_blank" rel="noopener noreferrer">
        {params.data?.UAID}
    </a>
}

export function ReportURLRenderer(params) {
    return <a href={params.data?.report_url} target="_blank">{params.data?.UAID}</a>
}

export function EventURLRenderer(params) {
    let urlBase = "https://webapp.carvaloo.com/ereignisse/"
    let urlEvent = urlBase.concat(params.data?.event_id, "?organization=", params.data?.customer)
    return <a href={urlEvent} target="_blank">{params.data?.event_id}</a>
}

export function CheckboxRenderer() {}

    CheckboxRenderer.prototype.init = function(params) {
      this.params = params;

      this.eGui = document.createElement('input');
      this.eGui.type = 'checkbox';
      this.eGui.checked = params.value;

      this.checkedHandler = this.checkedHandler.bind(this);
      this.eGui.addEventListener('click', this.checkedHandler);
    }

    CheckboxRenderer.prototype.checkedHandler = function(e) {
      let checked = e.target.checked;
      let colId = this.params.column.colId;
      this.params.node.setDataValue(colId, checked);
    }

    CheckboxRenderer.prototype.getGui = function(params) {
      return this.eGui;
    }

    CheckboxRenderer.prototype.destroy = function(params) {
      this.eGui.removeEventListener('click', this.checkedHandler);
}

export function AngleToStringGetter(params) {

  let angle = params.data.event_direction

  if (-180 <= angle && angle <= 180) {
    // do nothing
  } else {
    angle = angle - 360
  }

  if (-10 <= angle && angle <= 10) {return "vorne"}
  else if (10 <= angle && angle <= 25) {return "vorne rechts"}
  else if (-25 <= angle && angle <= -10) {return "vorne links"}
  else if (170 <= angle || angle <= -170) {return "hinten"}
  else if (155 <= angle && angle <= 170) {return "hinten rechts"}
  else if (-170 <= angle && angle <= -155) {return "hinten links"}
  else if (25 <= angle && angle <= 60) {return "rechts vorne"}
  else if (60 <= angle && angle <= 120) {return "rechts"}
  else if (120 <= angle && angle <= 155) {return "rechts hinten"}
  else if (-60 <= angle && angle <= -25) {return "links vorne"}
  else if (-120 <= angle && angle <= -60) {return "links"}
  else if (-155 <= angle && angle <= -120) {return "links hinten"}
  else {return "NOT IN RANGE"}
}

export function dateTimeFormatter(params) {
    return params.value != undefined ? moment.utc(params.value).format('DD.MM.YYYY HH:mm') : null;
}

// export function dateDateComparator(filterLocalDateAtMidnight, cellValue) {
//     const dateAsString = moment(cellValue).format('DD.MM.YYYY');
//
//     console.log('DATE FILTER');
//     console.log(filterLocalDateAtMidnight);
//     console.log(dateAsString);
//
//     if (dateAsString == null) {
//         return 0;
//     }
//
//     // We create a Date object for comparison against the filter date
//     const dateParts = dateAsString.split('.');
//     const year = Number(dateParts[2]);
//     const month = Number(dateParts[1]) - 1;
//     const day = Number(dateParts[0]);
//     const cellDate = new Date(year, month, day);
//
//     // Now that both parameters are Date objects, we can compare
//     if (cellDate < filterLocalDateAtMidnight) {
//         return -1;
//     } else if (cellDate > filterLocalDateAtMidnight) {
//         return 1;
//     }
//     return 0;
// }
//
// export function dateTimeComparator(date1, date2) {
//     const date1Number = dateTimeToComparableNumber(date1);
//     const date2Number = dateTimeToComparableNumber(date2);
//
//     if (date1Number === null && date2Number === null) {
//         return 0;
//     }
//     if (date1Number === null) {
//         return -1;
//     }
//     if (date2Number === null) {
//         return 1;
//     }
//
//     return date1Number - date2Number;
// }
//
// export function dateTimeToComparableNumber(datetime) {
//
//     if (datetime === undefined || datetime === null) {
//         return null;
//     }
//
//     const dateTimeString = moment(datetime).format('YYYYMMDDhhmm');
//
//     if (dateTimeString.length !== 12) {
//         return null;
//     }
//
//     return parseInt(dateTimeString);
// }
