// BtnCellRenderer.jsx
import {Popover, Button, Collapse, notification} from 'antd';

import React, {useState} from "react";
import 'leaflet/dist/leaflet.css';

import {Typography} from 'antd';
import axiosInstance from "../../services/axios";

const {Title, Text} = Typography;

const MatchingScoreRenderer = (props) => {

    const [calculated, setCalculated] = useState(false);

    function handleButtonClick(data, eventId, customer, api) {

        const payload = {
            uaid: data.UAID,
            customer: customer ? customer : data.customer,
            event_id: eventId
        }

        if (eventId) {
            notification['info']({message: 'Sent request to calculate matching score'});
            axiosInstance.post('/api/matching_tool/matching_score', payload)
                .then(res => {
                    api.refreshServerSide();
                    // setCalculated(true)
                    console.log('Request processed successfully')
                })
                .catch(error => {
                    console.log(error);
                    notification['error']({message: 'Error', description: error.message});
                })
        } else {
            notification['warning']({message: 'Please set event'});
        }
    }

    const eventId = props.context.eventId
    const customer = props.context.customer

    return (
        <>
            {eventId && eventId.length > 0 &&
                (
                    (props.data.matching_score !== null && props.data.matching_score !== undefined && props.api) ?
                        props.data.matching_score :

                        (
                            <Button onClick={() => handleButtonClick(props.data, eventId, customer, props.api)}>
                                {calculated ? 'Calculated' : 'Click'}
                            </Button>
                        )
                )
            }
        </>
    );
}


export default MatchingScoreRenderer;