import { Tag, AutoComplete} from 'antd';
import React from 'react';

export default class TagCellRenderer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columnValues: props.columnValues,
      tags: props.value ? props.value.split(",") : [],
      newTag: "",
      filteredValues: props.columnValues,
      showAddTag: props.showAddTag !== false
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.columnValues !== prevState.columnValues) {
      return { columnValues: nextProps.columnValues };
    }
    return null;
  }

  componentDidMount() {
    const sortedValues = this.state.columnValues
        .sort((a, b) => a.value.localeCompare(b.value));
    this.setState({
      filteredValues: sortedValues.filter((columnValue) =>
        columnValue.value.toLowerCase().startsWith(this.state.newTag.toLowerCase()) &&
        columnValue.value.toLowerCase().indexOf(this.state.newTag.toLowerCase()) === 0
      )
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.newTag !== prevState.newTag || this.state.columnValues !== prevState.columnValues) {
      const sortedValues = this.state.columnValues
        .sort((a, b) => a.value.localeCompare(b.value));
      this.setState({
        filteredValues: sortedValues.filter((columnValue) =>
          columnValue.value.toLowerCase().startsWith(this.state.newTag.toLowerCase()) &&
          columnValue.value.toLowerCase().indexOf(this.state.newTag.toLowerCase()) === 0
        )
      });
    }
  }

  handleChange = (value) => {
    this.setState({ newTag: value });
  };

  handleSelect = (value) => {
    const updatedTags = this.state.tags.concat([value]);
    const updatedValue = updatedTags.join(',');
    this.setState({ tags: updatedTags });
    this.props.setValue(updatedValue);
    this.setState({ newTag: '' });
  };

  handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      const updatedTags = this.state.tags.concat([this.state.newTag]);
      const updatedValue = updatedTags.join(',');
      this.setState({ tags: updatedTags });
      this.props.setValue(updatedValue);
      this.setState({ newTag: '' });
    }
  };

  handleClose = (index) => {
    const updatedTags = this.state.tags.slice();
    updatedTags.splice(index, 1);
    const updatedValue = updatedTags.join(',');
    this.setState({ tags: updatedTags });
    this.props.setValue(updatedValue);
  };

  render() {
    return (
      <>
        {this.state.tags.length > 0 ? (
          this.state.tags.map((tag, index) => (
            <Tag key={tag} color={"blue"} closable onClose={() => this.handleClose(index)}>
              {tag}
            </Tag>
          ))
        ) : (
          <span></span>
        )}
        {this.state.showAddTag && (
          <AutoComplete
            value={this.state.newTag}
            size={"small"}
            style={{width: 100, display:"inline-block"}}
            placeholder={"+Tag"}
            placeholderstyle={{ fontSize: 10 }}
            options={this.state.filteredValues}
            onChange={this.handleChange}
            onKeyDown={this.handleKeyDown}
            onSelect={this.handleSelect}
          />
        )}
      </>
    );
  }
}
