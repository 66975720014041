import React, {useState, useEffect, useRef, useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import {
    Button, Card, InputNumber, Select, Col, Modal, notification, Row, Typography, Input, Spin, Switch, Radio, Divider
} from 'antd';

import Icon, {
    CopyOutlined,
    DownloadOutlined,
    PlusCircleOutlined,
    PlusOutlined,
    ReloadOutlined, SaveOutlined
} from "@ant-design/icons";
import {JsonEditor} from 'json-edit-react'
import Ajv from 'ajv'
import schema from './pipeline_json_schema.json'
import axiosInstance from "../../services/axios";

const {Search} = Input;
const {Text} = Typography;
const ajv = new Ajv()
const validate = ajv.compile(schema)

const ConfigTool = (props) => {

    const [pipelineJSON, setPipelineJSON] = useState(null);
    const [modelJSON, setModelJSON] = useState(null);
    const [configPermission, setConfigPermission] = useState('editor');
    const [pipelineSearchValue, setPipelineSearchValue] = useState('');
    const [modelSearchValue, setModelSearchValue] = useState('');
    const navigate = useNavigate();

    const selectedFleet = '0f313a57-fbf3-42e0-a67c-5a3527040c00'

    // useEffect(() => {
    //     document.title = 'Anomaly Tool';
    //     // extract UAID from URL path
    //     const path = window.location.pathname;
    //
    //     if (path.includes("UAID")) {
    //         const parts = path.split("-");
    //         if (parts.length === 3 && parts[2].length === 12) {
    //             setUAID(path.substring(path.indexOf("UAID")));
    //         } else {
    //             notification['error']({
    //                 message: 'Not a valid UAID',
    //                 duration: 5
    //             });
    //         }
    //     }
    // }, []);

    const onLoad = (name) => {
        const payload = {
            fleet_uuid: selectedFleet,
            config_name: name,
        }
        axiosInstance.post('/api/config_tool/load_config', payload)
            .then(response => {
                console.log(response.data.config)
                if (name === 'pipeline_config') {
                    setPipelineJSON(response.data.config)
                }
                if (name === 'model_config') {
                    setModelJSON(response.data.config)
                }

                // notification['success']({
                //         message: 'Success loading ' + UAID,
                //         duration: 2.5,
                //         maxCount: 15
                //     }
            })
            .catch(error => {
                console.log(error);
                notification['error']({message: 'Error loading pipeline config', description: error.message});
            })
    };

    const onSave = (name) => {
        const payload = {
            fleet_uuid: selectedFleet,
            config_name: name,
            config: pipelineJSON
        }
        if (name === 'pipeline_config') {
            payload['config'] = pipelineJSON
        }
        if (name === 'model_config') {
            payload['config'] = modelJSON
        }

        axiosInstance.post('/api/config_tool/update_config', payload)
            .then(response => {
                notification['success']({
                    message: 'Success updating config for fleet ' + selectedFleet,
                    duration: 2.5,
                    maxCount: 15
                })
            })
            .catch(error => {
                console.log(error);
                notification['error']({message: 'Error updating pipeline config', description: error.message});
            })
    };

    const options = [
        {label: 'Editor', value: 'editor'},
        {label: 'Viewer', value: 'viewer'},
    ];

    const onChangePermission = ({target: {value}}) => {
        setConfigPermission(value);
    };

    const handlePipelineSearchChange = (e) => {
        setPipelineSearchValue(e.target.value);
    };

    const handleModelSearchChange = (e) => {
        setModelSearchValue(e.target.value);
    };

    return (<>
            <Row>
                <Text style={{color: 'red', fontWeight: 1000}}>
                    THIS IS A DEMO
                </Text>
            </Row>
            <Card
                style={{
                    width: '100%',
                    height: 58,
                    boxSizing: 'border-box',
                    display: 'flex',
                    flexDirection: 'column',
                    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                    border: '1px solid #e8e8e8',
                    marginBottom: 8
                }}
                styles={{
                    body: {
                        padding: '4px', display: 'flex', flexDirection: "column", flex: "1 1 auto"
                    }
                }}
            >
                <Row>
                    <Radio.Group
                        options={options} onChange={onChangePermission} value={configPermission}
                        defaultValue={configPermission}
                        optionType="button" style={{marginTop: 8, marginLeft: 8}}
                    />
                    <Divider type={'vertical'}
                             style={{
                                 border: '1px solid gray',
                                 marginBottom: 8,
                                 marginTop: 8,
                                 height: 32,
                                 marginLeft: 32
                             }}
                    ></Divider>
                    <Button
                        style={{marginTop: 8}}
                        type="link"
                        icon={<DownloadOutlined/>}
                        onClick={() => onLoad('pipeline_config')}
                    >
                        Load Pipeline Config
                    </Button>
                    <Input
                        style={{width: 200, marginTop: 8, height: 32}}
                        size={'small'}
                        value={pipelineSearchValue}
                        placeholder="search for anything in JSON"
                        onChange={handlePipelineSearchChange}
                    />
                    <Button
                        style={{marginTop: 8}}
                        type="link"
                        icon={<SaveOutlined/>}
                        disabled={configPermission === 'viewer'}
                        onClick={() => onSave('pipeline_config')}
                    >
                        Save Pipeline Config
                    </Button>
                    <Divider type={'vertical'}
                             style={{border: '1px solid gray', marginBottom: 8, marginTop: 8, height: 32}}
                    ></Divider>
                    <Button
                        style={{marginTop: 8}}
                        type="link"
                        icon={<DownloadOutlined/>}
                        onClick={() => onLoad('model_config')}
                    >
                        Load Model Config
                    </Button>
                    <Input
                        style={{width: 200, marginTop: 8, height: 32}}
                        size={'small'}
                        value={modelSearchValue}
                        placeholder="search for anything in JSON"
                        onChange={handleModelSearchChange}
                    />
                    <Button
                        style={{marginTop: 8}}
                        type="link"
                        icon={<SaveOutlined/>}
                        disabled={configPermission === 'viewer'}
                        onClick={() => onSave('model_config')}
                    >
                        Save Model Config
                    </Button>
                </Row>
            </Card>
            <Row>
                <Col gutter={8} style={{marginBottom: "8px", marginLeft: '0px'}} span={3}>

                </Col>
                <Col gutter={8} style={{marginBottom: "8px", marginLeft: '0px'}} span={8}>
                    {configPermission === 'editor' &&
                        < JsonEditor
                            data={pipelineJSON}
                            setData={setPipelineJSON}
                            searchFilter={'all'}
                            enableClipboard={false}
                            restrictDelete={true}
                            restrictAdd={true}
                            searchText={pipelineSearchValue}
                            onUpdate={({newData}) => {
                                const valid = validate(newData)
                                if (!valid) {
                                    console.log('Errors', validate.errors)
                                    const errorMessage = validate.errors
                                        ?.map((error) => `${error.instancePath}${error.instancePath ? ': ' : ''}${error.message}`)
                                        .join('\n')
                                    // Send detailed error message to an external UI element, such as a "Toast" notification
                                    notification['error']({
                                        message: 'Change not compliant with JSON Schema',
                                        description: errorMessage
                                    });
                                    // This string returned to and displayed in json-edit-react UI
                                    return 'JSON Schema error'
                                }
                            }}
                        />
                    }
                    {configPermission === 'viewer' &&
                        <JsonEditor
                            data={pipelineJSON}
                            searchFilter={'all'}
                            enableClipboard={false}
                            restrictEdit={true}
                            restrictDelete={true}
                            restrictDrag={true}
                            restrictAdd={true}
                            searchText={pipelineSearchValue}
                        />
                    }
                </Col>
                <Col gutter={8} style={{marginBottom: "8px", marginLeft: '0px'}} span={13}>
                    {configPermission === 'editor' &&
                        < JsonEditor
                            data={modelJSON}
                            setData={setModelJSON}
                            searchFilter={'all'}
                            enableClipboard={false}
                            searchText={modelSearchValue}
                            // onUpdate={({newData}) => {
                            //     const valid = true
                            //     if (!valid) {
                            //         console.log('Errors', validate.errors)
                            //         const errorMessage = validate.errors
                            //             ?.map((error) => `${error.instancePath}${error.instancePath ? ': ' : ''}${error.message}`)
                            //             .join('\n')
                            //         // Send detailed error message to an external UI element, such as a "Toast" notification
                            //         notification['error']({
                            //             message: 'Change not compliant with JSON Schema',
                            //             description: errorMessage
                            //         });
                            //         // This string returned to and displayed in json-edit-react UI
                            //         return 'JSON Schema error'
                            //     }
                            // }}
                        />
                    }
                    {configPermission === 'viewer' &&
                        <JsonEditor
                            data={modelJSON}
                            searchFilter={'all'}
                            enableClipboard={false}
                            restrictEdit={true}
                            restrictDelete={true}
                            restrictDrag={true}
                            restrictAdd={true}
                            searchText={modelSearchValue}
                        />
                    }
                </Col>
            </Row>
        </>
    );
}

export default ConfigTool;
