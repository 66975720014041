import React, {useState, useRef, useCallback, useEffect} from 'react';
import ReactJson from "react-json-view";
import {
    Button,
    Card,
    Descriptions,
    Select,
    Col,
    Modal,
    notification,
    Row,
    Typography,
    Input,
    Popover,
    Switch,
    Table,
    Divider
} from 'antd';

import Icon from "@ant-design/icons";
import {FaFile, FaCog} from 'react-icons/fa';
import axiosInstance from "services/axios";

const {Search} = Input;
const {Title} = Typography;


const AnomalyInfoCard = (props) => {

    const [data, setData] = useState(null);

    const UAID = props.UAID

    const getDashboardValues = () => {
        axiosInstance.post(
            '/api/anomaly_tool/info',
            {
                UAID: UAID,
            })
            .then(res => {
                console.log(res.data)
                props.setCustomer(res.data.customer)
                props.setAnomalyType(res.data.anomaly_in_alert)
                setData(res.data)
                props.setAnomalyInfo(res.data)
            })
            .catch(error => {
                console.log(error);
                setData(null)
                notification['error']({
                    message: 'Error in get AnomalyInfo',
                    description: (
                        <>
                            {error.message + ':'}<br/>
                            {error.response && error.response.data && error.response.data.message}
                        </>
                    ),
                });
            })
    }

    useEffect(() => {
        if (UAID !== null) {
            getDashboardValues();
        } else {
            setData(null)
        }
    }, [UAID, props.reload]);

    return (<>
            {/*<Modal*/}
            {/*  open={loading}*/}
            {/*  closable={false}*/}
            {/*  footer={null}*/}
            {/*  centered={true}*/}
            {/*  mask={true}*/}
            {/*  maskClosable={false}*/}
            {/*  bodyStyle={{ backdropFilter: 'blur(3px)' }}*/}
            {/*>*/}
            {/*  <Spin size="large" />*/}
            {/*</Modal>*/}
            <Card
                style={{
                    width: '100%',
                    height: '100%',
                    boxSizing: 'border-box',
                    display: 'flex',
                    flexDirection: 'column',
                    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                    border: '1px solid #e8e8e8'
                }}
                title={'Info'}
                bodyStyle={{
                    padding: '5px', display: 'flex', flexDirection: "column", flex: "1 1 auto"
                }}
            >
                <Descriptions bordered size={'middle'}>
                    <Descriptions.Item label="Customer">{data?.customer}</Descriptions.Item>
                    <Descriptions.Item label="UAID Type">{data?.uaid_type}</Descriptions.Item>
                </Descriptions>
                <Descriptions bordered size={'middle'}>
                    <Descriptions.Item label="Alert Level">{data?.anomaly_in_alert}</Descriptions.Item>
                    <Descriptions.Item label="Alert Status">{data?.anomaly_type ? data.anomaly_type : '-'}</Descriptions.Item>
                </Descriptions>
                <Descriptions bordered size={'middle'}>
                    <Descriptions.Item label="Box">{data?.box_id}</Descriptions.Item>
                    <Descriptions.Item label="Box Type">{data?.box_type}</Descriptions.Item>
                </Descriptions>
                <Descriptions bordered size={'middle'}>
                    <Descriptions.Item label="Vehicle">{data?.vehicle_id}</Descriptions.Item>
                    <Descriptions.Item label="Vehicle Type">{data?.vehicle_type}</Descriptions.Item>
                </Descriptions>
                <Descriptions bordered size={'middle'}>
                    <Descriptions.Item label="UTC Time">{data?.utc_time}</Descriptions.Item>
                    <Descriptions.Item label="UTC Date">{data?.utc_date}</Descriptions.Item>
                </Descriptions>
                <Descriptions bordered size={'middle'}>
                    <Descriptions.Item label="Time">{data?.time}</Descriptions.Item>
                    <Descriptions.Item label="Date">{data?.date}</Descriptions.Item>
                </Descriptions>
                {/*<Descriptions bordered size={'middle'}>*/}
                {/*    <Descriptions.Item label="Address">{data?.address}</Descriptions.Item>*/}
                {/*    <Descriptions.Item label="City">{data?.city}</Descriptions.Item>*/}
                {/*</Descriptions>*/}
                <Descriptions bordered size={'middle'}>
                    <Descriptions.Item label="Velocity">{data?.velocity} km/h</Descriptions.Item>
                    <Descriptions.Item label="Event">{data?.event_id ? data.event_id : '-'}</Descriptions.Item>
                </Descriptions>
            </Card>
        </>
    );
}


export default AnomalyInfoCard;
