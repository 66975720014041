import React, { useState } from 'react';
import { Row, Descriptions, Button, Input } from 'antd';
import { EditOutlined } from '@ant-design/icons';

const DescriptionEdit = (props) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editedData, setEditedData] = useState('');

    const handleEditClick = () => {
        setIsEditing(true);
        setEditedData(props.data);
    };

    const handleSaveClick = () => {
        // Handle saving the edited data (e.g., update the state or make an API call)
        props.onSave(props.field, editedData);
        setIsEditing(false);
    };

    const handleCancelClick = () => {
        setIsEditing(false);
    };

    return (
        <Row>
            <Descriptions bordered size={'middle'}>
                <Descriptions.Item label={props.label}>
                    {isEditing ? (
                        <Input value={editedData} onChange={(e) => setEditedData(e.target.value)} />
                    ) : (
                        props.data
                    )}
                </Descriptions.Item>
            </Descriptions>
            {isEditing ? (
                <>
                    <Button style={{marginTop: 12}} type="primary" onClick={handleSaveClick}>
                        Save
                    </Button>
                    <Button style={{marginTop: 12}} onClick={handleCancelClick}>Cancel</Button>
                </>
            ) : (
                <Button style={{marginTop: 8}} type="link" icon={<EditOutlined />} onClick={handleEditClick}>
                    Edit
                </Button>
            )}
        </Row>
    );
};

export default DescriptionEdit;