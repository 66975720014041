import React, {useEffect, useState} from 'react';
import {Row, Descriptions, Button, Input, Select} from 'antd';
import {EditOutlined, DeleteOutlined, CheckOutlined, CloseOutlined} from '@ant-design/icons';

const {Option} = Select;

const DescriptionEdit = (props) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editedData, setEditedData] = useState(props.data);

    useEffect(() => {
        setEditedData(props.data)
    }, [props.UAID, props.data]);

    const handleConfirmClick = () => {
        props.onConfirm(props.field);
    };

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleSaveClick = () => {
        // Handle saving the edited data (e.g., update the state or make an API call)
        props.onSave(props.field, editedData);
        setIsEditing(false);
    };

    const handleCancelClick = () => {
        setIsEditing(false);
    };

    const handleDeleteClick = () => {
        setEditedData(null);
    };

    const handleSelectChange = (value) => {
        setEditedData(value);
    };

    return (
        <Row>
            <Descriptions bordered size={'middle'}>
                <Descriptions.Item label={props.label} style={{padding: 8, height: 48}}>
                    {isEditing ? (
                        <Select
                            value={editedData}
                            onChange={handleSelectChange}
                            style={{width: props.width ? props.width : 70}}
                        >
                            {props.fixedValues.map((option) => (
                                <Option key={option.value} value={option.value}>
                                    {`${option.value}${option.label}`}
                                </Option>
                            ))}
                        </Select>
                    ) : (
                        props.data
                    )}
                </Descriptions.Item>
            </Descriptions>
            {isEditing ? (
                <>
                    <Button
                        type="link"
                        icon={<DeleteOutlined/>}
                        style={{marginTop: 8}}
                        onClick={handleDeleteClick}
                    >
                        Delete
                    </Button>
                    <Button style={{marginTop: 8}} type="primary" onClick={handleSaveClick}>
                        Save
                    </Button>
                    <Button
                        style={{marginTop: 8}}
                        onClick={handleCancelClick}
                        icon={<CloseOutlined/>}
                    >
                    </Button>
                </>
            ) : (
                <>
                    <Button style={{marginTop: 8}} type="link" icon={<EditOutlined/>} onClick={handleEditClick}>
                        Edit
                    </Button>
                    {props.showConfirm && props.data !== null &&
                        <>
                            {!props.confirm &&
                                <Button style={{marginTop: 8}} type="link" icon={<CheckOutlined/>}
                                        onClick={handleConfirmClick}>
                                    Confirm
                                </Button>
                            }
                            {props.confirm &&
                                <Button disabled={true} style={{marginTop: 8}} type="link" icon={<CheckOutlined/>}
                                        onClick={handleConfirmClick}>
                                    Confirmed - {new Date(props.confirmDate).toJSON()}
                                </Button>
                            }
                        </>
                    }
                </>
            )}
        </Row>
    );
};

export default DescriptionEdit;
