import React, {useState, useRef, useCallback, useEffect} from 'react';
import 'ag-grid-enterprise';
import {AgGridReact} from 'ag-grid-react';
import {LicenseManager} from 'ag-grid-enterprise';
import './DamageTool.css';

import {
    Button, Card, Select, Col, Dropdown, Menu, notification, Row, Space, Typography, Radio, Switch, Divider, DatePicker
} from 'antd';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import Icon, {CheckSquareOutlined} from "@ant-design/icons";
import {FaCar} from 'react-icons/fa';
import axiosInstance from "services/axios";
import PlotRenderer from "../PlotRenderer/PlotRenderer";
import TagRenderer from "../TagRenderer/TagRenderer";
import CarPartRenderer from "../CarPartRenderer/CarPartRenderer";
import {customLogicFilter} from "../CustomLogicFilter/CustomLogicFilter";
import DetailsCard from "../DetailsCard/DetailsCard";

import 'ag-grid-autocomplete-editor/dist/main.css';
import MultiImageRenderer from "../MultiImageRenderer/MultiImageRenderer";
import MultiImageRendererV2 from "../MultiImageRendererV2/MultiImageRendererV2";

import TagValues from "../TagValues/TagValues.js"

import {
    dateTimeFormatter, CheckboxRenderer, EventURLRenderer, AnomalyURLRenderer
} from "../CellHelpers/CellHelpers";
import {getColumnDefs} from "../MonitoringDashboard/MonitoringDashboardColumns";
import CustomSelectEditor from "../CustomSelectEditor/CustomSelectEditor";
import dayjs from "dayjs";

LicenseManager.setLicenseKey("CompanyName=carValoo GmbH,LicensedGroup=carValoo,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-038534,SupportServicesEnd=21_February_2024_[v2]_MTcwODQ3MzYwMDAwMA==569a60f2ef339ea47d5835a5fc039c3f")

const {Text} = Typography;
const {Option} = Select;
const {RangePicker} = DatePicker;


const DamageTool = (props) => {

    useEffect(() => {
        document.title = 'Damage Tool';
    }, []);

    const gridRef = useRef();
    const [selectedData, setSelectedData] = useState({id: null});
    const [damageFilter, setDamageFilter] = useState('damage_confirmed');
    const [selectedId, setSelectedId] = useState(null);
    const [detailCardOpen, setDetailCardOpen] = useState(false);
    const handleDetailsCardChange = useCallback((data) => setDetailCardOpen(data), []);
    const [dateRange, setDateRange] = useState([null, null]);
    const dateRangeRef = useRef([null, null]);

    let columnState = [];

    useEffect(() => {
        dateRangeRef.current = dateRange;
        if (gridRef.current.api) {
            gridRef.current.api.refreshServerSide();
        }
    }, [dateRange]);

    // Function to update the column state
    function updateColumnState() {
        // Get the current column state
        let columnState = gridRef.current.columnApi.getColumnState();

        // Set the sort key to null for all columns
        columnState = columnState.map(column => ({
            ...column,
            sort: null,
            sortIndex: null // If you want to reset the sort index as well
        }));

        // Store the updated column state in the session storage
        localStorage.setItem("carvalooDamageToolColumns", JSON.stringify(columnState));
    }

    const onResetColumns = useCallback(() => {
        gridRef.current.columnApi.resetColumnState();
        console.log('column state reset');
    }, []);

    const handleRowSelection = () => {
        const selectedNodes = gridRef.current.api.getSelectedNodes();
        if (selectedNodes.length) {
            setSelectedId(selectedNodes[0].data.id);
        } else {
            setSelectedId(null);
        }
    };

    const datasourceServerSide = {
        getRows(params) {
            // console.log("Request data with params: " + JSON.stringify(params.request, null, 1));

            // default sort by newest element
            if (params.request.sortModel.length === 0) {
                params.request.sortModel = [
                    {
                        "sort": "desc",
                        "colId": "date"
                    }
                ]
            }

            // and/or based text filter
            const keys = ['damage_tag', 'pattern', 'carPart_id'];
            params.request.filterModel = customLogicFilter(params.request.filterModel, keys)

            // list based filter for UAIDs
            if ('UAID' in params.request.filterModel) {
                if (params.request.filterModel['UAID'].type === 'contains') {
                    let filter_string = params.request.filterModel['UAID'].filter;
                    if (filter_string.includes(',')) {
                        params.request.filterModel['UAID'].filterType = "list"
                    }
                }
            }

            // list based filter for event ids
            if ('event_id' in params.request.filterModel) {
                if (params.request.filterModel['event_id'].type === 'contains') {
                    let filter_string = params.request.filterModel['event_id'].filter;
                    if (filter_string.includes(',')) {
                        params.request.filterModel['event_id'].filterType = "list"
                    }
                }
            }

            const currentRange = dateRangeRef.current;
            if (currentRange && currentRange[0] && currentRange[1]) {
                const startDate = dayjs(currentRange[0]).format('YYYY-MM-DD HH:mm:ss');
                const endDate = dayjs(currentRange[1]).format('YYYY-MM-DD HH:mm:ss');

                // Overwrite the current date filter
                params.request.filterModel['date'] = {
                    dateFrom: startDate,
                    dateTo: endDate,
                    filterType: 'date',
                    type: 'inRange'
                };
            }
            if (currentRange === null && params.request.filterModel?.date?.type === 'inRange') {
                delete params.request.filterModel.date;
            }

            console.log("Request data with params: " + JSON.stringify(params.request, null, 1));

            axiosInstance.post('/api/damage_tool/query', params.request)
                .then(res => {
                    // console.log(res.data.data)
                    params.successCallback(res.data.data, res.data.lastRow);
                    // let rowCount = res.data.lastRow.toString(10);
                    // notification['success']({
                    //     message: 'Total Row Count: ' + rowCount,
                    //     duration: 10,
                    //     maxCount: 1
                    //   }
                    // );
                })
                .catch(error => {
                    console.log(error);
                    params.fail();
                    notification['error']({message: 'Error in getRows', description: error.message});
                })
        }
    };

    const onChangeDamageFilter = ({target: {value}}) => {

        let currentFilterModel = gridRef.current.api.getFilterModel();
        setDamageFilter(value)

        // filter to only show damages or alerts+damages
        if (value === 'damages') {
            console.log('Changed to damages')
            currentFilterModel.damage_confirmed = {
                "filterType": "set",
                "values": [
                    "true"
                ]
            }
            currentFilterModel.damage_detected = {
                "filterType": "set",
                "values": [
                    "true"
                ]
            }
            // currentFilterModel.matching_confidence = {
            //     "filterType": "set",
            //     "values": [
            //         1, 2, 3, 4
            //     ]
            // }
        }
        if (value === 'alerts') {
            console.log('Changed to alerts')
            currentFilterModel.damage_confirmed = {
                "filterType": "set",
                "values": [
                    "false"
                ]
            }
            currentFilterModel.damage_detected = {
                "filterType": "set",
                "values": [
                    "true"
                ]
            }
        }
        if (value === 'all') {
            console.log('Changed to all')
            delete currentFilterModel.damage_confirmed;
            currentFilterModel.damage_detected = {
                "filterType": "set",
                "values": [
                    "true"
                ]
            }
        }

        gridRef.current.api.setFilterModel(currentFilterModel);
        console.log('Updated filter model for damage filtering')
    }

    const onCellValueChanged = (params) => {
        console.log(params);

        let id = params.data.id;
        let UAID = params.data.UAID

        let col = params.column.getColId();
        if (params.oldValue !== params.newValue) {
            let data = {};
            data['UAID'] = UAID
            data[col] = params.newValue;

            console.log('Updating cell value ...')
            console.log(params)
            console.log(data)

            axiosInstance.patch('/api/damage_tool/' + id, data)
                .then(response => {
                    //params.success(response.values);
                    console.log('Updated successfully');
                    console.log(response);
                    console.log(params);
                    notification['success']({
                            message: 'Updated entry of Event ' + UAID,
                            duration: 1.5,
                            maxCount: 10
                        }
                    );
                    // gridRef.current.api.purgeServerSideCache();
                })
                .catch(error => {
                    console.log(error);
                    notification['error']({message: 'Error updating', description: error.message});
                })
        }
    }
    //
    // function severityValueSetter(params) {
    //     if (!["high", "medium", "low", 'very low', 'NA'].includes(params.newValue)) {
    //         notification['warning']({
    //             message: "Invalid input! Please enter only 'high', 'medium', 'low', 'very low' or 'NA'"
    //         });
    //         return false;
    //     }
    //     params.data.event_severity = params.newValue
    //     return true;
    // }

    const onSelectAllChanged = (state) => {
        console.log(state);
        let currentPage = gridRef.current.api.paginationGetCurrentPage();
        let nodesPerPage = gridRef.current.api.paginationGetPageSize();
        if (state === "deselectAll") {
            gridRef.current.api.deselectAll();
        } else {
            gridRef.current.api.forEachNode(node => {
                if ((node.rowIndex >= (currentPage * nodesPerPage)) && (node.rowIndex < ((currentPage + 1) * nodesPerPage))) {
                    if (state === "toggle") {
                        node.setSelected(!node.selected);
                    } else if (state === "selectAll") {
                        node.setSelected(true);
                    } else if (state === "deselectAllOnPage") {
                        node.setSelected(false);
                    }

                }
            })
        }
    }

    const onResetAllFilters = () => {
        if (damageFilter === 'all') {
            console.log('Reset to all')
            gridRef.current.api.setFilterModel(
                {'damage_detected': {"values": ["true"], "filterType": "set"}})
        } else if (damageFilter === 'damages') {
            console.log('Reset to damages')
            gridRef.current.api.setFilterModel(
                {
                    'damage_detected': {"values": ["true"], "filterType": "set"},
                    'damage_confirmed': {"values": ["true"], "filterType": "set"}
                })
        } else if (damageFilter === 'alerts') {
            console.log('Reset to alerts')
            gridRef.current.api.setFilterModel(
                {
                    'damage_detected': {"values": ["true"], "filterType": "set"},
                    'damage_confirmed': {"values": ["false"], "filterType": "set"}
                })
            console.log(gridRef.current.api.getFilterModel())

        }
    }

    const getDistinctValues = (params) => {
        let column = params.column.colId;
        axiosInstance.get('/api/damage_tool/distinct/' + column)
            .then(res => {
                params.success(res.data.data);
            })
            .catch(error => {
                console.log(error);
                notification['error']({
                    message: 'Error in getDistinctValues',
                    description: (
                        <>
                            {error.message + ':'}<br/>
                            {error.response && error.response.data && error.response.data.message}
                        </>
                    ),
                });
            })
    }

    const updateSelectedData = () => {
        let selectedNodes = gridRef.current.api.getSelectedNodes();
        if (selectedNodes.length) {

            let selectedNode = selectedNodes[0];
            let data = {
                id: selectedNode.data.id,
                UAID: selectedNode.data.UAID,
                event_id: selectedNode.data.event_id,
                customer: selectedNode.data.customer,
                box_id: selectedNode.data.box_id,
                date: selectedNode.data.date,
                location: selectedNode.data.location,
                damage_tag: selectedNode.data.damage_tag,
                pattern: selectedNode.data.pattern,
                comment: selectedNode.data.comment,
                driving_situation: selectedNode.data.driving_situation,
                prediction_score: selectedNode.data.prediction_score,
                severity: selectedNode.data.severity,
                event_direction: selectedNode.data.event_direction,
                UAID_image: selectedNode.data.UAID_image,
                trip_image: selectedNode.data.trip_image,
                event_image: selectedNode.data.event_image,
            };
            // console.log('selected data', data);
            setSelectedData({
                ...data,
            });
        } else {
            // console.log('Nothing selected');
            setSelectedData({
                id: null
            });
        }
    }

    function onRefreshData() {
        gridRef.current.api.refreshServerSide();
        console.log("Refreshing data")
    }

    //
    // const copyAsCSV = async (params) => {
    //     const focusedCell = params.api.getFocusedCell();
    //
    //     const selectedNodes = params.api.getSelectedNodes();
    //     const selectedData = selectedNodes.map(node => node.data);
    //
    //     // Convert selected data to CSV format
    //     const csvContent = selectedData.map(row => Object.values(row).join(',')).join('\n');
    //
    //     // Copy CSV content to clipboard using Clipboard API
    //     try {
    //         await navigator.clipboard.writeText(csvContent);
    //         console.log('CSV copied to clipboard:', csvContent);
    //     } catch (err) {
    //         console.error('Failed to copy CSV to clipboard:', err);
    //     }
    // };
    //
    // const getContextMenuItems = (params) => {
    //     const result = [
    //         'copy',
    //         'copyWithHeaders',
    //         'copyWithGroupHeaders',
    //         {
    //             name: 'Copy as CSV List',
    //             action: () => copyAsCSV(params),
    //             icon: '<i class="fa fa-files-o"></i>' // Optional icon
    //         },
    //         'paste',
    //         'export',
    //     ];
    //     return result;
    // };

    const gridOptions = {
        rowModelType: 'serverSide',
        cacheBlockSize: 100,
        rowSelection: 'multiple',
        suppressRowClickSelection: false,
        pagination: true,
        paginationPageSize: 50, //paginationAutoPageSize: true,
        suppressLastEmptyLineOnPaste: true,
        enableRangeSelection: true,
        components: {
            checkboxRenderer: CheckboxRenderer
        },
        columnDefs: [
            {
                field: 'id',
                hide: true,
                width: 75,
                headerName: 'Id',
                floatingFilter: false,
            },
            {
                field: 'UAID',
                headerTooltip: "Unique Anomaly ID",
                width: 150,
                headerName: 'UAID',
                floatingFilter: true,
                filter: 'agTextColumnFilter',
                cellRenderer: AnomalyURLRenderer,
                suppressFiltersToolPanel: true,
                suppressMenu: false,
            },
            {
                field: 'event_id',
                headerTooltip: "Event ID",
                width: 150,
                headerName: 'Event',
                floatingFilter: true,
                filter: 'agTextColumnFilter',
                cellRenderer: EventURLRenderer,
                suppressFiltersToolPanel: true,
            },
            {
                field: 'date',
                width: 155,
                headerTooltip: "Filter by day and sort by datetime",
                headerName: 'Date',
                floatingFilter: true,
                valueFormatter: dateTimeFormatter,
                filter: 'agDateColumnFilter',
                filterParams: {
                    inRangeInclusive: true,
                    suppressAndOrCondition: true,
                }
            },
            {
                field: 'assignment_status',
                hide: true,
                headerName: 'Status',
                floatingFilter: true,
                filter: 'agSetColumnFilter',
                filterParams: {
                    values: params => {
                        getDistinctValues(params);
                    }
                },
            },
            {
                field: 'customer',
                headerName: 'Customer',
                floatingFilter: true,
                filter: 'agSetColumnFilter',
                filterParams: {
                    values: params => {
                        getDistinctValues(params);
                    }
                },
            },
            {
                field: 'fleet',
                headerName: 'Fleet',
                hide: true,
                floatingFilter: true,
                filter: 'agSetColumnFilter',
                filterParams: {
                    values: params => {
                        getDistinctValues(params);
                    }
                },
            },
            {
                field: 'station',
                headerName: 'Station',
                hide: true,
                floatingFilter: true,
                filter: 'agSetColumnFilter',
                filterParams: {
                    values: params => {
                        getDistinctValues(params);
                    }
                },
            },
            {
                field: 'box_id',
                headerName: 'Box',
                hide: true,
                floatingFilter: true,
                filter: 'agNumberColumnFilter',
            },
            {
                field: 'vehicle_id',
                headerName: 'Vehicle',
                hide: true,
                floatingFilter: true,
                filter: 'agTextColumnFilter',
            },
            // {
            //     field: 'vehicle_type_installation',
            //     hide: true,
            //     headerName: 'Installation',
            // },
            {
                field: 'detected',
                headerName: 'Detected',
                headerTooltip: "Detected by which pipeline",
                floatingFilter: true,
                width: 130,
                filter: 'agSetColumnFilter',
                filterParams: {
                    values: params => {
                        getDistinctValues(params);
                    }
                },
                // cellRenderer: function (params) {
                //     // Check if the "created_by" field exists in the row data
                //     if (params.data && params.data.anomaly_config && params.data.anomaly_config.created_by) {
                //         // Return the value of the "created_by" field
                //         return params.data.anomaly_config.created_by;
                //     } else {
                //         // Return a blank string if the "created_by" field is not present
                //         return '';
                //     }
                // }
            },
            {
                field: 'vehicle_category',
                headerName: 'Category',
                headerTooltip: "Vehicle Category - low detail",
                hide: true,
                floatingFilter: true,
                filter: 'agSetColumnFilter',
                filterParams: {
                    values: params => {
                        getDistinctValues(params);
                    }
                },
            },
            {
                field: 'vehicle_class',
                headerName: 'Class',
                headerTooltip: "Vehicle Class - medium level",
                hide: true,
                floatingFilter: true,
                filter: 'agSetColumnFilter',
                filterParams: {
                    values: params => {
                        getDistinctValues(params);
                    }
                },
            },
            {
                field: 'vehicle_type',
                headerName: 'Type',
                headerTooltip: "Vehicle Type - high level",
                hide: true,
                floatingFilter: true,
                filter: 'agSetColumnFilter',
                filterParams: {
                    values: params => {
                        getDistinctValues(params);
                    }
                },
            },
            {
                field: 'engine_type',
                headerName: 'Engine',
                headerTooltip: "Engine Type",
                hide: true,
                floatingFilter: true,
                filter: 'agSetColumnFilter',
                filterParams: {
                    values: params => {
                        getDistinctValues(params);
                    }
                },
            },
            {
                field: 'location',
                headerName: 'Location',
                headerTooltip: "Location based on event GPS coordinates",
                hide: true,
                floatingFilter: true,
                filter: 'agTextColumnFilter',
            },
            {
                field: 'velocity',
                headerName: 'Velocity',
                headerTooltip: "Velocity based on event GPS coordinates",
                hide: true,
                floatingFilter: true,
                filter: 'agNumberColumnFilter',
            },
            {
                field: 'comment',
                width: 150,
                suppressFiltersToolPanel: true,
                headerName: 'Comment',
                headerTooltip: "Free flowing comment - no structure",
                floatingFilter: true,
                filter: 'agTextColumnFilter',
                editable: true,
                wrapText: true,
            },
            {
                field: 'damage_tag',
                suppressFiltersToolPanel: true,
                width: 145,
                headerName: 'Damage',
                headerTooltip: "Description to tag the type of damage that occurred",
                floatingFilter: true,
                filter: 'agTextColumnFilter',
                cellRenderer: TagRenderer,
                cellRendererParams: {
                    columnValues: TagValues.distinctDamageValues,
                },
                wrapText: true,
                autoHeight: true,
            },
            {
                field: 'situation_tag',
                suppressFiltersToolPanel: true,
                width: 140,
                headerName: 'Situation',
                headerTooltip: "Description to tag the type of situation that occurred",
                floatingFilter: true,
                filter: 'agTextColumnFilter',
                cellRenderer: TagRenderer,
                cellRendererParams: {
                    columnValues: TagValues.distinctSituationValues,
                },
                wrapText: true,
                autoHeight: true,
            },
            {
                field: 'pattern',
                suppressFiltersToolPanel: true,
                width: 140,
                headerName: 'Pattern',
                headerTooltip: "Description to tag the type of signal that occurred",
                floatingFilter: true,
                filter: 'agTextColumnFilter',
                cellRenderer: TagRenderer,
                cellRendererParams: {
                    columnValues: TagValues.distinctPatternValues,
                },
                wrapText: true,
                autoHeight: true,
            },
            {
                field: 'carPart_id',
                width: 120,
                headerName: 'Car Part',
                headerTooltip: "Visualized car part ids for damage",
                floatingFilter: true,
                filter: 'agTextColumnFilter',
                wrapText: true,
                cellRenderer: CarPartRenderer,
                autoHeight: true,
            },
            {
                field: 'driving_situation',
                headerName: 'Model Situation',
                hide: true,
                headerTooltip: "Model predicted driving situation",
                suppressFiltersToolPanel: true,
                floatingFilter: true,
                wrapText: true,
                filter: 'agSetColumnFilter',
                filterParams: {
                    values: params => {
                        getDistinctValues(params);
                    }
                },
            },
            {
                field: 'prediction_score',
                headerName: 'Max() Score',
                headerTooltip: "Model predicted anomaly score - maximum score",
                suppressFiltersToolPanel: true,
                width: 90,
                floatingFilter: true,
                hide: true,
                filter: 'agNumberColumnFilter',
            },
            {
                field: 'lfc_tree_6f',
                headerName: 'LFC6f Score',
                headerTooltip: "Model predicted anomaly score - 6f or 6g model",
                suppressFiltersToolPanel: true,
                width: 90,
                hide: true,
                floatingFilter: true,
                filter: 'agNumberColumnFilter',
            },
            {
                field: 'lfc_tree_8c',
                headerName: 'LFC8c Score',
                headerTooltip: "Model predicted anomaly score - 8c or 8d model",
                suppressFiltersToolPanel: true,
                width: 90,
                hide: true,
                floatingFilter: true,
                filter: 'agNumberColumnFilter',
            },
            {
                field: 'wavenet_1',
                headerName: 'Wavenet Score',
                headerTooltip: "Model predicted anomaly score - wavenet_1 & wavenet_2.0 & wavenet_2.1 model",
                suppressFiltersToolPanel: true,
                width: 100,
                hide: true,
                floatingFilter: true,
                filter: 'agNumberColumnFilter',
            },
            {
                field: 'wavenet_2',
                headerName: 'Wavenet 2.2 Score',
                headerTooltip: "Model predicted anomaly score - wavenet_2.2 model",
                suppressFiltersToolPanel: true,
                width: 100,
                floatingFilter: true,
                hide: true,
                filter: 'agNumberColumnFilter',
            },
            {
                field: 'event_severity',
                width: 110,
                suppressFiltersToolPanel: true,
                headerName: 'Severity',
                hide: true,
                editable: true,
                headerTooltip: "Text severity level for event",
                floatingFilter: true,
                cellEditor: CustomSelectEditor,
                cellEditorParams: {
                    field: 'event_severity',
                    fixedValues: [
                        {value: null, label: ""},
                        {value: 'impact_without_damage', label: ""},
                        {value: 'low', label: ""},
                        {value: 'medium', label: ""},
                        {value: 'high', label: ""},
                    ]
                },
                filter: 'agSetColumnFilter',
                filterParams: {
                    values: params => {
                        getDistinctValues(params);
                    }
                },
            },
            {
                field: 'severity',
                width: 110,
                hide: true,
                suppressFiltersToolPanel: true,
                headerName: 'Model Severity',
                headerTooltip: "Text severity level for anomaly",
                floatingFilter: true,
                filter: 'agSetColumnFilter',
                filterParams: {
                    values: params => {
                        getDistinctValues(params);
                    }
                },


            },
            {
                field: 'event_direction_str',
                headerName: 'Model Direction',
                hide: true,
                width: 125,
                headerTooltip: "Model predicted event direction",
                suppressFiltersToolPanel: true,
                floatingFilter: true,
                filter: 'agSetColumnFilter',
                filterParams: {
                    values: ['vorne', 'vorne rechts', 'vorne links', 'hinten', 'hinten rechts', 'hinten links',
                        'rechts vorne', 'rechts', 'rechts hinten', 'links vorne', 'links', 'links hinten'],
                },
            },
            {
                field: 'event_direction',
                // valueGetter: AngleToStringGetter,
                headerName: 'Model Direction*',
                hide: true,
                width: 125,
                suppressFiltersToolPanel: true,
                floatingFilter: true,
                filter: 'agNumberColumnFilter',
                // filter: 'agSetColumnFilter',
                // filterParams: {
                //     values: ['vorne', 'vorne rechts', 'vorne links', 'hinten', 'hinten rechts', 'hinten links',
                //              'rechts vorne', 'rechts', 'rechts hinten', 'links vorne', 'links', 'links hinten'],
                // },
            },
            {
                field: 'underbody_impact_str',
                headerName: 'Underbody Impact',
                hide: true,
                width: 125,
                headerTooltip: "Model predicted underbody impact",
                suppressFiltersToolPanel: true,
                floatingFilter: true,
                filter: 'agSetColumnFilter',
                filterParams: {
                    values: params => {
                        getDistinctValues(params);
                    }
                },
                wrapText: true,
            },
            {
                field: 'incident_flag',
                headerName: 'Incidents',
                headerTooltip: "Boolean if incidents exist in the VorfallDB (14 days after) for the anomaly",
                hide: true,
                floatingFilter: true,
                filter: 'agSetColumnFilter',
                filterParams: {
                    values: params => {
                        getDistinctValues(params);
                    }
                },
                cellRenderer: 'checkboxRenderer',
                sortable: true,
            },
            {
                field: 'reactive',
                headerName: 'Reactive',
                headerTooltip: "Boolean if event was matched reactively",
                hide: true,
                suppressFiltersToolPanel: true,
                cellRenderer: 'checkboxRenderer',
                floatingFilter: true,
                filter: 'agSetColumnFilter',
                filterParams: {
                    values: params => {
                        getDistinctValues(params);
                    }
                },
            },
            {
                field: 'damage_detected',
                headerName: 'Damage Detected',
                hide: true,
                suppressFiltersToolPanel: true,
                cellRenderer: 'checkboxRenderer',
                floatingFilter: true,
                filter: 'agSetColumnFilter',
                filterParams: {
                    values: params => {
                        getDistinctValues(params);
                    }
                },
            },
            {
                field: 'damage_confirmed',
                headerName: 'Damage Confirmed',
                hide: true,
                suppressFiltersToolPanel: true,
                cellRenderer: 'checkboxRenderer',
                floatingFilter: true,
                filter: 'agSetColumnFilter',
                filterParams: {
                    values: params => {
                        getDistinctValues(params);
                    }
                },
            },
            {
                field: 'matching_confidence',
                headerName: 'Confidence',
                headerTooltip: "Matching confidence: 4: sure TP --> 1: unlikely TP --> 0: unsure --> -1: unlikely FP --> -4: sure FP",
                hide: true,
                suppressFiltersToolPanel: true,
                floatingFilter: true,
                editable: true,
                cellEditor: CustomSelectEditor,
                cellEditorParams: {
                    fixedValues: [
                        {value: 4, label: ": sure TP"},
                        {value: 3, label: ": plausible TP"},
                        {value: 2, label: ": possible TP"},
                        {value: 1, label: ": unlikely TP"},  // Add your new value here
                        {value: 0, label: ": unsure"},
                        {value: -1, label: ": unlikely FP"},
                        {value: -2, label: ": possible FP"},
                        {value: -3, label: ": plausible FP"},
                        {value: -4, label: ": sure FP"}
                    ]
                },
                filter: 'agSetColumnFilter',
                filterParams: {
                    values: params => {
                        getDistinctValues(params);
                    }
                },
            },
            {
                field: 'UAID_image',
                headerName: 'UAID Image',
                width: 200,
                cellRenderer: PlotRenderer,
                cellRendererParams: {'index': 0},
                autoHeight: true,
                wrapText: true,
                sortable: false,
            },
            {
                field: 'trip_image',
                headerName: 'Trip Image',
                hide: true,
                cellRenderer: PlotRenderer,
                cellRendererParams: {'index': 1},
                autoHeight: true,
                wrapText: true,
                sortable: false,
            },
            {
                field: 'event_image',
                headerName: 'Event Image',
                minWidth: 160,
                cellRenderer: MultiImageRendererV2,
                autoHeight: true,
                // wrapText: true,
                sortable: false,
            },
            {
                field: 'modified_datetime',
                headerName: 'Modified UTC',
                width: 155,
                hide: true,
                headerTooltip: "Filter by day and sort by datetime",
                floatingFilter: true,
                valueFormatter: dateTimeFormatter,
                filter: 'agDateColumnFilter',
                filterParams: {
                    inRangeInclusive: true,
                    suppressAndOrCondition: true,
                }
            },
        ],
        defaultColDef: {
            width: 100,
            sortable: true,
            filter: false,
            resizable: true, //minWidth: 100,
            editable: false, //cellEditor: PopupCellEditor
            suppressMenu: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
        },
        sideBar: {
            defaultToolPanel: '',
            position: 'right',
            toolPanels: [
                {
                    id: 'columns',
                    labelDefault: 'Columns',
                    labelKey: 'columns',
                    iconKey: 'columns',
                    toolPanel: 'agColumnsToolPanel',
                    minWidth: 100,
                    maxWidth: 225,
                    width: 175,
                    toolPanelParams: {
                        suppressRowGroups: true,
                        suppressValues: true,
                        suppressPivotMode: true
                    }
                },
                {
                    id: 'filters',
                    labelDefault: 'Filters',
                    labelKey: 'filters',
                    iconKey: 'filter',
                    toolPanel: 'agFiltersToolPanel',
                    minWidth: 150,
                    maxWidth: 400,
                    width: 180
                }
            ],
        },
        // statusBar: {
        //   statusPanels: [
        //     {
        //       statusPanel: StatusBarComponent,
        //       align: 'left',
        //       key: 'totalRowCount',
        //       params: {
        //         rowCount: totalRowCount
        //       }
        //     }
        //   ]
        // },
        // getContextMenuItems: getContextMenuItems,
        getMainMenuItems(params) {
            // you don't need to switch, we switch below to just demonstrate some different options
            // you have on how to build up the menu to return
            switch (params.column.getId()) {
                // return the defaults, put add some extra items at the end
                // case 'box_id':
                //   const boxmenu: (| MenuItemDef | string)[] = params.defaultItems.slice(0);
                //   boxmenu.push({
                //     name: 'Supply filter list', action: () => {
                //       updateFilterValues(params);
                //     },
                //   });
                //   return boxmenu;

                default:
                    // make no changes, just accept the defaults
                    return params.defaultItems;
            }

        },
        onSelectionChanged: (event) => {
            console.log('selection changed');
            updateSelectedData();
        },
        onGridReady: function () {
            gridRef.current.api.setServerSideDatasource(datasourceServerSide);
            gridRef.current.api.setFilterModel({
                'damage_confirmed': {
                    "values": [
                        "true"
                    ],
                    "filterType": "set"
                }
            });

            // Step 1: Retrieve the column state JSON string from session storage
            const columnStateString = localStorage.getItem("carvalooDamageToolColumns");
            if (columnStateString) {
                // Step 2: Convert the JSON string back to an array
                const columnState = JSON.parse(columnStateString);

                // Step 3: Apply the column state to ag-Grid
                gridRef.current.columnApi.applyColumnState({state: columnState});
            }

            console.log('grid ready');
        },
        onRowSelected: (event) => {
            handleRowSelection()
        },
        onRowEditingStarted: (event) => {
            console.log('never called - not doing row editing');
        },
        onRowEditingStopped: (event) => {
            console.log('never called - not doing row editing');
        },
        onCellEditingStarted: (event) => {
            console.log('cellEditingStarted');
        },
        onCellEditingStopped: (event) => {
            console.log('cellEditingStopped');
        },
        onNewColumnsLoaded: (event) => {
            console.log('onNewColumnsLoaded');
            console.log(event);
        },
        onColumnVisible: (event) => {
            updateColumnState()
        },
        onColumnResized: (event) => {
            updateColumnState()
        },
        onColumnMoved: (event) => {
            updateColumnState()
        },
        onColumnPinned: (event) => {
            updateColumnState()
        },
        onModelUpdated: (event) => {
            updateSelectedData();
            // const statusBarComponent = gridOptions.api.getStatusPanel('statusBarCount');
            // statusBarComponent.updateTotal(gridOptions.api.rowCount)
        },
        onDisplayedColumnsChanged: (event) => {
            console.log('onDisplayedColumnsChanged ');
            console.log(event);
        },
        onCellValueChanged: onCellValueChanged,
    }

    const rangePresets = [
        {label: 'Last 12 hours', value: [dayjs().add(-12, 'hours'), dayjs()]},
        {label: 'Last 24 hours', value: [dayjs().add(-1, 'd'), dayjs()]},
        {label: 'Last 2 days', value: [dayjs().add(-2, 'd'), dayjs()]},
        {label: 'Last 3 days', value: [dayjs().add(-3, 'd'), dayjs()]},
        {label: 'Last 7 days', value: [dayjs().add(-7, 'd'), dayjs()]},
        {label: 'Last 30 days', value: [dayjs().add(-30, 'd'), dayjs()]},
    ];

    const handleDateRangeChange = (data) => {
        setDateRange(data);
    }

    return (<>
            <Card style={{
                width: '100%',
                height: '100%',
                boxSizing: 'border-box',
                display: 'flex',
                flexDirection: 'column',
                boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                border: '1px solid #e8e8e8'
            }} bodyStyle={{padding: '5px', display: 'flex', flexDirection: "column", flex: "1 1 auto"}}>
                <Row gutter={16} style={{marginBottom: "8px"}}>
                    <Col span={24}>
                        <Card bodyStyle={{padding: "4px"}}>
                            <Row gutter={[0, 0]}>
                                <Col>
                                    <Button onClick={onRefreshData}>Refresh data</Button>
                                </Col>
                                <Col>
                                    <Button onClick={onResetAllFilters}>Reset filters</Button>
                                </Col>
                                <Col>
                                    <Button onClick={onResetColumns}>Reset columns</Button>
                                </Col>
                                <Divider type={'vertical'}></Divider>
                                <RangePicker
                                    showTime={{
                                        use12Hours: false,
                                    }}
                                    presets={rangePresets}
                                    onChange={handleDateRangeChange}
                                    style={{width: 330}}
                                    allowClear={true}
                                    value={dateRange}
                                />
                                <Divider type={'vertical'}></Divider>
                                <Col>
                                    <Radio.Group
                                        options={[
                                            {label: 'Damages', value: 'damages'},
                                            {label: 'Alerts', value: 'alerts'},
                                            {label: 'All', value: 'all'},
                                        ]}
                                        onChange={onChangeDamageFilter}
                                        defaultValue={'damages'}
                                        optionType="button"/>
                                </Col>
                                <Col style={{marginLeft: 'auto'}}>
                                    <Switch
                                        style={{marginTop: '5px'}}
                                        onChange={handleDetailsCardChange}
                                        checkedChildren="details"
                                        unCheckedChildren="details"
                                        checked={detailCardOpen}
                                    />
                                </Col>
                            </Row>
                        </Card>
                    </Col>

                </Row>
                <Row className="ag-theme-alpine" style={{flex: "1 1 auto"}}>
                    <Col style={{flex: "1 1 auto"}}>
                        <AgGridReact
                            ref={gridRef}
                            gridOptions={gridOptions}
                        />
                    </Col>
                    {(selectedId) && (detailCardOpen) &&
                        <Col>
                            <DetailsCard id={selectedId}/>
                        </Col>}
                </Row>
            </Card>
        </>

    );
}

export default DamageTool;
