import React, { useState, useEffect } from 'react';
import { Tag, AutoComplete } from 'antd';

const TagRenderer = (props) => {
    const [columnValues, setColumnValues] = useState(props.columnValues || []);
    const [tags, setTags] = useState(props.value ? props.value.split(',') : []);
    const [newTag, setNewTag] = useState('');
    const [filteredValues, setFilteredValues] = useState(props.columnValues || []);
    const [showAddTag] = useState(props.showAddTag !== false);

    const labelMapper = props.labelMapper || {};

    useEffect(() => {
        setTags(props.value ? props.value.split(',') : []);
    }, [props.value, props.UAID]);

    useEffect(() => {
        setColumnValues(props.columnValues || []);
    }, [props.columnValues]);

    useEffect(() => {
        const sortedValues = columnValues.sort((a, b) => a.value.localeCompare(b.value));
        setFilteredValues(
            sortedValues.filter(
                (columnValue) =>
                    columnValue.value.toLowerCase().startsWith(newTag.toLowerCase()) &&
                    columnValue.value.toLowerCase().indexOf(newTag.toLowerCase()) === 0
            )
        );
    }, [columnValues, newTag]);

    const handleChange = (value) => {
        setNewTag(value);
    };

    const handleSelect = (value) => {
        const updatedTags = tags.concat([value]);
        const updatedValue = updatedTags.join(',');
        setTags(updatedTags);
        if (props.onSaveActive) {
            props.onSave(props.field, updatedValue);
        } else {
            props.setValue(updatedValue);
        }
        setNewTag('');
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            const updatedTags = tags.concat([newTag]);
            const updatedValue = updatedTags.join(',');
            setTags(updatedTags);

            if (props.onSaveActive) {
                props.onSave(props.field, updatedValue);
            } else {
                props.setValue(updatedValue);
            }
            setNewTag('');
        }
    };

    const handleClose = (index) => {
        const updatedTags = [...tags];
        updatedTags.splice(index, 1);
        const updatedValue = updatedTags.join(',');
        setTags(updatedTags);
        if (props.onSaveActive) {
            props.onSave(props.field, updatedValue);
        } else {
            props.setValue(updatedValue);
        }
    };

    return (
        <>
            {tags.length > 0 ? (
                tags.map((tag, index) => (
                    <Tag key={tag} color="blue" closable onClose={() => handleClose(index)} style={{marginBottom: 8}}>
                        {labelMapper[tag] || tag}
                    </Tag>
                ))
            ) : (
                <span></span>
            )}
            {showAddTag && (
                <AutoComplete
                    value={newTag}
                    size="small"
                    style={{ width: props.width || 100, display: 'inline-block' }}
                    placeholder="+Tag"
                    placeholderstyle={{ fontSize: 10 }}
                    options={filteredValues.map(value => ({
                        value: value.value,
                        label: labelMapper[value.value] || value.value
                    }))}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    onSelect={handleSelect}
                />
            )}
        </>
    );
};

export default TagRenderer;
