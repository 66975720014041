import React, {useState, useEffect} from 'react';
import {Descriptions, Table, Card, Form, Input, Popover, Button, Col, notification} from 'antd';
import axiosInstance from "services/axios";
import 'leaflet/dist/leaflet.css';
import ChartToolModal from "../ChartTool/ChartToolModal";
import HistoryModal from "./HistoryModal";
import MapComponent from "../MapComponent/MapComponent";
import Icon, {InfoCircleOutlined, PlusOutlined, ReloadOutlined} from "@ant-design/icons";

const DetailsCard = (props) => {

    const {id = null} = props;
    let showPredTable = false
    const [data, setData] = useState({});
    const [bothChartModalOpen, setBothChartModalOpen] = useState(false);
    const [accelerationChartModalOpen, setAccelerationChartModalOpen] = useState(false);
    const [gyroChartModalOpen, setGyroChartModalOpen] = useState(false);
    const [historyModalOpen, setHistoryModalOpen] = useState(false);
    const [historyOpenCount, setHistoryOpenCount] = useState(0)
    const [anomalySearch, setAnomalySearch] = useState(null);
    const [showMap, setShowMap] = useState(false);
    const [UAID, setUAID] = useState(null);


    const onSearchUAID = () => {
        if (UAID) {
            if (UAID.includes("UAID")) {
                const request = {UAID: UAID}
                setHistoryOpenCount(historyOpenCount + 1)
                console.log("Request data with params: " + JSON.stringify(request, null, 1));
                axiosInstance.post('/api/map_tool/info', request)
                    .then(res => {

                        if (res.data.message.includes("WARNING")) {
                            // notification['warning']({
                            //     message: res.data.message,
                            //     duration: 5,
                            //     maxCount: 15
                            //   }
                            // );
                        } else {
                            setAnomalySearch(res.data.anomaly)
                            setShowMap(true)
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        notification['error']({message: 'Error in get_anomaly', description: error.message});
                    })

            } else {
                notification['error']({
                        message: 'Not a valid UAID',
                        duration: 5
                    }
                );
            }
        }
    }

    useEffect(() => {
        setShowMap(false)
        onSearchUAID()
    }, [UAID]);

    const showBothChartModal = () => {
        setBothChartModalOpen(true);
    }

    const showAccelerationChartModal = () => {
        setAccelerationChartModalOpen(true);
    }

    const showGyroChartModal = () => {
        setGyroChartModalOpen(true);
    }

    const showHistoryModal = () => {
        setHistoryOpenCount(historyOpenCount + 1)
        setHistoryModalOpen(true);
    }

    const columns = [
        {
            title: 'Method',
            dataIndex: 'predict_method',
            key: 'predict_method',
            width: 180
        },
        {
            title: 'Prediction',
            dataIndex: 'prediction',
            key: 'prediction',
        },
        {
            title: 'Score',
            dataIndex: 'prediction_score',
            key: 'prediction_score',
        },
    ]

    useEffect(() => {
        if (id) {
            console.log('selected id', id);
            axiosInstance.get('/api/matching_tool/details/' + id)
                .then(function (response) {
                    if (response.data.data.anomaly_pred) {
                        showPredTable = true
                    } else {
                        showPredTable = false
                    }
                    setData(response.data.data);
                    setUAID(response.data.data.UAID)
                })
                .catch(function (error) {
                    console.log(error);
                    setData({});
                });
        } else {
            setData({});
        }
    }, [id]);

    return (
        <>
            <HistoryModal
                showModal={() => setHistoryModalOpen(true)}
                hideModal={() => setHistoryModalOpen(false)}
                open={historyModalOpen}
                id={UAID}
                count={historyOpenCount}
            />
            <ChartToolModal
                showModal={() => setBothChartModalOpen(true)}
                hideModal={() => setBothChartModalOpen(false)}
                open={bothChartModalOpen}
                id={id}
                type={'b'}
            />
            <ChartToolModal
                showModal={() => setAccelerationChartModalOpen(true)}
                hideModal={() => setAccelerationChartModalOpen(false)}
                open={accelerationChartModalOpen}
                id={id}
                type={'a'}
            />
            <ChartToolModal
                showModal={() => setGyroChartModalOpen(true)}
                hideModal={() => setGyroChartModalOpen(false)}
                open={gyroChartModalOpen}
                id={id}
                type={'g'}
            />
            <Descriptions
                style={{}}
                bordered column={1}
                size={'small'}
                contentStyle={{'minWidth': '300px', 'maxWidth': '400px'}}
            >
                <Descriptions.Item label="UAID" style={{marginBottom: "0px"}}>
                    {data.UAID || ''}
                    <Button
                        style={{marginLeft: 16}}
                        icon={<InfoCircleOutlined/>}
                        href={`https://matching.carvaloo.com/anomaly_tool/${data.UAID}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Anomaly
                    </Button>
                </Descriptions.Item>
                <Descriptions.Item label="Data">
                    <Button onClick={showBothChartModal}> Acceleration+Gyro</Button>
                    <Button onClick={showAccelerationChartModal}> Acceleration</Button>
                    <Button onClick={showGyroChartModal}> Gyro</Button>
                </Descriptions.Item>
                <Descriptions.Item label="Buttons">
                    <Button onClick={showHistoryModal}> History</Button>
                </Descriptions.Item>
            </Descriptions>
            <Table
                size='small'
                columns={columns}
                dataSource={data.anomaly_pred}
                pagination={false}
                scroll={{y: 300}}
                style={{'minWidth': '300px', 'maxWidth': '450px'}}
            />
            <div style={{marginTop: '20px'}}>
                {showMap ? (
                    <MapComponent
                        anomalySearch={anomalySearch}
                        isAnomaliesActive={true}
                        isClustererActive={false}
                        isPOIActive={false}
                        highlightedBox={null}
                        mapHeight={'300px'}
                        noAutocomplete={true}
                    />
                ) : (
                    <div style={{height: '285px', backgroundColor: '#eeeeee', padding: 128}}>
                        No Map Data for Anomaly
                    </div>
                )}
            </div>

        </>
    )
        ;
};

export default DetailsCard;