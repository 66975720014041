import React, { useState, useEffect } from 'react';
import { Tag } from 'antd';

const EventTagRenderer = (props) => {
    const { context, value} = props;
    const eventId = context.eventId
    const [tagsValue, setTagsValue] = useState({});

    useEffect(() => {
        if (value && typeof value === 'object') {
            setTagsValue(value.events || {});
        }
    }, [value]);

    const renderTags = () => {
        return Object.keys(tagsValue).map(key => {
            const value = tagsValue[key];
            let color = '';
            let opacity = 0.8;

            // Define color based on value
            if (value === eventId) {
                color = '#90ee90';
            }

            // Adjust opacity for all colors
            const tagStyle = {
                marginTop: 0,
                marginBottom: 0,
                backgroundColor: color,
                opacity: opacity,
            };

            // Render tag only if value is not '0'
            return (
                <Tag key={key} style={tagStyle}>
                    {`${value}`}
                </Tag>
            );
        });
    };

    return (
        <div style={{ whiteSpace: 'normal' }}>
            {renderTags()}
        </div>
    );
};

export default EventTagRenderer;