import React from "react";

export const convertSeverityToColor = (severity, isLogger = false) => {
  severity = severity === null ? "NaN" : severity;

  if(typeof severity == "string") {
    if(severity.startsWith("#")) {
      return severity;
    }
  }
  if (severity <= 1) return "#0F968C";
  if (severity === 2) return "#FFB400";
  if (severity >= 3) return "#D42E53";
  return "#D42E53";
};

export const carPartColorGetter = (currentParts, carPart) => {
  const { severity } =
    currentParts.find((item) => item["carPart"] === carPart) || undefined;
  return convertSeverityToColor(severity, true);
};

export const IconSeverity = ({ ...props }) => {
  return (
      <svg
        viewBox="0 0 65.333 64"
        x={props.positionX}
        y={props.positionY}
        height={props.height}
        width={props.width}
      >
        <path
          d="M0 32C0 14.327 14.625 0 32.667 0c18.041 0 32.666 14.327 32.666 32 0 17.673-14.625 32-32.666 32C14.625 64 0 49.673 0 32z"
          fill={props.outSideColor}
          fillOpacity={0.149}
        />
        <path
          d="M21.333 31.333C21.333 25.075 26.408 20 32.667 20 38.925 20 44 25.075 44 31.333c0 6.259-5.075 11.334-11.333 11.334-6.259 0-11.334-5.075-11.334-11.334z"
          fill={props.inSideColor}
        />
      </svg>
  );
};

export const RectSeverityArea = ({ ...props }) => {

  return (
    <>
      <svg>
        <rect
          strokeWidth="8"
          x={props.positionX}
          y={props.positionY}
          width="270"
          height="180"
          stroke="#d42e53"
          fill="#d42e5311"
        />
      </svg>
    </>
  );
};
