import React, {useState} from 'react';
import {Modal} from 'antd';
import ChartTool from "./ChartTool";

const ChartToolModal = (props) => {

  const handleCancel = () => {
    props.hideModal && props.hideModal();
  };

  return (
    <>
      <Modal title="Anomaly Data"
             open={props.open}
             onCancel={handleCancel}
             width={"60vw"}
             height={"90vh"}
             footer={null}
      >
        <ChartTool id={props?.id} type={props.type} uaid={props?.uaid}/>
      </Modal>
    </>
  );
};

export default ChartToolModal;