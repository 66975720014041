import React, {useState, useRef, useCallback, useEffect} from 'react';

import {
    Button, Card, InputNumber, Select, Col, Modal, notification, Row, Typography, Input, Spin, Switch, Table
} from 'antd';

import Icon from "@ant-design/icons";
import {FaInfoCircle} from 'react-icons/fa';
import axiosInstance from "services/axios";

const {Search} = Input;
const {Title} = Typography;


const AnomalyInfoCard = (props) => {

  const [data, setData] = useState(null);

  const UAID = props.UAID

  const columns = [
    {
      title: 'Method',
      dataIndex: 'predict_method',
      key: 'predict_method',
      width: 200
    },
    {
      title: 'Prediction',
      dataIndex: 'prediction',
      key: 'prediction',
      width: 200
    },
    {
      title: 'Score',
      dataIndex: 'prediction_score',
      key: 'prediction_score',
    },
  ]

  const getDashboardValues = () => {
    axiosInstance.post(
      '/api/anomaly_tool/pred',
      {
        UAID: UAID,
      })
      .then(res => {
          console.log(res.data)
        setData(res.data)
      })
      .catch(error => {
        console.log(error);
        setData(null)
        notification['error']({
          message: 'Error in get AnomalyPred',
          description: (
            <>
              {error.message + ':'}<br/>
              {error.response && error.response.data && error.response.data.message}
            </>
          ),
        });
      })
  }

  useEffect(() => {
    if (UAID !== null) {
        getDashboardValues();
    } else {
        setData(null)
    }
  }, [UAID, props.reload]);

  return (<>
      {/*<Modal*/}
      {/*  open={loading}*/}
      {/*  closable={false}*/}
      {/*  footer={null}*/}
      {/*  centered={true}*/}
      {/*  mask={true}*/}
      {/*  maskClosable={false}*/}
      {/*  bodyStyle={{ backdropFilter: 'blur(3px)' }}*/}
      {/*>*/}
      {/*  <Spin size="large" />*/}
      {/*</Modal>*/}
      <Card
        style={{
          width: '100%',
          height: '100%',
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'column',
          boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
          border: '1px solid #e8e8e8'}}
        title={'Predictions'}
        bodyStyle={{padding: '5px', display: 'flex', flexDirection: "column", flex: "1 1 auto"}}>
          <Table
            size='small'
            columns={columns}
            dataSource={data?.anomaly_pred}
            pagination={false}
            scroll={{ y: 320 }}
            style={{'maxHeight': '400px'}}
          />
      </Card>
    </>
  );
}

export default AnomalyInfoCard;
