export function customLogicFilter(filterModel, keys) {

  for (const key of keys) {
    if (!(key in filterModel)) {
      continue;
    }

    if (filterModel[key].type === 'contains') {
      let filter_string = filterModel[key].filter;
      filter_string = filter_string.replace(/\s+/g, '');
      if (filter_string.includes(',')) {
        const conditions = filter_string.split(',');

        filterModel[key] = {
          filterType: 'text',
          operator: 'OR',
        };

        for (let i = 0; i < conditions.length; i++) {
          filterModel[key][`condition${i + 1}`] = {
            filterType: 'text',
            type: 'contains',
            filter: conditions[i],
          };
        }
      }

      if (filter_string.includes('+')) {
        const conditions = filter_string.split('+');

        filterModel[key] = {
          filterType: 'text',
          operator: 'AND',
        };

        for (let i = 0; i < conditions.length; i++) {
          filterModel[key][`condition${i + 1}`] = {
            filterType: 'text',
            type: 'contains',
            filter: conditions[i],
          };
        }
      }
    }
  }

  return filterModel;

}
