import React, {useState, useRef, useCallback, useEffect} from 'react';

import {
    Button, Card, InputNumber, Select, Col, Modal, notification, Row, Typography, Input, Popover, Switch
} from 'antd';

import Icon from "@ant-design/icons";
import {FaGlobe} from 'react-icons/fa';
import MapComponent from "../MapComponent/MapComponent";
import axiosInstance from "services/axios";
import FilterForm from "./FilterForm";

const {Search} = Input;
const {Text} = Typography;


const MapTool = (props) => {

    const [UAID, setUAID] = useState(null);
    const [filterOpen, setFilterOpen] = useState(false);
    const [highlightOpen, setHighlightOpen] = useState(false);
    const [filterActive, setFilterActive] = useState(false);
    const [highlightActive, setHighlightActive] = useState(false);

    const [anomalySearch, setAnomalySearch] = useState(null);
    const [isClustererActive, setClustererActive] = useState(false);
    const [isPOIActive, setPOIActive] = useState(false);
    const [isAnomaliesActive, setAnomaliesActive] = useState(true);
    const [isGeosimilarityActive, setGeosimilarityActive] = useState(false);
    const [highlightValues, setHighlightValues] = useState([]);
    const [filterValues, setFilterValues] = useState([]);

    useEffect(() => {
        let url = new URL(window.location.href);
        const search = url.searchParams.get('search');
        if (search) {
            onSearchUAID(search)
        }
    }, []);

    const highlightOptions = [
        {
            label: 'Box',
            value: 'box',
            example: 'example: 10203032'
        },
        {
            label: 'Customer',
            value: 'customer',
            example: 'example: mobility'
        },
        {
            label: 'Vehicle Category',
            value: 'vehicle_category',
            example: 'example: car'
        },
        {
            label: 'Vehicle Class',
            value: 'vehicle_class',
            example: 'example: small'
        },
        {
            label: 'Vehicle Type',
            value: 'vehicle_type',
        },
        {
            label: 'Matching',
            value: 'matching',
        },
        {
            label: 'Proactive',
            value: 'proactive',
        },
        {
            label: 'Alert',
            value: 'alert',
        },
    ]

    const highlightSelectOptions = [
        {
            label: 'equals',
            value: 'equals',
            tooltipContent: 'Equals - include exact matches',
        },
        {
            label: 'not equals',
            value: 'not_equals',
            tooltipContent: 'Not Equals - exclude exact matches',
        }
    ];

    const filterOptions = [
        {
            label: 'UAID',
            value: '_id',
        },
        {
            label: 'Datetime',
            value: 'event_ts',
        },
        {
            label: 'Box',
            value: 'box',
        },
        {
            label: 'Customer',
            value: 'customer',
        },
        {
            label: 'Velocity',
            value: 'velocity',
        },
        {
            label: 'Alert',
            value: 'alert',
        },
        {
            label: 'Reactive Alert',
            value: 'reactive',
        },
        {
            label: 'Matching Confidence',
            value: 'matching_confidence',
        },
        {
            label: 'Vehicle Category',
            value: 'vehicle_category',
        },
        {
            label: 'Vehicle Class',
            value: 'vehicle_class',
        },
        {
            label: 'Vehicle Type',
            value: 'vehicle_type',
        },
        {
            label: 'Event Direction',
            value: 'direction',
        },
        {
            label: 'Matching',
            value: 'matching',
        },
        {
            label: 'Proactive',
            value: 'proactive',
        },
        {
            label: 'Exclude',
            value: 'exclude',
        },
        {
            label: 'Pattern',
            value: 'pattern',
        },
        {
            label: 'Wavenet Score',
            value: 'wavenet_score',
        },
        {
            label: 'Wavenet Predict',
            value: 'wavenet_predict',
        },
        {
            label: 'Wavenet Version',
            value: 'wavenet_version',
        },
        {
            label: 'Anomaly Count',
            value: 'anomaly_count',
        },
        {
            label: 'Cluster Count',
            value: 'cluster_count',
        },
        {
            label: 'Cluster Percentage',
            value: 'cluster_percentage',
        },
    ]

    const filterSelectOptions = [
        {
            label: 'equals',
            value: 'equals',
            tooltipContent: 'Equals - include exact matches',
        },
        {
            label: 'not equals',
            value: 'not_equals',
            tooltipContent: 'Not Equals - exclude exact matches',
        },
        {
            label: 'smaller',
            value: 'smaller',
            tooltipContent: 'Smaller Than - set an upper limit',
        },
        {
            label: 'greater',
            value: 'greater',
            tooltipContent: 'Greater Than - set a lower limit',
        },
        {
            label: 'contains',
            value: 'contains',
            tooltipContent: 'Contains - include partial matches',
        },
        {
            label: 'not contains',
            value: 'not_contains',
            tooltipContent: 'Not Contains - exclude partial matches',
        },
    ];

    useEffect(() => {
        document.title = 'Map Tool';
    }, []);

    const handleToggleAnomalies = () => {
        setAnomaliesActive(!isAnomaliesActive);
    };

    const handleToggleGeosimilarity = () => {
        setGeosimilarityActive(!isGeosimilarityActive);
    };

    const handleToggleClusterer = () => {
        setClustererActive(!isClustererActive);
    };

    const handleTogglePOI = () => {
        setPOIActive(!isPOIActive);
    };

    const handleFilterChange = (values) => {
        setFilterOpen(!filterOpen);
        console.log('Received values of form:', values)

        if (values.filters.length > 0) {

            for (const filter of values.filters) {
                if (filter.field === "box") {
                    const oldValue = filter.value
                    filter.value = oldValue.replace(/^0+/, '');
                }
            }

            setFilterActive(true)
            setFilterValues(values.filters)
        } else {
            setFilterActive(false)
            setFilterValues([]);
        }
    };

    const handleFilterOpen = () => {
        setFilterOpen(!filterOpen);
    };

    const handleHighlightChange = (values) => {
        setHighlightOpen(!highlightOpen);
        console.log('Received values of form:', values)

        if (values.filters.length > 0) {

            for (const filter of values.filters) {
                if (filter.field === "box") {
                    const oldValue = filter.value
                    filter.value = oldValue.replace(/^0+/, '');
                }
            }

            setHighlightActive(true)
            setHighlightValues(values.filters)
        } else {
            setHighlightActive(false)
            setHighlightValues([]);
        }
    };

    const handleHighlightOpen = () => {
        setHighlightOpen(!highlightOpen);
    };

    const onSearchUAID = (value) => {
        if (value) {
            if (value.includes("UAID")) {
                setUAID(value)
                const request = {UAID: value}

                console.log("Request data with params: " + JSON.stringify(request, null, 1));
                axiosInstance.post('/api/map_tool/info', request)
                    .then(res => {
                        if (res.data.message.includes("WARNING")) {
                            notification['warning']({
                                    message: res.data.message,
                                    duration: 5,
                                    maxCount: 15
                                }
                            );
                        } else {
                            let url = new URL(window.location.href);
                            url.searchParams.set('search', value);
                            window.history.pushState({}, '', url.href);
                            setAnomalySearch(res.data.anomaly)
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        notification['error']({message: 'Error in get_anomaly', description: error.message});
                    })

            } else {
                notification['error']({
                        message: 'Not a valid UAID',
                        duration: 5
                    }
                );
            }
        }
    }

    return (<>
            <Card
                style={{
                    width: '100%',
                    height: '100%',
                    boxSizing: 'border-box',
                    display: 'flex',
                    flexDirection: 'column',
                    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                    border: '1px solid #e8e8e8'
                }}
                bodyStyle={{padding: '5px', display: 'flex', flexDirection: "column", flex: "1 1 auto"}}>
                <Row gutter={16} style={{marginBottom: "8px", marginLeft: '8px'}} type="flex" justify="space-between">
                    <Col>
                        <Row gutter={[16, 16]}>
                            <Search
                                allowClear={true}
                                placeholder="search for UAID"
                                onSearch={onSearchUAID}
                                size={'large'}
                                style={{
                                    width: 300,
                                }}
                            />
                            <Popover
                                trigger="click"
                                placement="bottom"
                                content={
                                    <FilterForm
                                        onFinish={handleHighlightChange} fieldOptions={highlightOptions}
                                        conditionOptions={highlightSelectOptions}
                                    >
                                    </FilterForm>}
                                open={highlightOpen}
                            >
                                <Button
                                    size={'large'}
                                    onClick={handleHighlightOpen}
                                    style={{backgroundColor: highlightActive ? "lightcoral" : "white"}}
                                >
                                    Highlight
                                </Button>
                            </Popover>
                            <Popover
                                trigger="click"
                                placement="bottom"
                                content={
                                    <FilterForm
                                        onFinish={handleFilterChange} fieldOptions={filterOptions}
                                        conditionOptions={filterSelectOptions}
                                    >
                                    </FilterForm>}
                                open={filterOpen}
                            >
                                <Button
                                    size={'large'}
                                    onClick={handleFilterOpen}
                                    style={{backgroundColor: filterActive ? "lightcoral" : "white"}}
                                >
                                    Filter
                                </Button>
                            </Popover>
                        </Row>
                    </Col>
                    <Col>
                        <Row type="flex" justify="end">
                            <Col style={{marginTop: '10px'}}>
                                <Switch
                                    size={'large'}
                                    onChange={handleToggleAnomalies}
                                    checkedChildren="Anomalies"
                                    unCheckedChildren="Anomalies"
                                    checked={isAnomaliesActive}
                                />
                            </Col>
                            <Col style={{marginLeft: '10px', marginTop: '10px'}}>
                                <Switch
                                    size={'large'}
                                    onChange={handleToggleClusterer}
                                    checkedChildren="Cluster"
                                    unCheckedChildren="Cluster"
                                />
                            </Col>
                            <Col style={{marginLeft: '10px', marginTop: '10px'}}>
                                <Switch
                                    size={'large'}
                                    onChange={handleToggleGeosimilarity}
                                    checkedChildren="Geosimilarity"
                                    unCheckedChildren="Geosimilarity"
                                />
                            </Col>
                            <Col style={{marginLeft: '10px', marginTop: '10px'}}>
                                <Switch
                                    size={'large'}
                                    onChange={handleTogglePOI}
                                    checkedChildren="POIs"
                                    unCheckedChildren="POIs"
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row gutter={16} style={{marginBottom: "8px", marginLeft: '8px'}}>
                    <MapComponent
                        anomalySearch={anomalySearch}
                        isClustererActive={isClustererActive}
                        isPOIActive={isPOIActive}
                        isAnomaliesActive={isAnomaliesActive}
                        isGeosimilarityActive={isGeosimilarityActive}
                        highlightValues={highlightValues}
                        filterValues={filterValues}
                    />
                </Row>
            </Card>
        </>
    );
}

export default MapTool;
