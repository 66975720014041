import React, {useState, useEffect} from 'react';
import {
    Descriptions,
    Table,
    Card,
    Form,
    Input,
    Popover,
    Button,
    Col,
    notification,
    Modal,
    Checkbox,
    Radio,
    Row, Divider, Tooltip, Skeleton
} from 'antd';
import axiosInstance from "services/axios";
import 'leaflet/dist/leaflet.css';
import ChartToolModal from "../ChartTool/ChartToolModal";
import HistoryModal from "./HistoryModal";
import MapComponent from "../MapComponent/MapComponent";
import Icon, {
    CheckOutlined,
    CloseOutlined,
    InfoCircleOutlined,
    StarOutlined,
    PlusOutlined,
    ReloadOutlined, StarFilled, ColumnHeightOutlined, VerticalAlignMiddleOutlined
} from "@ant-design/icons";
import ChartTool from "../ChartTool/ChartTool";
import CommentTags from './CommentTags';
import MatchingModal from "../AnomalyTool/MatchingModal";
import {FaBullseye} from "react-icons/fa"; // Adjust the path accordingly

const MatchingCard = (props) => {

    const {id = null} = props;
    const [data, setData] = useState({});
    const [historyData, setHistoryData] = useState(null);
    const [bothChartModalOpen, setBothChartModalOpen] = useState(false);
    const [accelerationChartModalOpen, setAccelerationChartModalOpen] = useState(false);
    const [gyroChartModalOpen, setGyroChartModalOpen] = useState(false);
    const [predTableModalOpen, setPredTableModalOpen] = useState(false);
    const [historyModalOpen, setHistoryModalOpen] = useState(false);
    const [historyOpenCount, setHistoryOpenCount] = useState(0)
    const [matchingModalOpen, setMatchingModalOpen] = useState(false);
    const [matchingOpenCount, setMatchingOpenCount] = useState(0)
    const [anomalySearch, setAnomalySearch] = useState(null);
    const [showMap, setShowMap] = useState(false);
    const [UAID, setUAID] = useState(null);
    const [showAllTags, setShowAllTags] = useState(false);
    const [loading, setLoading] = useState(false);

    const [updateCounter, setUpdateCounter] = useState(0);
    const [analysisTags, setAnalysisTags] = useState(null);
    const [reviewTags, setReviewTags] = useState(null);
    const [matchingMode, setMatchingMode] = useState('analysis');
    const [maxHeight, setMaxHeight] = useState('400px'); // Initial height, can be adjusted
    const [anomalyFavorite, setAnomalyFavorite] = useState(false);
    const [radioValues, setRadioValues] = useState(null);
    const [checkValues, setCheckValues] = useState(null);

    useEffect(() => {
        // Function to update maxHeight based on screen height
        setMatchingMode(props.matchingMode)
    }, [props.matchingMode]);

    useEffect(() => {
        // Function to update maxHeight based on screen height
        const updateMaxHeight = () => {
            const screenHeight = window.innerHeight;
            const newMaxHeight = screenHeight - 280; // Adjust 200 as needed for margins and padding
            setMaxHeight(`${newMaxHeight}px`);
        };

        // Initial update
        updateMaxHeight();

        // Event listener for window resize
        window.addEventListener('resize', updateMaxHeight);

        // Clean-up on component unmount
        return () => window.removeEventListener('resize', updateMaxHeight);
    }, []);

    useEffect(() => {
        if (id) {
            setLoading(true)
            axiosInstance.get('/api/matching_tool/details/' + id)
                .then(function (response) {
                    setData(response.data.data);
                    setUAID(response.data.data.UAID)
                    setLoading(false)
                })
                .catch(function (error) {
                    console.log(error);
                    setLoading(false)
                    setData({});
                });
        } else {
            setData({});
        }
    }, [id]);

    useEffect(() => {
        if (UAID) {
            const payload = {
                'id': UAID
            }
            axiosInstance.post('/api/matching_tool/event_logs', payload)
                .then(function (response) {
                    setHistoryData(response.data.data);
                })
                .catch(function (error) {
                    console.log(error);
                    setHistoryData(undefined);
                });
        }
    }, [historyOpenCount, UAID]);

    useEffect(() => {
        if (UAID && props.targetEvent) {

            setHistoryOpenCount(historyOpenCount + 1)

            const payload = {
                uaid: UAID,
                event_id: props.targetEvent,
            }

            axiosInstance.post('/api/matching_tool/favorites', payload)
                .then(function (response) {
                    if (Object.keys(response.data.favorites).length > 0) {
                        setAnomalyFavorite(response.data.favorites.matching_favorite)
                    }
                    props.onUpdateFavorite(response.data.favorites)
                })
                .catch(function (error) {
                    console.log(error);
                    setAnomalyFavorite(null);
                });

            axiosInstance.post('/api/matching_tool/analysis_tags', payload)
                .then(function (response) {
                    setAnalysisTags(response.data.tag_dict)
                    props.onUpdateAnalysis(response.data.tag_dict)
                })
                .catch(function (error) {
                    console.log(error);
                    setAnalysisTags(null);
                });

            axiosInstance.post('/api/matching_tool/review_tags', payload)
                .then(function (response) {
                    setReviewTags(response.data.tag_dict)
                    props.onUpdateReview(response.data.tag_dict)
                })
                .catch(function (error) {
                    console.log(error);
                    setReviewTags(null);
                });

        }
    }, [UAID, props.targetEvent, updateCounter]);

    useEffect(() => {
        if (descriptionItems && analysisTags) {
            const initialValues = descriptionItems.reduce((acc, item) => {
                if (typeof item === 'object' && item.label) {
                    acc[item.label] = analysisTags[item.label] !== undefined ? analysisTags[item.label] : null;
                }
                return acc;
            }, {});
            setRadioValues(initialValues);
        }
    }, [analysisTags]);

    useEffect(() => {
        if (descriptionItems && reviewTags) {
            const initialValues = descriptionItems.reduce((acc, item) => {
                if (typeof item === 'object' && item.label) {
                    acc[item.label] = reviewTags[item.label] !== undefined ? reviewTags[item.label] : null;
                }
                return acc;
            }, {});
            setCheckValues(initialValues);
        }
    }, [reviewTags]);


    const showBothChartModal = () => {
        setBothChartModalOpen(true);
    }

    const showAccelerationChartModal = () => {
        setAccelerationChartModalOpen(true);
    }

    const showGyroChartModal = () => {
        setGyroChartModalOpen(true);
    }

    const showHistoryModal = () => {
        setHistoryOpenCount(historyOpenCount + 1)
        setHistoryModalOpen(true);
    }

    const showPredModal = () => {
        setPredTableModalOpen(true);
    }

    const columns = [
        {
            title: 'Method',
            dataIndex: 'predict_method',
            key: 'predict_method',
            width: 180
        },
        {
            title: 'Prediction',
            dataIndex: 'prediction',
            key: 'prediction',
        },
        {
            title: 'Score',
            dataIndex: 'prediction_score',
            key: 'prediction_score',
        },
    ]

    const handleCancel = () => {
        setPredTableModalOpen(false)
    };

    const modeOptions = [
        {
            label: 'Analysis',
            value: 'analysis',
        },
        {
            label: 'Review',
            value: 'review',
        }
    ]

    const onChangeMode = ({target: {value}}) => {
        setMatchingMode(value);
    };

    const showMatchingModal = () => {
        setMatchingOpenCount(matchingOpenCount + 1)
        setMatchingModalOpen(true);
    }

    const handleButtonClick = (label, value) => {
        if (matchingMode === 'analysis') {
            setRadioValues(prevValues => ({...prevValues, [label]: value}));
        }
        onChangeTags(label)({target: {value}});
    };

    const handleShowButtonClick = () => {
        setShowAllTags(prev => !prev);
    };

    const handleStarClick = () => {

        const payload = {
            uaid: UAID,
            event_id: props.targetEvent,
            matching_favorite: !anomalyFavorite,
        }

        setAnomalyFavorite(prev => !prev);

        axiosInstance.post('/api/matching_tool/favorites/update', payload)
            .then(function (response) {
                setUpdateCounter(prevCounter => prevCounter + 1);
            })
            .catch(function (error) {
                console.log(error);
                notification['error']({
                    message: 'Error setting favorite',
                    description: (
                        <>
                            {error.message + ':'}<br/>
                            {error.response && error.response.data && error.response.data.message}
                        </>
                    ),
                });
            });
    };

    const onChangeTags = (label) => (e) => {
        const value = e.target.value;
        // console.log(`Label: ${label}, Value: ${value} clicked`);

        const payload = {
            uaid: UAID,
            event_id: props.targetEvent,
            label: label,
            value: value,
            type: matchingMode,
        }

        axiosInstance.post('/api/matching_tool/tags/update', payload)
            .then(function (response) {
                console.log(`Event: ${props.targetEvent}, Label: ${label}, Value: ${value} saved`);
                setUpdateCounter(prevCounter => prevCounter + 1);
            })
            .catch(function (error) {
                console.log(error);
                notification['error']({
                    message: 'Error setting Tags',
                    description: (
                        <>
                            {error.message + ':'}<br/>
                            {error.response && error.response.data && error.response.data.message}
                        </>
                    ),
                });
            });
    };

    const criteriaOptions = [
        {
            label: '--',
            value: '-2',
        },
        {
            label: '-',
            value: '-1',
        },
        {
            label: 'o',
            value: '0',
        },
        {
            label: '+',
            value: '1',
        },
        {
            label: '++',
            value: '2',
        },
    ]

    const descriptionItems = [
        {label: 'impact_intensity', default: true, tooltip: 'test'},
        {
            label: 'impact_duration',
            disable: ['++', '--'],
            default: true,
            tooltip: 'whether impact duration (in relation to velocity) fits to the damage'
        },
        {
            label: 'impact_direction',
            default: true,
            tooltip: 'whether impact direction fits to the damage; prediction of event direction model is typically used, but can be assessed manually as well'
        },
        {
            label: 'repeated_pattern',
            disable: ['++', '+'],
            default: true,
            tooltip: 'whether anomaly pattern is observed several times within limited time'
        },
        {
            label: 'gx_relative_gy',
            default: true,
            tooltip: 'relative amplitude of gx and gy, can be positive if gx >> gy (side damage) or negative if gx ~= gy (uneven road)'
        },
        {
            label: 'initial_excitation',
            default: true,
            tooltip: 'relative order of initial excitation in different axes; for example: road bumps and uneven roads are typically characterized by initial smooth impulse in gx and gy and/or az, damages tend to start with significant impulse in ax/ay'
        },
        {label: 'smoothness_ax', tooltip: 'pattern smoothness (negative) or sharpness (positive) in given axis'},
        {label: 'smoothness_ay', tooltip: 'pattern smoothness (negative) or sharpness (positive) in given axis'},
        {label: 'smoothness_az', tooltip: 'pattern smoothness (negative) or sharpness (positive) in given axis'},
        {label: 'smoothness_gx', tooltip: 'pattern smoothness (negative) or sharpness (positive) in given axis'},
        {label: 'smoothness_gy', tooltip: 'pattern smoothness (negative) or sharpness (positive) in given axis'},
        {
            label: 'smoothness_gz',
            default: true,
            tooltip: 'pattern smoothness (negative) or sharpness (positive) in given axis'
        },
        {label: 'prominent_ax', tooltip: 'prominent pattern in given axis'},
        {label: 'prominent_ay', default: true, tooltip: 'prominent pattern in given axis'},
        {label: 'prominent_az', tooltip: 'prominent pattern in given axis'},
        {label: 'prominent_gx', tooltip: 'prominent pattern in given axis'},
        {label: 'prominent_gy', tooltip: 'prominent pattern in given axis'},
        {label: 'prominent_gz', tooltip: 'prominent pattern in given axis'},
        {
            label: 'similarity',
            tooltip: 'whether highly similar anomalies exist, can be positive or negative, depending on labels of neighbor anomalies'
        },
        {label: 'road_bump', disable: ['++'], default: true},
        {
            label: 'pattern_filter',
            tooltip: 'whether anomaly corresponds to known type of pattern (filter); typically negative argument, but can be positive in certain cases (e.g. road_bump for underbody/rim/tire damages)'
        },
        'divider',
        {
            label: 'trajectory',
            default: true,
            tooltip: 'plausibility of trajectory relative to damage (geometrical argument)'
        },
        {label: 'curve_course', default: true, tooltip: 'whether curve interruption/continuity fits to the damage'},
        {
            label: 'driving_situation',
            disable: ['++'],
            default: true,
            tooltip: 'whether driving situation (drive, parking, standstill) fits to the damage'
        },
        {
            label: 'door_after_impact',
            disable: ['++', '--'],
            default: true,
            tooltip: 'presence/absence of door activity shortly after impact'
        },
        {
            label: 'reaction_after_impact',
            default: true,
            tooltip: 'driver behavior after impact (including stop), can be negative if immediate stop is expected at impact'
        },
        {label: 'third_party', tooltip: 'whether anomaly could be third-party damage (external force)'},
        'divider',
        {label: 'matching_score', default: true, tooltip: 'automated matching score for the UAID and event pair'},
        {
            label: 'last_inspection',
            disable: ['++', '+'],
            default: true,
            tooltip: 'whether intermediate inspections were conducted between anomaly and damage observation'
        },
        {
            label: 'time_frame',
            default: true,
            disable: ['++', '--'],
            tooltip: 'whether anomaly is in relevant time frame'
        },
        {label: 'time_to_observation', default: true, tooltip: 'time gap between anomaly and first damage observation'},
        {label: 'time_of_day', disable: ['++', '--'], tooltip: 'suspicious time of anomaly occurrence (e.g. at night)'},
        {
            label: 'location',
            default: true,
            tooltip: 'whether anomaly location is plausible with respect to the damage (possible obstacle visible in StreetView, source of FPs like uneven road, etc.)'
        },
        {
            label: 'geo_similarity',
            disable: ['++', '+'],
            tooltip: 'whether location has many similar FPs and/or geo-similarity cluster'
        },
        {label: 'weather'},
        'divider',
        {label: 'known_location', tooltip: 'whether anomaly location fits to the known event location'},
        {label: 'known_situation', default: true, tooltip: 'whether anomaly fits to the known event situation'},
        {label: 'known_time', tooltip: 'whether anomaly fits to known event time'},
    ];

    const getMostRecentOperation = (history, label, value) => {

        if (!history) {
            return null
        }

        let operationName = 'update_analysis_tags'
        if (matchingMode === 'review') {
            operationName = 'update_review_tags'
        }

        let relevantOperations = history
            .filter(op => op.operation_type === operationName && op.content.includes(label) && op.content.includes(` - ${value}`))
            .sort((a, b) => new Date(b.created_datetime) - new Date(a.created_datetime));

        if (relevantOperations.length === 0 && matchingMode === 'review') {
            relevantOperations = history
                .filter(op => op.operation_type === 'update_analysis_tags' && op.content.includes(label) && op.content.includes(` - ${value}`))
                .sort((a, b) => new Date(b.created_datetime) - new Date(a.created_datetime));
        }

        return relevantOperations.length ? relevantOperations[0] : null;
    };

    const getTooltipContent = (operation) => {
        if (operation) {
            const {operation_user, created_datetime} = operation;
            const formattedDatetime = new Date(created_datetime).toLocaleString('de-DE', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                hour12: false
            }).replace(',', '');
            return `Updated by ${operation_user} - ${formattedDatetime}`;
        }
        return null;
    };

    const renderButtonWithTooltip = (item, option) => {
        const mostRecentOperation = getMostRecentOperation(historyData, item.label, option.value);
        const tooltipContent = getTooltipContent(mostRecentOperation);

        const reviewTagsLoaded = radioValues && reviewTags && matchingMode === 'review'
        const existAnalysisTag = radioValues && radioValues[item.label] === option.value
        const existReviewTag = radioValues && reviewTags && reviewTags[item.label] && reviewTags[item.label] === option.value && matchingMode === 'review'
        const correctAnalysisTag = reviewTagsLoaded && radioValues[item.label] === option.value && radioValues[item.label] === reviewTags[item.label]
        const incorrectAnalysisTag = (reviewTagsLoaded && radioValues[item.label] === option.value && radioValues[item.label] !== reviewTags[item.label]) || (reviewTagsLoaded && reviewTags[item.label] === option.value && radioValues[item.label] === null)

        const button = (
            <Button
                key={option.value}
                type={existAnalysisTag ? 'primary' : 'default'}
                onClick={() => handleButtonClick(item.label, option.value)}
                size='small'
                disabled={item.disable?.includes(option.label)}
                style={{
                    width: 32,
                    opacity: incorrectAnalysisTag ? 0.5 : 1,
                    backgroundColor: existReviewTag ? correctAnalysisTag ? '#383838' : '#9f9f9f' : null,
                }}
            >
                {existReviewTag ? correctAnalysisTag ? <CheckOutlined/> : <CloseOutlined/> : option.label}
            </Button>
        );

        return tooltipContent && (existReviewTag || existAnalysisTag || incorrectAnalysisTag) ? (
            <Tooltip title={tooltipContent} key={option.value} mouseEnterDelay={0.5}>
                {button}
            </Tooltip>
        ) : button;
    };

    const renderButtons = (item) => {
        return criteriaOptions.map(option => renderButtonWithTooltip(item, option));
    };

    const DescriptionsComponent = ({descriptionItems}) => (
        <Descriptions bordered column={1} size={'small'} style={{width: 380}}>
            {descriptionItems.map((item, index) =>
                item === 'divider' ? (
                    <Divider key={index} style={{marginBottom: 4}}/>
                ) : item.default || showAllTags || (radioValues && radioValues[item.label]) ? (
                    <Descriptions.Item
                        key={item.label}
                        label={
                            <Tooltip mouseEnterDelay={1} title={item.tooltip ? item.tooltip : 'None'}>
                                {item.label}
                            </Tooltip>
                        }
                        style={{height: '10px', padding: 4, paddingLeft: 16}}
                    >
                        <div>
                            {renderButtons(item)}
                        </div>
                    </Descriptions.Item>
                ) : null
            )}
        </Descriptions>
    );

    return (
        <>
            <HistoryModal
                showModal={() => setHistoryModalOpen(true)}
                hideModal={() => setHistoryModalOpen(false)}
                open={historyModalOpen}
                data={historyData}
                count={historyOpenCount}
            />
            <ChartToolModal
                showModal={() => setBothChartModalOpen(true)}
                hideModal={() => setBothChartModalOpen(false)}
                open={bothChartModalOpen}
                id={id}
                type={'b'}
            />
            <ChartToolModal
                showModal={() => setAccelerationChartModalOpen(true)}
                hideModal={() => setAccelerationChartModalOpen(false)}
                open={accelerationChartModalOpen}
                id={id}
                type={'a'}
            />
            <ChartToolModal
                showModal={() => setGyroChartModalOpen(true)}
                hideModal={() => setGyroChartModalOpen(false)}
                open={gyroChartModalOpen}
                id={id}
                type={'g'}
            />
            <MatchingModal
                showModal={() => setMatchingModalOpen(true)}
                hideModal={() => setMatchingModalOpen(false)}
                open={matchingModalOpen}
                id={UAID}
                data={data}
                count={matchingOpenCount}
                targetEvent={props.targetEvent}
                eventStatus={props.eventData?.assignment_status}
            />
            <Modal title="Anomaly Pred Data"
                   open={predTableModalOpen}
                   onCancel={handleCancel}
                   width={"60vw"}
                   height={"90vh"}
                   footer={null}
            >
                <Table
                    size='small'
                    columns={columns}
                    dataSource={data.anomaly_pred}
                    pagination={false}
                    scroll={{y: 600}}
                    // style={{'minWidth': '300px', 'maxWidth': '450px'}}
                />
            </Modal>

            <Descriptions
                style={{}}
                bordered column={1}
                size={'small'}
                contentStyle={{'minWidth': '300px', 'maxWidth': '400px'}}
            >
                <Descriptions.Item label="UAID" style={{marginBottom: "0px"}}>
                    {data.UAID || ''}
                    <Button
                        style={{marginLeft: 16}}
                        icon={<InfoCircleOutlined/>}
                        href={`https://matching.carvaloo.com/anomaly_tool/${data.UAID}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Anomaly
                    </Button>
                </Descriptions.Item>
                <Descriptions.Item label="Data">
                    <Button onClick={showBothChartModal}> Acceleration+Gyro</Button>
                    <Button onClick={showAccelerationChartModal}> Acceleration</Button>
                    <Button onClick={showGyroChartModal}> Gyro</Button>
                </Descriptions.Item>
                <Descriptions.Item label="Buttons">
                    <Button onClick={showHistoryModal}> History</Button>
                    <Button onClick={showPredModal}> Predictions</Button>
                    {props.targetEvent &&
                        <Button onClick={showMatchingModal}>Match Anomaly</Button>
                    }
                </Descriptions.Item>
            </Descriptions>
            {props.targetEvent ?
                !loading ?
                    <div style={{padding: 8, maxHeight: maxHeight, overflowY: 'auto'}}>
                        {/*<Row>*/}
                        {/*    <div style={{marginLeft: 16, marginRight: 16}}> Comments</div>*/}
                        {/*</Row>*/}
                        <Row style={{marginBottom: 8}}>
                            <div style={{marginLeft: 16, marginTop: 8, marginRight: 16}}>Matching Mode</div>
                            <Radio.Group options={modeOptions} onChange={onChangeMode} value={matchingMode}
                                         optionType="button"/>
                            <div style={{flex: 1}}/>
                            {/* This empty div pushes the star to the right */}
                            {showAllTags ?
                                <VerticalAlignMiddleOutlined
                                    style={{marginRight: 8}}
                                    onClick={handleShowButtonClick}
                                /> :
                                <ColumnHeightOutlined
                                    style={{marginRight: 8}}
                                    onClick={handleShowButtonClick}
                                />
                            }
                            {anomalyFavorite ?
                                <StarFilled
                                    style={{marginRight: 32, color: '#ffd700', cursor: 'pointer'}}
                                    onClick={handleStarClick}
                                /> :
                                <StarOutlined
                                    style={{marginRight: 32, cursor: 'pointer'}}
                                    onClick={handleStarClick}
                                />
                            }
                        </Row>
                        {/*Positive*/}
                        {/*<Row>*/}
                        {/*    <Checkbox.Group options={positiveTagOptions} defaultValue={['Apple']} onChange={onChangeTags}*/}
                        {/*                    style={{width: 430}}/>*/}
                        {/*</Row>*/}
                        <DescriptionsComponent descriptionItems={descriptionItems}/>
                        <Divider style={{marginBottom: 8, marginTop: 8}}></Divider>
                        <div style={{width: 400}}>
                            <CommentTags UAID={UAID} targetEvent={props.targetEvent}
                                         onUpdateComment={props.onUpdateComment}/>
                        </div>
                    </div>
                    : <Skeleton active style={{marginLeft: 16, marginRight: 16, width: 350}}/>
                : <div style={{marginLeft: 72, marginTop: 72}}> No target event selected</div>
            }
        </>
    );
};

export default MatchingCard;