import React, {useEffect, useState} from 'react';
import {debounce} from 'lodash';
import {Modal, Input, Descriptions, Select, Button, Alert, notification} from 'antd';
import axiosInstance from "../../services/axios";
import DescriptionSelectEdit from "./DescriptionSelectEdit";

const {Option} = Select;

const MatchingModal = (props) => {

        const [matchingConfidence, setMatchingConfidence] = useState(null);
        const [eventId, setEventId] = useState(null);
        const [comment, setComment] = useState(null);
        const [isEventValid, setIsEventValid] = useState(null);

        useEffect(() => {
            setMatchingConfidence(null)
            if (props.targetEvent) {
                setEventId(props.targetEvent)
            } else {
                setEventId(null)
            }
            setIsEventValid(null)
        }, [props.count, props.targetEvent]);

        const fixedValues = [
            {value: 4, label: ": sure TP"},
            {value: 3, label: ": plausible TP"},
            {value: 2, label: ": possible TP"},
            {value: 1, label: ": unlikely TP"},  // Add your new value here
            {value: 0, label: ": unsure"},
            {value: -1, label: ": unlikely FP"},
            {value: -2, label: ": possible FP"},
            {value: -3, label: ": plausible FP"},
            {value: -4, label: ": sure FP"}
        ]

        const handleCancel = () => {
            props.hideModal && props.hideModal();
        };

        const handleEventIdChange = (e) => {
            setEventId(e.target.value);
        };

        const handleCommentChange = (e) => {
            setComment(e.target.value);
        };

        const handleSelectChange = (value) => {
            setMatchingConfidence(value);
        };

        function handleButtonClick() {

            const payload = {
                customer: props.data.customer,
                uaid: props.id,
                event_id: eventId,
                comment: comment,
                matching_confidence: matchingConfidence
            }
            notification['info']({message: 'Matching request submitted ...'});
            axiosInstance.post('/api/anomaly_tool/match', payload)
                .then(res => {
                    notification['success']({message: 'Matching request processed successfully'});
                    console.log('Matching request processed successfully')
                    setTimeout(() => {
                        props.setReload(props.reload + 1)
                    }, 1000);
                    props.hideModal()
                })
                .catch(error => {
                    console.log(error);
                    notification['error']({
                        message: 'Error',
                        description: error.message + ' - ' + error.response.data.message
                    });
                })
        }

        return (
            <>
                <Modal title="Match Anomaly to Event"
                       open={props.open}
                       onCancel={handleCancel}
                       width={450}
                       height={"90vh"}
                       footer={null}
                >
                    <Descriptions bordered size={'small'}>
                        <Descriptions.Item label={'Anomaly'}>
                            <Input
                                value={props.id}
                                disabled={true}
                                // suffix={'test'}
                            />
                        </Descriptions.Item>
                    </Descriptions>
                    <Descriptions bordered size={'small'}>
                        <Descriptions.Item label={'Event'}>
                            <Input
                                value={eventId}
                                placeholder="please input Event ID"
                                onChange={handleEventIdChange}
                            />
                        </Descriptions.Item>
                    </Descriptions>
                    <Descriptions bordered size={'small'}>
                        <Descriptions.Item label={'Matching Confidence'}>
                            <Select
                                value={matchingConfidence}
                                onChange={handleSelectChange}
                                style={{width: 202}}
                                placeholder="please select MC"
                            >
                                {fixedValues.map((option) => (
                                    <Option key={option.value} value={option.value}>
                                        {`${option.value}${option.label}`}
                                    </Option>
                                ))}
                            </Select>
                        </Descriptions.Item>
                    </Descriptions>
                    <Descriptions bordered size={'small'}>
                        <Descriptions.Item label={'Comment'}>
                            <Input.TextArea
                                placeholder="optional comment"
                                onChange={handleCommentChange}
                                rows={3} // Set the number of lines to 3
                            />
                        </Descriptions.Item>
                    </Descriptions>
                    {props.data?.anomaly_type === 'matched' &&
                        <Alert
                            message="Anomaly has already been matched"
                            description="This action will overwrite the current matching"
                            type="error"
                            showIcon
                        />
                    }
                    {props.data?.anomaly_type === 'proactive' &&
                        <Alert
                            message="Anomaly is proactive"
                            description="This action will overwrite the default event"
                            type="error"
                            showIcon
                        />
                    }
                    {props.eventStatus !== 'in_analysis' &&
                        <Alert
                            message="Event is not in analysis - please verify event status"
                            type="error"
                            showIcon
                        />
                    }
                    <Button style={{marginTop: 16}} disabled={matchingConfidence === null || !eventId}
                            onClick={() => handleButtonClick()}>
                        Submit
                    </Button>
                </Modal>
            </>
        );
    }
;

export default MatchingModal;