import React, { useState, useEffect } from 'react';
import { Checkbox } from 'antd';

const CheckboxRenderer = (props) => {
    const { context, value } = props;
    const [eventId, setEventId] = useState(context.eventId);
    const [checked, setChecked] = useState(false);

    useEffect(() => {
        if (context.eventId !== eventId) {
            setEventId(context.eventId);
        }
        if (value && typeof value === 'object' && value.hasOwnProperty(context.eventId)) {
            setChecked(value[context.eventId]['matching_favorite'] || false);
        } else {
            setChecked(false);
        }
    }, [context.eventId, eventId, value]);

    const handleChange = () => {
        // Checkbox is not editable, so no action on change
    };

    return (
        <Checkbox
            checked={checked}
            onChange={handleChange}
            disabled
        />
    );
};

export default CheckboxRenderer;
