import React, { memo, useEffect, useRef, useState } from 'react';
import {Select} from 'antd';

const {Option} = Select;

const CustomSelectEditor = (props) => {
    const [selectedValue, setSelectedValue] = useState(props.value);

    useEffect(() => {
        setSelectedValue(props.value);
    }, [props.value]);

    const handleSelectChange = (value) => {
        setSelectedValue(value);
        props.node.setDataValue(props.field ? props.field : 'matching_confidence',value).
        props.stopEditing();
    };

    return (
        <Select
            value={selectedValue}
            onChange={handleSelectChange}
            style={{width: 120}}
            size={'small'}
        >
            {props.fixedValues.map((option) => (
                <Option key={option.value} value={option.value}>
                    {`${option.value}${option.label}`}
                </Option>
            ))}
        </Select>
    );
}

export default CustomSelectEditor;