import React from "react";
import "./CarTopViewVector.css";

const CarTopViewVector = () => {
  return (
    <g id="CAR_TOP_VIEW">
      <g id="svg3575">
        <path
          id="path203"
          className="carTopCls1"
          d="M721.51,115.72v.8l.2-.1c3.1,1.6,6.2,4.71,7.2,7.61-.4-3.3-4.7-7.21-6.7-9l-.6-.1a2.49,2.49,0,0,0-.1.8Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2690"
          className="carTopCls2"
          d="M462,147.31c31.29,6.91,98.69,7.24,126.49,7.25,33.7,0,73.1-1.06,108.6-5.65l.4.3-.3.4c-35.5,4.59-75,5.67-108.7,5.65-27.8,0-95.2-.34-126.59-7.25l-.3-.5Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2692"
          className="carTopCls2"
          d="M461.6,147.71c-9.72,25.7-12.84,80.09-12.85,102.09l-.8.8-.8-.8c0-22.2,3.13-76.4,13-102.59l1-.4Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2694"
          className="carTopCls2"
          d="M354.81,121.36a30.08,30.08,0,0,1,11.6-2.09c4.8,0,10.9.8,18.9,2.71,16.09,3.9,63.29,17.42,69.49,19.43l.2.5-.5.2c-6.1-2-53.4-15.63-69.39-19.43a81.88,81.88,0,0,0-18.7-2.71,28.62,28.62,0,0,0-11.3,2l-.5-.2Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2696"
          className="carTopCls2"
          d="M454.7,141.41c4.2,1.3,6.7,2.8,6.7,5.1v.5l-.4.3-.3-.4v-.4c.1-1.5-2-3-6.2-4.3l-.3-.5Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2698"
          className="carTopCls2"
          d="M460.7,147.91c-11-2.51-52.8-15.22-64.3-18.33s-29.29-6.51-35.59-7h-1.2a13.51,13.51,0,0,0-5.5,1.1l-1-.4.4-1a15.65,15.65,0,0,1,6.2-1.19l1.3.1c6.5.5,24.3,4,35.89,7.11s53.4,15.82,64.3,18.23l.6.9Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2700"
          className="carTopCls2"
          d="M340.35,250.06c0-22,3.83-77.4,14.14-100.1,7.11-15.69,14.61-21,24.61-21a49.07,49.07,0,0,1,12.3,1.91c18.1,4.61,42.7,11.32,50.2,13.72,3.5,1.1,5.9,1.9,7.6,3a5.71,5.71,0,0,1,2.49,5.21,30.09,30.09,0,0,1-.8,5.89c-5.21,20.2-10.43,66.1-10.44,91.3l-.4.4-.4-.4c0-25.3,5.23-71.2,10.54-91.5a30.48,30.48,0,0,0,.8-5.7c0-2.3-.7-3.6-2.19-4.6s-4-1.8-7.4-2.9c-7.4-2.4-32.1-9.11-50.1-13.72a48.31,48.31,0,0,0-12.1-1.91c-9.7,0-16.8,5-23.91,20.49-10.21,22.4-14,77.8-14,99.8l-.4.4Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2702"
          className="carTopCls2"
          d="M413.12,91.69c32.5-.69,68.4-1.07,75.7-1.17l.8.8-.8.7c-7.3.1-43.2.48-75.7,1.17l-.8-.8Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2704"
          className="carTopCls2"
          d="M410.21,108.09c35-.89,79.7-1.57,109.1-1.56,10.6,0,19.1.11,24.6.32l.4.4-.4.4c-5.4-.21-14-.31-24.6-.32-29.4,0-74.2.67-109.1,1.56l-.4-.4Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2706"
          className="carTopCls2"
          d="M364.31,109.57c4.9-.4,13.4-.7,24.1-1.09l.4.4-.4.4c-10.8.39-19.3.69-24.1,1.09l-.4-.4Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2708"
          className="carTopCls2"
          d="M410.51,104.89c34.1-.79,77.8-1.47,107.1-1.46,11.3,0,20.4.11,26,.32l.4.4-.4.4c-5.5-.21-14.6-.31-25.9-.32-29.2,0-73,.57-107,1.46l-.4-.4Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2710"
          className="carTopCls2"
          d="M411.21,110.79c38.8-1,90.4-2.16,118.9-2.15,6.3,0,11.6.1,15.2.21l.5.6-.6.5c-3.7-.11-8.9-.21-15.2-.21-28.5,0-80.1,1.26-118.9,2.15l-.6-.5Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2712"
          className="carTopCls2"
          d="M545.51,109.25c6.1,6.8,15.49,28.8,16.79,33.5l-.4.7-.7-.4c-1.2-4.4-10.69-26.6-16.49-33.1v-.8Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2714"
          className="carTopCls2"
          d="M537.52,92.24c.6,2.5.6,3.5,3.8,7.1a37.2,37.2,0,0,1,3.09,3.71,4.67,4.67,0,0,1,.7,2.4c.2,1.7.3,3.8.3,3.8l-.5.6-.6-.5s-.1-2.1-.3-3.7a5.2,5.2,0,0,0-.6-2,22.32,22.32,0,0,0-2.89-3.5c-3.3-3.6-3.5-5.1-4.1-7.6l.4-.6Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2716"
          className="carTopCls2"
          d="M906.25,250.1c0-24-4.66-86.9-14.55-112-2.1-5.4-3.9-9.91-7.49-13.81s-9.1-7.2-18.7-10.1c-9.2-2.81-25.3-5.92-32.1-7.22-12.2-2.4-24.29-4.91-30.39-6.81-11.4-3.71-17.7-5.21-29.1-6.21-6.1-.61-27.3-.82-44-1.32-7.4-.21-19.2-.31-31.1-.32-15.1,0-30.2.19-36.1.59-5.4.39-11.4.59-18.2.59s-14.5-.21-23.1-.51c-17.1-.61-82.4-.94-90.2-.94l-.8-.8.8-.8c7.7,0,73,.33,90.2.94,8.6.3,16.2.51,23,.51s12.8-.2,18.1-.59c6-.4,21.1-.59,36.2-.59,11.9,0,23.8.11,31.2.32,16.7.5,37.9.71,44.1,1.32,11.4,1.1,18,2.6,29.4,6.31,6,1.9,18.09,4.41,30.29,6.81,6.8,1.4,23,4.41,32.3,7.32,9.8,3,15.5,6.4,19.4,10.5s5.69,8.81,7.89,14.21c9.89,25.7,14.46,88.5,14.45,112.6l-.8.8Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2718"
          className="carTopCls2"
          d="M772.92,93.44c7.1,3.61,25.4,8.22,42.69,12.32s33.9,7.82,37.8,9.82l.2.5-.5.2c-3.7-1.9-20.2-5.71-37.6-9.82S779.92,97.85,772.62,94l-.2-.5Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2720"
          className="carTopCls2"
          d="M695.22,99c48.4,2.32,139.39,15.06,153.79,18.77l.3.5-.5.3c-14.2-3.61-105.29-16.35-153.59-18.67l-.4-.4Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2722"
          className="carTopCls2"
          d="M735.11,102.83s-.2,1.4-.3,2.9v.4a3,3,0,0,0,1.2,2.6c.9.7,2.7,1.3,5.6,1.7,6.1.9,19.5,2,22.5,2.21h.7c2.9-.1,3.7-1.4,3.7-3.4a9.75,9.75,0,0,0-.5-2.8l.3-.5.5.3a10.47,10.47,0,0,1,.5,3,3.87,3.87,0,0,1-4.5,4.2H764c-3-.2-16.3-1.31-22.5-2.21-3-.4-4.8-1-5.9-1.8a3.79,3.79,0,0,1-1.5-3.2v-.4c.1-1.6.3-3,.3-3l.4-.3Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2724"
          className="carTopCls2"
          d="M665.51,112.8c2.1,4.1,7.69,19.6,9.29,25.6l-.4.7-.7-.4c-1.5-5.8-7.19-21.5-9.19-25.4l.2-.7Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2726"
          className="carTopCls2"
          d="M672.62,91.2a89.12,89.12,0,0,1,13.7,2.31c5.8,1.4,11.7,3.2,14.4,5.5l.1.8-.8.1c-2.4-2-8.2-3.9-14-5.3a102.41,102.41,0,0,0-13.5-2.31l-.5-.6Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2728"
          className="carTopCls2"
          d="M707.92,100c1.4,1.1,6.79,5.2,8.59,6.6,2.3,1.8,4.7,3.9,4.9,6.9a20.34,20.34,0,0,1,.1,2.2,4.48,4.48,0,0,1-.1,1.2l-.6.4-.4-.6h0v-1a20.34,20.34,0,0,0-.1-2.2c-.1-2.3-2.2-4.4-4.5-6.1-1.8-1.3-7.19-5.4-8.59-6.6l-.1-.8Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2730"
          className="carTopCls2"
          d="M531.92,91.94c6.2.1,108.5,3.15,120.3,4.25l.3.4-.4.3c-11.7-1.2-114.1-4.15-120.2-4.25l-.4-.4Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2732"
          className="carTopCls2"
          d="M545.31,103.75c28.6.11,140.2,3.36,174.7,7.57l.3.4-.4.3c-34.4-4.11-146.1-7.36-174.6-7.57l-.4-.4Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2734"
          className="carTopCls2"
          d="M545.41,106.75c28.6.11,140.2,3.36,174.7,7.57l.3.4-.4.3c-34.4-4.11-146.1-7.36-174.6-7.57l-.4-.4Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2736"
          className="carTopCls2"
          d="M545.31,108.75c28.6.11,140.9,3.36,175.5,7.57l.5.6-.6.5c-34.4-4.11-146.8-7.36-175.4-7.57l-.5-.5Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2738"
          className="carTopCls2"
          d="M651.42,97c1.7.7,7.7,1.41,9.9,1.41h.2l.1.1h0c.3.3,2,1.7,7.7,2,3.3.2,5.8.4,7.8.4a11.37,11.37,0,0,0,4.8-.79h.2s6.6.1,8.5.1h.8c1.6,0,3.1-.1,3.5-.6h.5v.5c-.9.9-2.5.9-4.1.9h-.8c-1.9,0-8.5-.1-8.5-.1v-.4l.2.4a13.54,13.54,0,0,1-5.1.79c-2.1,0-4.6-.2-7.9-.4-6.7-.3-8.2-2.2-8.3-2.3l.3-.2v.4c-2.2-.1-8.2-.71-10.1-1.51l-.2-.5Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2740"
          className="carTopCls2"
          d="M694.32,99.51a5.55,5.55,0,0,0-.8-1.8,5.93,5.93,0,0,0-3.8-1.8,105,105,0,0,0-17.1-1.21,86.39,86.39,0,0,0-18.5,1.69l-.2-.8.3.7c-.9.3-1.4.8-1.4,1l-.9.5-.5-1a3.32,3.32,0,0,1,2.4-2h0c5.2-1.29,12.2-1.69,18.9-1.69a110.5,110.5,0,0,1,17.4,1.21c2.5.5,3.9,1.4,4.7,2.4a5.65,5.65,0,0,1,1,2.1l-.4,1Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2742"
          className="carTopCls2"
          d="M686.32,94.61c.1,0,.9.5.9,2s-.1,3.7-.1,3.7l-.4.4-.4-.4s.1-2.4.1-3.7c0-.6-.1-1-.3-1.1l-.1-.2h0l.1-.1v.1h0l.1-.1v.1l-.2-.5Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2744"
          className="carTopCls2"
          d="M662.12,99.2a1.89,1.89,0,0,1,.7-1.4,4.58,4.58,0,0,1,2.9-.6h3.1c3.7,0,8.7.1,10.4.2a4.29,4.29,0,0,1,2.5.81,2.61,2.61,0,0,1,.8,2.2l-.4.4-.4-.4a2.06,2.06,0,0,0-.5-1.61,3.6,3.6,0,0,0-2-.6c-1.6-.1-6.7-.2-10.3-.2h-3.1c-1.5,0-2.2.2-2.4.4a1.57,1.57,0,0,0-.4,1l-.5.3Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2746"
          className="carTopCls2"
          d="M531,92.84c-.3-.6-1.2-2.1-1.4-2.5l-.5-.5a8.48,8.48,0,0,0-2.1-.5,82.4,82.4,0,0,0-9.6-.41,149.52,149.52,0,0,0-18.7,1,62.43,62.43,0,0,0-8.9,1.89l-.5.2-.1.1h0v.3l-.8.8-.8-.8.3-1.2a2.55,2.55,0,0,1,1.2-.8c1.1-.5,3.6-1.1,9.2-2a150,150,0,0,1,18.9-1.1,67,67,0,0,1,9.8.51,11.06,11.06,0,0,1,2.6.6,3,3,0,0,1,1,.9,24,24,0,0,1,1.6,2.7l-.3,1Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2748"
          className="carTopCls2"
          d="M488.32,92.32a64.18,64.18,0,0,0,9.3,1h.4l.1.2-.1.1.1-.1h0l-.1.1.1-.1s.8,1.3,5.1,1.6c2.8.2,5.3.3,7.3.3a27.33,27.33,0,0,0,3.2-.1c1.8-.1,3.8-.4,5-1.3l.1-.1h.1s6.1-.09,7.7-.09,4.1-.4,4.9-1.2l.3.3-.3.2-.2-.3.2-.5.5.2.2.3.1.2-.2.2c-1.2,1.2-3.8,1.4-5.4,1.5s-7.7.09-7.7.09v-.4l.2.3c-1.5,1.2-3.6,1.4-5.4,1.5-.7,0-1.9.1-3.3.1-2,0-4.6-.1-7.4-.3-4.8-.3-5.7-1.81-5.8-2l.3-.19v.4h-.2a64.42,64.42,0,0,1-9.5-1.1l-.3-.5Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2750"
          className="carTopCls2"
          d="M498.92,94.33a2.72,2.72,0,0,1,.3-1.4,2.44,2.44,0,0,1,2-1.1c1.6-.2,8.8-.6,12.9-.6a17.1,17.1,0,0,1,2.6.1,4.64,4.64,0,0,1,2,.8,2.1,2.1,0,0,1,.5,1.5V94l-.4.4-.4-.4v-.4a1.08,1.08,0,0,0-.3-.9,3.69,3.69,0,0,0-1.6-.6A15,15,0,0,0,514,92c-4,0-11.2.4-12.7.6-1,.1-1.3.4-1.5.7l-.2,1.1-.4.3Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2752"
          className="carTopCls2"
          d="M522.72,87.94a3.37,3.37,0,0,1,.9,2.4v3.8l-.4.4-.4-.4v-3.8a2.6,2.6,0,0,0-.6-1.8V88Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2754"
          className="carTopCls2"
          d="M721,111.32c26.8,2.31,102.8,10.85,135.29,18.36l.3.5-.5.3c-32.39-7.51-108.39-16-135.19-18.36l-.4-.4Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2756"
          className="carTopCls2"
          d="M760.71,121.54c18.3,2.21,53.59,7.62,71.19,11.43,39.5,8.52,46.29,25.62,51.78,45.22,7.59,26.91,8.37,62.41,8.37,71.91l-.4.4-.4-.4c0-9.5-.78-45-8.27-71.71-5.59-19.6-12-36.2-51.18-44.72-17.5-3.81-52.79-9.22-71.09-11.33l-.3-.4Z"
          transform="translate(-91.04 -70.25)"
        />
        <polygon
          id="polygon2758"
          className="carTopCls2"
          points="790.56 79.74 789.86 79.84 785.66 76.34 785.56 75.54 786.36 75.54 790.56 79.04 790.56 79.74"
        />
        <path
          id="path2760"
          className="carTopCls2"
          d="M798.7,142c4.3-1.5,10.2-2.6,19-2.6h2.8c10.3.3,32.2,1.81,36.3,1.91s15.2,2.11,18.1,2.71a6.63,6.63,0,0,1,2.6,1.8l.1.8-.8.1c-.7-.6-1.5-1.5-2.2-1.6-2.8-.6-13.9-2.61-17.9-2.71s-26-1.71-36.3-1.91h-2.8c-8.7,0-14.5,1.1-18.6,2.6l-.7-.3Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2762"
          className="carTopCls2"
          d="M887.28,180.09c-1.89-16.2-9.18-41.5-14-51.1a33.89,33.89,0,0,0-3.39-6,9.79,9.79,0,0,0-4.3-2.9,65.52,65.52,0,0,0-10.9-3.31,9.69,9.69,0,0,0-2.3-.3,4.81,4.81,0,0,0-2.1.4l-.6.7c0,.2.2.6.8,1.1,1.1.8,4.5,3,7.9,5.1s6.59,4.2,7.69,5.11c7,5.7,9.6,10.8,11.5,16.5s7.29,25.4,8.08,28.6c.6,2.4.5,3.5,2,5.3l-.1.8-.8-.1c-1.6-2-1.6-3.5-2.2-5.7-.79-3.2-6.19-22.9-8.08-28.5s-4.3-10.4-11.2-16.1c-.9-.81-4.3-2.91-7.59-5s-6.7-4.3-7.9-5.2a2.53,2.53,0,0,1-1.2-1.9,2.17,2.17,0,0,1,1.1-1.7,5,5,0,0,1,2.6-.6,12.07,12.07,0,0,1,2.5.3,62.48,62.48,0,0,1,11.1,3.31,12.16,12.16,0,0,1,4.7,3.2,37,37,0,0,1,3.49,6.2c4.9,9.8,12.19,35.1,14.08,51.5l-.5.6Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2764"
          className="carTopCls2"
          d="M888.38,180.4c2.5,12.2,5.88,53.5,5.87,69.7l-.5.5-.5-.5c0-16.1-3.37-57.5-5.87-69.51l.4-.6Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2766"
          className="carTopCls2"
          d="M883.89,158.09c1,1.7,4.8,3.81,8.5,6.31s7.6,5.4,8.49,9.3l-.3.5-.5-.3c-.79-3.5-4.49-6.4-8.19-8.8s-7.6-4.51-8.8-6.61l.1-.5Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2768"
          className="carTopCls2"
          d="M698.19,149c11.29,29,13.87,82.41,13.86,101l-.8.8-.8-.8c0-18.4-2.67-71.9-13.76-100.41l.41-1Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2770"
          className="carTopCls2"
          d="M697.39,148.51c13-1.69,69.81-8.27,80.51-9.26,3.3-.3,6.4-.6,9.4-.6,6.4,0,12.4,1.71,17.2,8.91,19.48,29.21,22.86,81.51,22.85,103l-.8.8-.8-.8c0-21.3-3.47-73.6-22.55-102.21-4.5-6.8-9.7-8.21-15.9-8.21-2.9,0-6,.3-9.3.6-10.6,1-67.5,7.47-80.41,9.26l-.9-.7Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2772"
          className="carTopCls2"
          d="M367.31,111.67c6.2,1.2,50.3,12.62,62.49,16.23,40.1,12,64.4,15.62,97.9,16.44,9.9.2,22.5.41,36,.41,32.3,0,70.1-.67,90.5-2.26,28.5-2.18,63.7-8.57,73.4-11.76a22.07,22.07,0,0,0,6.3-2.9,3.53,3.53,0,0,0,1.71-2.8c0-1.7-1.6-3.7-4-6.1a26.57,26.57,0,0,0-4-3.4l-.9-.61-.3-.1-.2-.5.5-.2c.1,0,1.7.71,5.4,4.31,2.5,2.4,4.3,4.5,4.3,6.6a4.48,4.48,0,0,1-2,3.4,22.1,22.1,0,0,1-6.5,3c-9.8,3.19-45,9.58-73.6,11.76-20.5,1.59-58.3,2.28-90.6,2.26-13.5,0-26.1-.11-36-.31-33.6-.82-58-4.53-98.1-16.44-12.09-3.61-56.29-15-62.49-16.23l-.3-.4Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2774"
          className="carTopCls2"
          d="M376.81,111.57c15.5,3.81,43.1,11.22,52.39,14,40.1,12,64.3,15.72,97.9,16.44,10.4.2,23.5.41,37.7.41,31.6,0,68-.67,88-2.16,23.7-1.79,53.8-6.67,69.6-10.47l.7.4-.4.7c-15.9,3.9-46,8.78-69.7,10.57-20.1,1.49-56.4,2.18-88.1,2.16-14.1,0-27.3-.11-37.7-.41-33.6-.82-58-4.53-98.1-16.54-9.19-2.71-36.79-10.12-52.29-14l-.4-.7Z"
          transform="translate(-91.04 -70.25)"
        />
        <polygon
          id="polygon2776"
          className="carTopCls2"
          points="630.58 43.97 630.98 43.67 635.58 43.87 635.98 44.27 635.58 44.67 630.88 44.37 630.58 43.97"
        />
        <path
          id="path2778"
          className="carTopCls2"
          d="M729.3,126.23c-.5,1.7-1.9,3.2-6.3,4.49l-.7-.4.4-.7c4.3-1.3,5.1-2.39,5.6-3.79l.7-.3Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2780"
          className="carTopCls2"
          d="M722.21,114.12c1.8,1.7,7.4,6.21,7.5,10.51a4.29,4.29,0,0,1-.61,2.2l-.5.1-.1-.5a4.09,4.09,0,0,0,.51-1.8c.1-3.7-5.4-8.31-7.2-10V114Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2782"
          className="carTopCls2"
          d="M721.71,116.42c3.8,1.9,7.5,6.11,7.49,9.41a4,4,0,0,1-.3,1.5l-.7.2-.2-.7.2-1c.11-2.5-3.39-6.81-6.89-8.41l-.3-.7Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2784"
          className="carTopCls2"
          d="M461.71,352c31.5-7,98.8-7.16,126.6-7.15,33.7,0,73.1,1.14,108.7,5.75l.3.4-.4.3c-35.5-4.61-74.9-5.73-108.6-5.75-27.8,0-95.2.26-126.5,7.15l-.5-.3Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2786"
          className="carTopCls2"
          d="M460.11,353c-9.79-26.21-12.87-80.41-12.86-102.61l.8-.8.8.8c0,22,3.07,76.41,12.76,102.11l-.4,1Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2788"
          className="carTopCls2"
          d="M355,378a28.32,28.32,0,0,0,11.19,2c4.8,0,10.8-.8,18.71-2.69,16.1-3.9,63.3-17.38,69.4-19.37l.5.2-.2.5c-6.2,2.09-53.4,15.57-69.5,19.37-8,1.89-14.11,2.69-18.91,2.69a28.62,28.62,0,0,1-11.6-2.11l-.19-.5Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2790"
          className="carTopCls2"
          d="M454.4,358c4.2-1.3,6.3-2.8,6.21-4.3v-.4l.3-.4.5.3.1.5c-.11,2.2-2.61,3.7-6.71,5.1l-.5-.2Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2792"
          className="carTopCls2"
          d="M461,353.71C450.1,356.1,408.3,368.89,396.7,372s-29.31,6.59-35.91,7.09c-.5,0-1.1.1-1.7.09a12.63,12.63,0,0,1-4.8-.9l-.39-1,1-.4a11.11,11.11,0,0,0,4.2.8,6.88,6.88,0,0,0,1.5-.09c6.3-.5,24.1-4,35.6-7s53.31-15.78,64.31-18.27l.9.6Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2794"
          className="carTopCls2"
          d="M341.15,250.16c0,21.9,3.77,77.2,14,99.6,7.09,15.61,14.19,20.51,23.89,20.51a47.31,47.31,0,0,0,12.1-1.89c18.1-4.59,42.7-11.28,50.11-13.68,3.5-1.1,5.9-1.9,7.4-2.9s2.2-2.3,2.2-4.6a30.48,30.48,0,0,0-.8-5.7l.4-.1-.4.1c-5.19-20.3-10.37-66.2-10.36-91.5l.4-.4.4.4c0,25.2,5.17,71.1,10.36,91.3h0a30.26,30.26,0,0,1,.8,5.91c0,2.5-.8,4.09-2.5,5.19s-4.2,1.9-7.61,3c-7.5,2.4-32.1,9.09-50.2,13.68A48.08,48.08,0,0,1,379,371c-10,0-17.5-5.3-24.59-21-10.29-22.7-14.07-77.9-14.06-99.9l.4-.4Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2796"
          className="carTopCls2"
          d="M412.38,406.89c32.5.71,69,1.23,76.2,1.23l.8.8-.8.8c-7.3-.1-43.8-.52-76.3-1.23l-.8-.8Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2798"
          className="carTopCls2"
          d="M410.29,391.29c35.2.91,80.2,1.73,109.5,1.74,10.3,0,18.6-.09,23.9-.28l.4.4-.4.4c-5.3.19-13.7.29-24,.28-29.3,0-74.4-.73-109.6-1.64l-.4-.4Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2800"
          className="carTopCls2"
          d="M410.39,394.39c34.1.81,77.8,1.53,107,1.54,11.3,0,20.4-.09,26-.29l.4.41-.4.39c-5.5.2-14.7.3-26,.29-29.2,0-73-.63-107.1-1.54l-.4-.4Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2802"
          className="carTopCls2"
          d="M411.09,388.19c38.9,1,90.7,2.34,119.2,2.35,6.2,0,11.3-.1,14.9-.19l.6.5-.5.6c-3.6.09-8.7.19-15,.19-28.5,0-80.3-1.34-119.2-2.35l-.5-.6Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2804"
          className="carTopCls2"
          d="M544.59,390.25c5.8-6.5,15.31-28.7,16.51-33.1l.7-.4.4.7c-1.3,4.7-10.71,26.6-16.81,33.5h-.8Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2806"
          className="carTopCls2"
          d="M536.28,407.64c.6-2.5.8-4,4.11-7.6a28.85,28.85,0,0,0,2.9-3.5,4.2,4.2,0,0,0,.6-2c.2-1.6.3-3.7.3-3.7l.6-.5.5.6s-.1,2.1-.3,3.8a4.88,4.88,0,0,1-.8,2.4,21.67,21.67,0,0,1-3.1,3.69c-3.21,3.6-3.21,4.5-3.81,7.1l-.6.4Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2808"
          className="carTopCls2"
          d="M907.75,250.4c0,24.1-4.64,87-14.75,112.5-2.1,5.4-4,10.1-7.9,14.19s-9.61,7.6-19.41,10.5c-9.3,2.79-25.4,5.88-32.3,7.28-12.1,2.4-24.3,4.89-30.31,6.79a126.63,126.63,0,0,1-30.5,6.38c-6.2.6-26.4.69-43.1,1.19-7.4.19-19.3.29-31.2.28-15.1,0-30.1-.21-36.2-.61-5.3-.41-11.3-.61-18.1-.61s-14.4.19-23,.49c-17.2.59-82.5.86-90.2.86l-.8-.8.8-.8c7.7,0,73-.27,90.2-.86,8.6-.3,16.2-.49,23-.49s12.9.2,18.2.61c5.9.4,21,.61,36.1.61,11.9,0,23.8-.09,31.2-.28,16.7-.5,36.9-.59,43-1.19a123.48,123.48,0,0,0,30.11-6.28c6.1-2,18.3-4.39,30.4-6.79,6.8-1.4,23-4.39,32.1-7.19,9.6-2.89,15.1-6.19,18.71-10.09s5.4-8.29,7.5-13.79c9.91-25,14.64-88,14.65-112l.8-.8Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2810"
          className="carTopCls2"
          d="M772.48,406.24c7.3-3.69,25.51-8.18,42.91-12.28s34-7.78,37.6-9.78l.5.2-.2.5c-3.9,2-20.4,5.69-37.8,9.78s-35.61,8.69-42.71,12.28l-.5-.2Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2812"
          className="carTopCls2"
          d="M695.09,400.51c48.3-2.28,139.4-14.94,153.6-18.53l.5.3-.3.5c-14.3,3.59-105.4,16.25-153.81,18.63l-.39-.4Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2814"
          className="carTopCls2"
          d="M664.39,387c2-3.9,7.71-19.5,9.21-25.4l.7-.4.4.7c-1.6,6-7.21,21.5-9.31,25.6l-.7.2Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2816"
          className="carTopCls2"
          d="M672.48,408a89.48,89.48,0,0,0,13.5-2.29c5.7-1.3,11.6-3.2,14-5.3l.8.1-.11.8c-2.7,2.3-8.6,4.1-14.4,5.5a91,91,0,0,1-13.7,2.29l-.6-.5Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2818"
          className="carTopCls2"
          d="M707.09,399.42c1.5-1.2,6.9-5.2,8.6-6.6,2.3-1.7,4.4-3.8,4.5-6.1a20.34,20.34,0,0,0,.1-2.2v-1l.4-.6.6.4a6.76,6.76,0,0,1,.1,1.2,20.34,20.34,0,0,1-.1,2.2c-.2,2.9-2.6,5.1-4.9,6.9-1.8,1.3-7.2,5.4-8.6,6.5l-.8-.1Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2820"
          className="carTopCls2"
          d="M531.78,407.54c6.2-.1,108.6-3.05,120.2-4.15l.4.3-.3.4c-11.8,1.2-114.1,4.05-120.3,4.15l-.4-.4Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2822"
          className="carTopCls2"
          d="M545.19,395.65c28.5-.09,140.2-3.24,174.6-7.43l.4.3-.3.4c-34.5,4.09-146.1,7.24-174.7,7.43l-.4-.4Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2824"
          className="carTopCls2"
          d="M545.29,392.75c28.5-.09,140.2-3.24,174.6-7.43l.4.3-.3.4c-34.5,4.09-146.1,7.24-174.7,7.43l-.4-.4Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2826"
          className="carTopCls2"
          d="M545.19,390.35c28.5-.09,141-3.24,175.4-7.43l.6.5-.5.6c-34.5,4.09-146.9,7.24-175.5,7.43l-.5-.5Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2828"
          className="carTopCls2"
          d="M651,402.59c1.9-.8,7.91-1.39,10.1-1.49v.4l-.3-.2c.1-.1,1.61-2,8.31-2.3,3.3-.2,5.8-.4,7.9-.4a12.83,12.83,0,0,1,5.1.81l-.2.4v-.4s6.6-.1,8.5-.1h.8c1.6,0,3.2,0,4.1.9v.6h-.5c-.4-.5-1.9-.7-3.5-.6h-.8c-1.9,0-8.5.1-8.5.1h-.2a11,11,0,0,0-4.8-.81c-2,0-4.5.2-7.8.3a19.76,19.76,0,0,0-6.4,1.2,4.69,4.69,0,0,0-1.11.6l-.2.2h0l-.1.1h-.2c-2.1.1-8.2.69-9.8,1.39l-.5-.2Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2830"
          className="carTopCls2"
          d="M695.58,401.41a5,5,0,0,1-1,2.1c-.8,1-2.2,1.9-4.7,2.4-3.2.7-10.1,1.19-17.4,1.19a90.19,90.19,0,0,1-18.9-1.71h0a3.52,3.52,0,0,1-2.4-2l.5-.9.9.5a2.45,2.45,0,0,0,1.4,1l-.3.7.2-.7a85,85,0,0,0,18.5,1.71,105.59,105.59,0,0,0,17.1-1.19c2.3-.5,3.3-1.2,3.8-1.8a5,5,0,0,0,.81-1.8l1-.4Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2832"
          className="carTopCls2"
          d="M685.88,405h0s.4-.3.4-1.3c0-1.3-.09-3.7-.09-3.7l.4-.4.4.4s.09,2.4.09,3.8-.8,2-.9,2l-.5-.2Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2834"
          className="carTopCls2"
          d="M662.79,401c.09.5.09.8.39,1a4.47,4.47,0,0,0,2.4.4h3.1c3.7,0,8.7-.1,10.3-.2a3.31,3.31,0,0,0,2-.6,2,2,0,0,0,.51-1.59l.4-.4.4.4a2.61,2.61,0,0,1-.81,2.2,5.38,5.38,0,0,1-2.5.79c-1.7.1-6.7.2-10.4.2h-3.1a5,5,0,0,1-2.9-.6,2.26,2.26,0,0,1-.69-1.4l.3-.5Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2836"
          className="carTopCls2"
          d="M532.28,408a24,24,0,0,1-1.6,2.7c-.2.2-.4.7-1,.9a7.52,7.52,0,0,1-2.6.6,80.08,80.08,0,0,1-9.8.49c-7,0-15.1-.4-18.9-1.1a55.83,55.83,0,0,1-9.2-2,3.53,3.53,0,0,1-1.3-.8l-.3-1.2.8-.8.8.8v.3h0l.6.3c.9.4,3.4,1,8.9,1.91a149.52,149.52,0,0,0,18.7,1,79.91,79.91,0,0,0,9.6-.39,8.48,8.48,0,0,0,2.1-.5l.5-.5a18.61,18.61,0,0,0,1.4-2.5l1-.3Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2838"
          className="carTopCls2"
          d="M488,407.12a63.06,63.06,0,0,1,9.5-1.09h.2v.4l-.3-.21c.1-.2,1-1.69,5.8-2,2.8-.2,5.3-.2,7.4-.2a30.22,30.22,0,0,1,3.3.1,10.23,10.23,0,0,1,5.4,1.5l-.2.3v-.4s6.1.11,7.7.11,4.2.3,5.4,1.4l.2.2-.3.6-.5.2-.2-.5.2-.3.3.2-.3.3c-.7-.8-3.3-1.2-4.9-1.2s-7.7-.11-7.7-.11h-.1l-.1-.1c-1.2-1-3.2-1.3-5-1.3-.7,0-1.8-.1-3.2-.1-2,0-4.5.1-7.3.2a11,11,0,0,0-4.3,1l-.7.5-.1.1h0l-.1-.1.1.1h0l-.1-.1.1.1-.1.2h-.4a75.48,75.48,0,0,0-9.3,1l-.5-.3Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2840"
          className="carTopCls2"
          d="M499.48,405.73l.2,1.1c.2.3.5.6,1.5.7,1.6.2,8.7.6,12.8.6a15,15,0,0,0,2.5-.1,2.34,2.34,0,0,0,1.5-.6,1.22,1.22,0,0,0,.3-.9v-.4l.4-.4.4.4v.4a2,2,0,0,1-.5,1.5,3.76,3.76,0,0,1-2,.8,17.1,17.1,0,0,1-2.6.1c-4.1,0-11.2-.4-12.9-.6a2.44,2.44,0,0,1-2-1.1,2.72,2.72,0,0,1-.3-1.4l.3-.4Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2842"
          className="carTopCls2"
          d="M522.08,411.64a2.6,2.6,0,0,0,.6-1.8V406l.4-.4.4.4v3.8a3,3,0,0,1-.9,2.4h-.5Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2844"
          className="carTopCls2"
          d="M720.89,388.32c26.8-2.29,102.81-10.75,135.21-18.24l.5.3-.3.5c-32.51,7.49-108.51,15.95-135.31,18.24l-.4-.4Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2846"
          className="carTopCls2"
          d="M760.1,378.14c18.2-2.19,53.8-7.58,71.5-11.37,39.2-8.48,45.61-25.08,51.22-44.68,7.61-26.69,8.33-62.39,8.33-71.89l.4-.4.4.4c0,9.5-.82,45.2-8.43,72.09C878,341.89,871.2,359,831.7,367.47c-17.7,3.79-53.3,9.18-71.51,11.37l-.4-.3Z"
          transform="translate(-91.04 -70.25)"
        />
        <polygon
          id="polygon2848"
          className="carTopCls2"
          points="790.47 280.24 790.47 281.04 786.27 284.44 785.47 284.44 785.57 283.64 789.77 280.14 790.47 280.24"
        />
        <path
          id="path2850"
          className="carTopCls2"
          d="M799,357.46c4.1,1.5,9.9,2.6,18.6,2.6h2.8c10.3-.3,32.1-1.79,36.3-1.89,4-.1,15.1-2.09,17.9-2.69.7-.1,1.51-1,2.21-1.6l.8.1-.1.8a6.56,6.56,0,0,1-2.71,1.9c-2.9.6-13.9,2.59-18.1,2.69s-26,1.69-36.3,1.89h-2.8c-8.8,0-14.7-1.1-19-2.6l-.3-.7Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2852"
          className="carTopCls2"
          d="M888.32,320.5c-1.91,16.39-9.22,41.59-14.12,51.49a37.56,37.56,0,0,1-3.5,6.2,11.54,11.54,0,0,1-4.71,3.2,56.58,56.58,0,0,1-11.1,3.29,11.34,11.34,0,0,1-2.5.3,4.76,4.76,0,0,1-2.6-.6,1.85,1.85,0,0,1-1.1-1.7,2.28,2.28,0,0,1,1.2-1.9c1.2-.9,4.61-3,7.91-5.2s6.7-4.3,7.6-5c6.9-5.6,9.3-10.4,11.21-16.1s7.31-25.4,8.11-28.5c.6-2.2.6-3.7,2.2-5.7l.8-.1.1.8c-1.4,1.8-1.4,2.9-2,5.3-.8,3.2-6.21,22.9-8.11,28.6s-4.51,10.8-11.61,16.5c-1.1.89-4.3,3-7.7,5.09s-6.71,4.3-7.91,5.1c-.6.4-.8.8-.8,1.1l.6.7a5.18,5.18,0,0,0,2.1.4,11.15,11.15,0,0,0,2.3-.3,69.42,69.42,0,0,0,10.9-3.19,11.61,11.61,0,0,0,4.31-2.9,33.77,33.77,0,0,0,3.4-6c4.8-9.6,12.11-35,14-51.1l.6-.5Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2854"
          className="carTopCls2"
          d="M887.32,319.89c2.41-12,5.92-53.39,5.93-69.49l.5-.5.5.5c0,16.3-3.42,57.6-5.93,69.7l-.6.39Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2856"
          className="carTopCls2"
          d="M883.21,342c1.2-2.1,5-4.09,8.8-6.49s7.41-5.3,8.21-8.8l.5-.3.3.5c-1,3.9-4.81,6.8-8.51,9.2s-7.6,4.49-8.5,6.29l-.5.2Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2858"
          className="carTopCls2"
          d="M696.61,350.81c11.11-28.49,13.83-82,13.84-100.39l.8-.8.8.8c0,18.6-2.73,72-13.94,101l-1,.4Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2860"
          className="carTopCls2"
          d="M697.51,350.31c12.9,1.71,69.79,8.33,80.39,9.34,3.3.3,6.4.6,9.3.6,6.2,0,11.3-1.49,15.91-8.19,19.11-28.59,22.73-80.09,22.64-101.39l.8-.8.8.8c0,21.5-3.43,73-22.94,102.29-4.81,7.2-10.71,8.89-17.21,8.89-3,0-6.1-.3-9.4-.6-10.6-1-67.49-7.53-80.49-9.34l-.7-.9Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2862"
          className="carTopCls2"
          d="M366.59,387.77c6.1-1.1,50.81-12.78,62.91-16.37,40.1-12,64.5-15.58,98.11-16.36,9.9-.2,22.5-.29,36-.29,32.3,0,70.09.73,90.49,2.34,28.6,2.22,63.7,8.63,73.6,11.84a25,25,0,0,1,6.5,3,4.17,4.17,0,0,1,2,3.4c0,2.1-1.81,4.2-4.31,6.6-3.7,3.6-5.4,4.29-5.4,4.29l-.5-.2.2-.5.3-.1a21.55,21.55,0,0,0,4.9-4c2.4-2.4,4.11-4.4,4-6.1a3.62,3.62,0,0,0-1.7-2.8,22.07,22.07,0,0,0-6.3-2.9c-9.7-3.11-44.9-9.52-73.4-11.84-20.4-1.61-58.2-2.32-90.5-2.34-13.5,0-26.1.09-36,.29-33.5.78-57.8,4.37-97.9,16.36-12.2,3.59-56.81,15.27-63,16.47l-.4-.3Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2864"
          className="carTopCls2"
          d="M375,387.87c15.5-3.79,44.51-11.68,53.81-14.37,40.1-12,64.5-15.68,98.1-16.46,10.4-.2,23.6-.39,37.7-.39,31.6,0,68,.63,88.1,2.24,23.8,1.81,53.9,6.73,69.7,10.63l.4.7-.7.4c-15.8-3.8-45.9-8.72-69.6-10.53-20-1.51-56.4-2.22-88-2.24-14.1,0-27.3.09-37.7.39-33.5.78-57.8,4.47-97.9,16.36-9.3,2.79-38.31,10.58-53.81,14.37l-.7-.4Z"
          transform="translate(-91.04 -70.25)"
        />
        <polygon
          id="polygon2866"
          className="carTopCls2"
          points="630.46 315.87 630.76 315.47 635.46 315.17 635.86 315.57 635.46 315.97 630.86 316.17 630.46 315.87"
        />
        <path
          id="path2868"
          className="carTopCls2"
          d="M728.2,374.43c-.5-1.4-1.2-2.51-5.6-3.81l-.4-.7.7-.4c4.4,1.3,5.7,2.71,6.3,4.51l-.3.7Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2870"
          className="carTopCls2"
          d="M721.59,385.72c1.8-1.6,7.3-6.19,7.21-10a3.09,3.09,0,0,0-.5-1.8l.1-.5.5.1a4.28,4.28,0,0,1,.6,2.2c-.11,4.4-5.71,8.89-7.51,10.49h-.5Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2872"
          className="carTopCls2"
          d="M721.09,382.92c3.5-1.7,7-6,6.91-8.39l-.2-1,.2-.7.7.2a4,4,0,0,1,.3,1.5c-.1,3.3-3.71,7.49-7.51,9.39l-.7-.3Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2874"
          className="carTopCls2"
          d="M389.18,405.38c-4.4,0-20.8-.41-37.5-.92l-.4-.4.4-.4c16.7.51,33.1.92,37.5.92l.4.4Z"
          transform="translate(-91.04 -70.25)"
        />
        <polygon
          id="polygon2876"
          className="carTopCls2"
          points="273.46 318.01 273.96 317.52 289.16 317.92 289.75 318.52 289.15 319.02 273.95 318.62 273.46 318.01"
        />
        <polygon
          id="polygon2878"
          className="carTopCls2"
          points="300.25 337.93 299.45 338.63 257.95 337.61 257.25 336.81 258.05 336.01 299.55 337.13 300.25 337.93"
        />
        <path
          id="path2880"
          className="carTopCls2"
          d="M389.72,95.38c-4.4,0-22.6.89-39.3,1.38l-.4-.4.4-.4c16.7-.49,34.9-1.28,39.3-1.28l.4.4Z"
          transform="translate(-91.04 -70.25)"
        />
        <polygon
          id="polygon2882"
          className="carTopCls2"
          points="273.48 41.91 274.08 41.32 291.48 40.92 292.08 41.52 291.48 42.02 274.07 42.42 273.48 41.91"
        />
        <polygon
          id="polygon2884"
          className="carTopCls2"
          points="301.49 21.53 300.68 22.33 256.18 23.91 255.38 23.11 256.18 22.31 300.69 20.83 301.49 21.53"
        />
        <path
          id="path2886"
          className="carTopCls2"
          d="M116.82,311.46c46.39,40.82,199.38,63.79,232.48,64.8l.4.4-.4.4c-33.3-1.11-186.09-23.88-233-65v-.5Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2980"
          className="carTopCls2"
          d="M146,364.87c18.3,7.21,69.5,12.83,117.69,16.75s93.6,6,100.4,6.15l.5.5-.5.5c-6.8-.11-52.2-2.23-100.5-6.15s-99.39-9.54-118.09-16.85l-.3-.7Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2982"
          className="carTopCls2"
          d="M354.1,376.66l.4.2.3.7a3.07,3.07,0,0,1-.41,1.3,31.57,31.57,0,0,1-6,8.8h-.8v-.8a28.16,28.16,0,0,0,5.8-8.6c.21-.5.31-.8.31-.8h0l.3-.1v.3l-.3-.2.3-.1v.3l-.5-.6Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2984"
          className="carTopCls2"
          d="M318.15,250c0,27.3,3.18,56.3,9.47,79.8S343,371.26,354.5,376.46l.4,1-1,.4c-12.3-5.8-21.49-24.11-27.68-47.71s-9.48-52.8-9.47-80.2l.8-.8Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2986"
          className="carTopCls2"
          d="M386.49,393.38c-76.8-1.64-188.4-8.59-221.89-17.5l-.2-.3.3-.2c33.39,8.81,145,15.86,221.79,17.4l.2.2Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2988"
          className="carTopCls2"
          d="M364.39,389.07l-2.6,3.6h0s-1.2,1.4-3.5,3.59-6,6.4-7.31,7.9h0l-3,3.1h-.8v-.8l3-3.1.4.4-.4-.4c1.3-1.5,5-5.7,7.41-8s3.4-3.59,3.5-3.59l.4.4-.4-.3,2.6-3.6.8-.1Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2990"
          className="carTopCls2"
          d="M176.69,390.69c5.4,3,14.6,8.4,18.79,12s5.1,4.61,8.2,6.51l.2.7-.7.2c-3.2-1.9-4.2-3.1-8.3-6.61s-13.29-8.9-18.69-11.9l-.2-.7Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2992"
          className="carTopCls2"
          d="M347.38,407.76c-5.8-.3-17.8-.41-22.9-.61h-1.3a93.55,93.55,0,0,0-15.2,1.59c-4.8.8-18.8,2.69-40.2,2.89H262c-23.1,0-63.8-.83-76.5-2.43-13.7-1.71-20-3.21-31.29-10.91s-27.3-19.42-35.49-34.62c-8.19-15-24.48-51.11-24.45-113.71l.8-.8.8.8c0,62.3,16.26,98.11,24.25,112.91s23.79,26.51,35,34.12,17,8.9,30.69,10.71c12.4,1.6,53.2,2.42,76.3,2.43h5.8c21.3-.2,35.2-2.09,39.9-2.89a94.82,94.82,0,0,1,15.5-1.59h1.4c5.1.2,17,.21,22.9.61l.7.8Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2994"
          className="carTopCls2"
          d="M350.18,404.36c-6.6-.3-41.9-1.52-44.9-1.62h-.5a5.94,5.94,0,0,0-1.7.2l-.4.2h0c0,.1.9.6,2.8.8,6.6.7,38.4,1.72,42.6,1.82l.4.4-.4.4c-4.1-.2-36-1.12-42.6-1.82a9.81,9.81,0,0,1-2.5-.5c-.5-.2-1-.5-1-1s.5-.7.9-.9a5.25,5.25,0,0,1,2-.3h.5c3,.1,38.3,1.32,44.9,1.62l.4.4Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2996"
          className="carTopCls2"
          d="M120.22,332c-1.1-2.8-7.1-20.6-7.1-20.7l.3-.7.7.3,3.1,9.3c1.6,4.8,3.4,10,3.9,11.3l-.31.7Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path2998"
          className="carTopCls2"
          d="M175.49,391.48c-18.1-9-33.69-27-48.38-50.12-2.6-4-5.1-7.4-7.79-9.7-2.9-2.4-12-10.7-14.8-13.71v-.8h.8A184.68,184.68,0,0,0,120,330.76c2.89,2.4,5.49,5.9,8.09,10,14.59,23,30.08,40.92,48,49.73l.2.7Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path3000"
          className="carTopCls2"
          d="M105,317.25c14.67,62.91,43.87,76.42,66.77,76.43a60,60,0,0,0,7.4-.49l.3.2-.2.3a60,60,0,0,1-7.4.49c-23.2,0-52.6-13.72-67.27-76.83l.2-.3Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path3002"
          className="carTopCls2"
          d="M117.92,329.06c2.59,5.6,10.09,21.3,20.08,35.41s22.69,26.81,35.19,26.81c1,0,2-.1,2.9-.19l.6.5-.5.6a17,17,0,0,1-3.1.19c-13.3,0-26-13-36.09-27.31a235.09,235.09,0,0,1-20.18-35.61l.3-.7Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path3004"
          className="carTopCls2"
          d="M103.35,251.65l.1-.4c1.9.6,5.5,2.11,6.8,11.21,1.79,11.9,3.08,26.1,3.48,29.8s1.1,10.7,1.3,14.7v.4a5.19,5.19,0,0,1-.91,3.3,2.8,2.8,0,0,1-2.3,1.2c-1.7,0-3.6-1.1-5.49-3.11-4.1-4.2-5.8-10-7.1-16.9-2-10.3-3-29.4-3-35.6a7.16,7.16,0,0,1,.1-1.7,4,4,0,0,1,4.2-3.8,8.87,8.87,0,0,1,3,.6l-.2.3-.1.4a7.55,7.55,0,0,0-2.8-.5,3.21,3.21,0,0,0-3.5,3.1v1.7c0,6.2,1,25.2,3,35.4,1.3,6.8,3.1,12.5,6.9,16.51,1.79,1.9,3.59,2.9,5,2.8s2.4-1,2.41-3.7v-.3c-.1-3.9-.9-11-1.3-14.7s-1.69-17.8-3.48-29.7c-1.4-8.9-4.5-9.91-6.3-10.61Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path3006"
          className="carTopCls2"
          d="M108.35,261.26c1.69,11.9,3.18,26.4,3.58,30.4.3,3.1,1.2,10,1.3,13.7l-.5.6-.6-.5c-.1-3.6-.9-10.5-1.3-13.6-.4-4-1.89-18.5-3.58-30.3l.5-.7Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path3008"
          className="carTopCls2"
          d="M103.55,253.35c3.4,1.81,4.3,4.21,4.8,8l-.5.6-.6-.5c-.5-3.81-1.2-5.51-4.2-7.21l-.2-.7Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path3010"
          className="carTopCls2"
          d="M99.35,257.65a16.68,16.68,0,0,1,.5-3.1,2.23,2.23,0,0,1,2-1.7,3.8,3.8,0,0,1,1.8.6l.2.5-.5.2a3.94,3.94,0,0,0-1.4-.5c-.7,0-1,.4-1.3,1.2a12.51,12.51,0,0,0-.5,2.9l-.4.4Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path3012"
          className="carTopCls2"
          d="M110.73,307.46l.6.2.4-.3a4.31,4.31,0,0,0,.3-1.5v-.4l.5-.6.6.5v.4a4.14,4.14,0,0,1-.4,2.1,1.6,1.6,0,0,1-1.4.8l-1.1-.3-.3-.7Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path3014"
          className="carTopCls2"
          d="M105.63,301.05c1,3.1,3.5,5.81,5,6.51l.2.5-.5.2c-1.9-.8-4.4-3.61-5.5-7l.3-.5Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path3016"
          className="carTopCls2"
          d="M113.12,311c-2.7-.4-4.7-1.4-6.69-5.31-1.6-3-3.3-11.7-4.69-21.6a222.79,222.79,0,0,1-2.39-28.2v-1a3.59,3.59,0,0,1,3.6-3.6l1,.2.3.5-.5.3-.8-.1a2.86,2.86,0,0,0-2.8,2.8v1a222.86,222.86,0,0,0,2.39,28.1c1.39,9.8,3.19,18.6,4.69,21.41,2,3.7,3.59,4.4,6.09,4.9l.3.4Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path3018"
          className="carTopCls2"
          d="M112,307.76c-2.5-2.3-4.1-8.3-5.3-14.31s-1.89-12.2-2.29-14.6a159.18,159.18,0,0,1-1.69-19.4c0-2.5.1-4.4.8-5.3l.5-.1.1.5c-.4.4-.6,2.3-.6,4.8a155.56,155.56,0,0,0,1.69,19.3c.4,2.5,1.09,8.6,2.29,14.61s2.9,12,5.1,13.9v.5Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path3050"
          className="carTopCls2"
          d="M92.15,250c0,14.1,2.68,51.2,7.27,65.7,1.6,5,2.1,5.4,4.7,7.8v.5h-.5c-2.5-2.4-3.3-3.1-4.9-8.1-4.59-14.7-7.28-51.7-7.27-65.9l.4-.4Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path3052"
          className="carTopCls2"
          d="M409.79,381a63.43,63.43,0,0,1-5.8,11.79c-2.5,3.9-5.7,7.1-9.6,7.2a8.51,8.51,0,0,1-5.6-2.5l-.1-1.1,1.1-.1a7,7,0,0,0,4.6,2.1c2.9,0,5.8-2.7,8.3-6.5a63.31,63.31,0,0,0,5.6-11.49l1-.4Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path3054"
          className="carTopCls2"
          d="M417.6,378.09a22.64,22.64,0,0,1-1.91,3.7c-3.4,5.8-4.9,10.3-4.9,14.9a24.27,24.27,0,0,0,.79,5.8c1.7,6.8,6.69,23.3,7.29,25.8l.1.6-3.8,1.6-.2.1h-.2c-6.8-.4-13.3-5.61-18.19-12.11s-8.2-14.4-8.39-20.5v-.1c.1-2.8,2.8-5,4.4-6.3,1.9-1.5,5.1-3.3,8.1-3.4a4.33,4.33,0,0,1,4,2.3l-.3,1-1-.3a2.55,2.55,0,0,0-2.6-1.4c-2.2,0-5.4,1.7-7.1,3s-3.9,3.5-3.8,5.1v.1c.19,5.5,3.39,13.3,8.09,19.6s11,11.21,17,11.51v.8l-.3-.7,3.2-1.3.3.7-.8.2c-.5-2.4-5.59-19-7.29-25.8a27.58,27.58,0,0,1-.79-6.2c0-5,1.7-9.8,5.1-15.7a20.29,20.29,0,0,0,1.81-3.5l1-.4Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path3056"
          className="carTopCls2"
          d="M414.57,429.69c-.5-2.4-5.59-19-7.29-25.8a27,27,0,0,1-.89-6.8c0-4.1,1.1-8,3.7-12.8l.5-.2.2.5c-2.5,4.6-3.6,8.5-3.6,12.4a26.11,26.11,0,0,0,.89,6.6c1.7,6.8,6.69,23.3,7.29,25.8l-.3.5Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path3058"
          className="carTopCls2"
          d="M378.09,387.37c3.6.1,9.6,2.21,12.9,6.31l-.1,1.1-1.1-.1c-2.8-3.6-8.7-5.71-11.7-5.71l-.8-.8Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path3060"
          className="carTopCls2"
          d="M408.39,382.49c1.2.4,3.1,1.6,4.6,1.6l.4.4-.4.4c-1.8,0-3.8-1.2-4.8-1.6l-.2-.5Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path3062"
          className="carTopCls2"
          d="M389.49,398c2,2.2,5.4,3,9.3,3a37.1,37.1,0,0,0,8-1l.5.3-.3.5a38.32,38.32,0,0,1-8.21,1c-3.9,0-7.59-.9-9.79-3.3V398Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path3064"
          className="carTopCls2"
          d="M386.39,389.28a27.84,27.84,0,0,1,10.4-2.1,21.18,21.18,0,0,1,4.4.5l.3.5-.5.3a14.66,14.66,0,0,0-4.2-.5,25.76,25.76,0,0,0-10,2l-.5-.2Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path3066"
          className="carTopCls2"
          d="M116.38,188.16c46.92-41.08,199.73-63.71,233-64.8l.4.4-.4.4c-33.1,1-186.11,23.82-232.53,64.6h-.5Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path3160"
          className="carTopCls2"
          d="M145.7,134.37c18.6-7.29,69.71-12.87,118.11-16.75s93.6-6,100.5-6.05l.6.5-.5.5c-6.7.09-52.1,2.17-100.4,6.05s-99.51,9.56-117.81,16.65l-.7-.3Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path3162"
          className="carTopCls2"
          d="M353.61,122.06a33.16,33.16,0,0,0-5.8-8.6v-.8h.8a33.13,33.13,0,0,1,6,8.8l-.3.7Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path3164"
          className="carTopCls2"
          d="M316.55,250c0-29.3,3.33-58.2,9.74-81.4s15.71-40.59,28-46.89l1,.3-.3,1c-11.61,5.8-20.91,22.89-27.22,45.89s-9.63,51.8-9.64,81l-.8.8Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path3166"
          className="carTopCls2"
          d="M387.21,107c-73.3,2.07-189.2,8.71-222.3,17.8l-.3-.2.2-.3c33.2-9.19,149.1-15.73,222.4-17.9l.2.2Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path3168"
          className="carTopCls2"
          d="M363.71,112.17l-2.8-3.8.4-.3-.4.4a34.9,34.9,0,0,0-3.5-3.61c-2.4-2.3-6.09-6.5-7.29-8l.4-.4-.4.4-3-3.1V93h.8l3,3.1h0c1.3,1.5,5,5.7,7.29,7.9s3.5,3.61,3.5,3.61h0l2.8,3.8-.1.8Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path3170"
          className="carTopCls2"
          d="M176.21,108.59c5.4-3,14.61-8.4,18.71-11.9s5.1-4.59,8.3-6.59l.7.2-.2.7c-3.1,1.9-4,2.9-8.2,6.49s-13.41,8.9-18.81,12l-.7-.2Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path3172"
          className="carTopCls2"
          d="M347.62,94.16c-5.9.3-17.8.39-22.9.59h-1.4a96,96,0,0,1-15.5-1.61c-4.7-.8-18.7-2.71-39.9-2.91h-5.7c-23.1,0-63.9.77-76.4,2.37-13.7,1.79-19.4,3.09-30.71,10.69s-27.1,19.28-35,34.08S95.78,187.85,95.75,250.15l-.8.8-.8-.8c0-62.6,16.44-98.59,24.55-113.69s24.21-27,35.52-34.58,17.7-9.2,31.3-10.89c12.7-1.6,53.4-2.38,76.6-2.37h5.7c21.3.2,35.4,2.11,40.2,2.91a94.84,94.84,0,0,0,15.2,1.61h1.3c5.1-.2,17-.19,22.9-.59l.8.7Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path3174"
          className="carTopCls2"
          d="M350.42,96.76c-6.6.3-41.9,1.48-44.9,1.58H305a5.62,5.62,0,0,1-2-.3c-.5-.2-.9-.4-.9-.9s.5-.8,1-1a10.87,10.87,0,0,1,2.5-.5c6.6-.7,38.5-1.68,42.6-1.78l.4.4-.4.4c-4.1.2-36,1.08-42.6,1.78a7.21,7.21,0,0,0-2.3.5l-.5.3-.1.1h0l.6.3a6.27,6.27,0,0,0,1.6.2h.5c3-.1,38.3-1.28,44.9-1.58l.4.4Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path3176"
          className="carTopCls2"
          d="M121.39,168.56c-.5,1.3-2.31,6.5-4,11.3s-3.2,9.3-3.2,9.3l-.7.3-.3-.7s6-18,7.21-20.7l.7-.3Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path3178"
          className="carTopCls2"
          d="M176.11,109.79c-17.8,8.79-33.41,26.68-48,49.67-2.6,4.1-5.1,7.5-8.1,10-2.81,2.4-12,10.6-14.71,13.59h-.8v-.8c2.8-3.09,11.91-11.29,14.81-13.69s5.3-5.7,7.8-9.7c14.61-23.09,30.32-41.08,48.42-50.08l.7.21Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path3180"
          className="carTopCls2"
          d="M104.68,182.75C119.41,119.76,148.81,106,172,106a57.34,57.34,0,0,1,7.4.51l.2.3-.3.2a58.63,58.63,0,0,0-7.4-.51c-23,0-52.1,13.48-66.83,76.37l-.3.2Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path3182"
          className="carTopCls2"
          d="M117,170.66a234.18,234.18,0,0,1,20.21-35.59c10.21-14.2,22.91-27.19,36.11-27.19a29.34,29.34,0,0,1,3.1.21l.5.6-.6.4a27.49,27.49,0,0,0-2.9-.21c-12.5,0-25.2,12.59-35.21,26.79a241.08,241.08,0,0,0-20.11,35.39l-.71.3Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path3184"
          className="carTopCls2"
          d="M103.35,248.55l-.1-.4c1.8-.7,4.9-1.59,6.31-10.49,1.8-11.9,3.11-26,3.51-29.7s1.1-10.7,1.31-14.7V193c0-2.7-1-3.7-2.4-3.7s-3.2,1-5,2.8c-3.91,4-5.61,9.69-6.91,16.49-2,10.2-3,29.3-3,35.4v1.6c.2,2,1.3,3,3.5,3.1a11.07,11.07,0,0,0,2.8-.5v.4l.1.4a8.87,8.87,0,0,1-3,.6,4,4,0,0,1-4.2-3.8c0-.4-.1-.9-.1-1.7,0-6.2,1-25.2,3-35.6,1.3-6.9,3.1-12.7,7.11-16.9,1.9-2,3.8-3.09,5.5-3.09a2.68,2.68,0,0,1,2.3,1.2,5.39,5.39,0,0,1,.9,3.3v.4c-.11,4-.91,11-1.31,14.7s-1.71,17.8-3.51,29.8c-1.31,9.1-4.91,10.49-6.81,11.19Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path3186"
          className="carTopCls2"
          d="M107.26,238.76c1.7-11.9,3.21-26.4,3.61-30.3.3-3.2,1.2-10,1.3-13.6l.6-.5.5.6c-.1,3.7-.9,10.6-1.3,13.7-.4,4-1.91,18.5-3.61,30.4l-.6.5Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path3188"
          className="carTopCls2"
          d="M103.05,246c3.1-1.7,3.7-3.4,4.21-7.19l.6-.5.5.6c-.51,3.8-1.41,6.2-4.81,8l-.7-.2Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path3190"
          className="carTopCls2"
          d="M100.05,242.45a12.51,12.51,0,0,0,.5,2.9c.3.8.7,1.2,1.3,1.2a2.61,2.61,0,0,0,1.4-.5l.5.2-.2.5a3.8,3.8,0,0,1-1.8.6,2.13,2.13,0,0,1-2-1.7,15.44,15.44,0,0,1-.5-3.1l.4-.4Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path3192"
          className="carTopCls2"
          d="M110.38,191.76l1.1-.3a1.6,1.6,0,0,1,1.4.8,4,4,0,0,1,.39,2.1v.4l-.6.5-.5-.6v-.4a2.32,2.32,0,0,0-.29-1.5l-.4-.3-.6.2-.7-.3Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path3194"
          className="carTopCls2"
          d="M105,198.85a12.37,12.37,0,0,1,5.51-7l.5.2-.2.5c-1.6.7-4.11,3.39-5,6.49l-.5.3Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path3196"
          className="carTopCls2"
          d="M113.28,190c-2.6.4-4.1,1.1-6.11,4.8-1.5,2.79-3.3,11.49-4.7,21.39a223.79,223.79,0,0,0-2.42,28.1v1c0,1.6,1,3,2.4,3l.7-.1.5.3-.3.5-.9.1c-2,0-3.2-1.9-3.2-3.8v-1a225.23,225.23,0,0,1,2.42-28.2c1.4-9.9,3.2-18.6,4.7-21.6,2-3.89,4-4.89,6.71-5.29l.4.3Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path3198"
          className="carTopCls2"
          d="M112.58,193c-2.21,2-3.91,7.9-5.11,13.9s-1.91,12.09-2.31,14.59a184.78,184.78,0,0,0-1.71,19.3c0,2.5.2,4.4.6,4.8l-.1.5-.5-.1c-.6-.9-.8-2.7-.8-5.3a159.06,159.06,0,0,1,1.71-19.4c.4-2.5,1.11-8.6,2.31-14.6s2.9-12,5.31-14.29h.5Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path3230"
          className="carTopCls2"
          d="M91.35,250.25c0-14.2,2.72-51.2,7.33-65.9,1.6-5,2.3-5.7,4.9-8.1h.5v.5c-2.5,2.4-3.1,2.8-4.7,7.8-4.61,14.5-7.32,51.6-7.33,65.7l-.4.4Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path3232"
          className="carTopCls2"
          d="M408.71,120.39a63.44,63.44,0,0,0-5.6-11.51c-2.4-3.8-5.4-6.5-8.3-6.5a7,7,0,0,0-4.6,2.1l-1.1-.1.1-1.1a8.55,8.55,0,0,1,5.61-2.5c3.9,0,7,3.3,9.59,7.2a75.33,75.33,0,0,1,5.8,11.81l-.4,1Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path3234"
          className="carTopCls2"
          d="M415.91,122.09l-.3-.7-.1-.2a5.8,5.8,0,0,1-.8-1.2c-3.4-5.9-5.1-10.7-5.1-15.7a27.63,27.63,0,0,1,.81-6.2c1.7-6.9,6.81-23.4,7.31-25.8l.8.2-.3.7-3.2-1.3.3-.7V72c-6,.3-12.3,5.19-17,11.49s-7.9,14.1-8.11,19.6v.1c-.1,1.6,2.2,3.9,3.8,5.1s4.9,3.1,7.1,3a2.71,2.71,0,0,0,2.6-1.4l1-.3.3,1a4.31,4.31,0,0,1-4,2.3c-2.9,0-6.2-1.9-8.1-3.4-1.7-1.3-4.4-3.5-4.4-6.3v-.1c.21-6,3.51-13.9,8.41-20.5s11.31-11.79,18.21-12.09h.2l3.3,1.4.6.2-.1.6c-.6,2.5-5.61,19-7.31,25.8a24.62,24.62,0,0,0-.81,5.9c0,4.6,1.5,9.1,4.9,14.9.6.9.6,1,.7,1.1l.3.4.4.8-.4,1Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path3236"
          className="carTopCls2"
          d="M415.63,71.39c-.6,2.5-5.61,19-7.31,25.8a26,26,0,0,0-.91,6.6c0,3.9,1.1,7.8,3.6,12.4l-.2.5-.5-.2c-2.5-4.7-3.7-8.7-3.7-12.8a26.78,26.78,0,0,1,.91-6.7c1.7-6.9,6.81-23.4,7.31-25.8l.5-.3Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path3238"
          className="carTopCls2"
          d="M378.91,111.37c3.2-.1,8.4-1.59,11.2-5.19l1.1-.1.1,1.1c-3.3,4.2-8.8,5.69-12.4,5.79l-.8-.8Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path3240"
          className="carTopCls2"
          d="M408.51,117.59c1.1-.4,3.1-1.6,4.8-1.6l.4.4-.4.4c-1.4,0-3.4,1.1-4.6,1.6l-.5-.2Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path3242"
          className="carTopCls2"
          d="M389.22,102.28c2.2-2.4,5.9-3.3,9.8-3.3a37.69,37.69,0,0,1,8.2,1l.3.5-.5.3a35.92,35.92,0,0,0-8-1c-3.8,0-7.3.9-9.31,3h-.5Z"
          transform="translate(-91.04 -70.25)"
        />
        <path
          id="path3244"
          className="carTopCls2"
          d="M387,110.78a28.3,28.3,0,0,0,10,2,14.66,14.66,0,0,0,4.2-.5l.5.3-.3.5a16.07,16.07,0,0,1-4.4.5,28.32,28.32,0,0,1-10.4-2.1l-.2-.5Z"
          transform="translate(-91.04 -70.25)"
        />
      </g>
    </g>
  );
};

export default CarTopViewVector;
