import React from "react";
import Popup from 'reactjs-popup';
import './PlotRenderer.css'

export default class PlotRenderer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentColumnIndex: props.index !== undefined ? props.index : 0,
    };
  }

  componentDidMount() {
    document.addEventListener("keydown", this.handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  handleKeyDown = (event) => {
    if (event.key === "ArrowRight" || event.key === "ArrowLeft") {
      this.showNextImage();
    }
  };

  showNextImage = () => {
    const { currentColumnIndex } = this.state;
    const { data } = this.props;

    if (!data || typeof data !== "object") {
      // Data is not available or not an object, return early
      return;
    }

    // Assuming each image is in a separate column and the image URLs are in an array
    const imageColumns = Object.keys(data).filter(
      (col) =>
        data[col] &&
        typeof data[col] === "string" &&
        data[col].includes("image.png")
    );

    if (imageColumns.length === 0) {
      // No image columns found, return early to prevent error
      return;
    }

    // Increment the index to show the next image in the adjacent column
    const nextColumnIndex = (currentColumnIndex + 1) % imageColumns.length;

    this.setState({ currentColumnIndex: nextColumnIndex });
  };

  render() {
    const { column, data } = this.props;
    const { currentColumnIndex } = this.state;

    if (!data || typeof data !== "object") {
      // Data is not available or not an object, return early
      return;
    }

    // Assuming each image is in a separate column and the image URLs are in an array
    const imageColumns = Object.keys(data).filter(
      (col) =>
        data[col] &&
        typeof data[col] === "string" &&
        data[col].includes("image.png")
    );

    if (!imageColumns || imageColumns.length === 0) {
      // No image columns found, render a fallback content or null
      return null;
    }

    // Get the URL of the current image using the currentColumnIndex
    const currentImageURL = data[imageColumns[currentColumnIndex]];

    if (!currentImageURL) {
      return null;
    }

    const isPDF = currentImageURL.endsWith(".pdf");
    if (isPDF) {
      return (
        <a href={currentImageURL} download>
          PDF
        </a>
      );
    }

    return (
      <div>
        <Popup
          trigger={
            <img
              className="small"
              src={this.props.value}
              alt={this.props.name}
              style={{ maxHeight: "100%", maxWidth: "100%", marginTop: 10, marginBottom: 0 }}
            />
          }
          modal
          nested
          position="center center"
        >
          {close => (
            <div className="image-modal">
              <button className="close" onClick={close}>
                &times;
              </button>
              <div className="content">
                <img
                  className="plot"
                  src={currentImageURL}
                  alt={column.colId}
                  style={{ maxWidth: "100%", maxHeight: "100%", objectFit: "contain" }}
                />
              </div>
              {/* Removed the button for Next Image */}
            </div>
          )}
        </Popup>
      </div>
    );
  }
}
