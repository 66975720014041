import React, {useState, useRef, useCallback, useEffect} from 'react';
import 'ag-grid-enterprise';
import {AgGridReact} from 'ag-grid-react';
import { LicenseManager } from 'ag-grid-enterprise';

import {
  Button, Card, InputNumber, Select, Col, Modal, notification, Row, Typography, Input, Spin, Switch, Divider
} from 'antd';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import Icon from "@ant-design/icons";
import {FaShapes} from 'react-icons/fa';
import axiosInstance from "services/axios";
import PlotRenderer from "../PlotRenderer/PlotRenderer";
import {dateTimeFormatter, EventURLRenderer, ReportURLRenderer} from "../CellHelpers/CellHelpers";
import MultiImageRenderer from "../MultiImageRenderer/MultiImageRenderer";
import TagRenderer from "../TagRenderer/TagRenderer";
import TagValues from "../TagValues/TagValues";
import CarPartRenderer from "../CarPartRenderer/CarPartRenderer";
import DetailsCard from "../DetailsCard/DetailsCard";

LicenseManager.setLicenseKey("CompanyName=carValoo GmbH,LicensedGroup=carValoo,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-038534,SupportServicesEnd=21_February_2024_[v2]_MTcwODQ3MzYwMDAwMA==569a60f2ef339ea47d5835a5fc039c3f")

const {Search} = Input;
const {Text} = Typography;


const SimilarityTool = (props) => {

  const [inputNumber, setInputNumber] = useState('10');
  const [inputDatabase, setInputDatabase] = useState('anomaly-similarity');
  const [loading, setLoading] = useState(false);

  const [selectedId, setSelectedId] = useState(null);
  const [detailCardOpen, setDetailCardOpen] = useState(false);
  const handleDetailsCardChange = useCallback((data) => setDetailCardOpen(data), []);
  const gridRef = useRef();

  let columnState = [];

  // Function to update the column state
  function updateColumnState() {
    // Get the current column state
    let columnState = gridRef.current.columnApi.getColumnState();

        // Set the sort key to null for all columns
        columnState = columnState.map(column => ({
            ...column,
            sort: null,
            sortIndex: null // If you want to reset the sort index as well
        }));

    // Store the updated column state in the session storage
    localStorage.setItem("carvalooSimilarityToolColumns", JSON.stringify(columnState));
  }

  const onResetColumns = useCallback(() => {
    gridRef.current.columnApi.resetColumnState();
    console.log('column state reset');
  }, []);

  useEffect(() => {
    document.title = 'Similarity Tool';
  }, []);

  const handleRowSelection = () => {
    const selectedNodes = gridRef.current.api.getSelectedNodes();
    if (selectedNodes.length) {
      setSelectedId(selectedNodes[0].data.id);
    } else {
      setSelectedId(null);
    }
  };

  const onCellValueChanged = (params) => {
    console.log(params);

    let id = params.data.id;
    let UAID = params.data.UAID

    let col = params.column.getColId();
    if (params.oldValue !== params.newValue) {
      let data = {};
      data['UAID'] = UAID
      data[col] = params.newValue;

      axiosInstance.patch('/api/damage_tool/' + id, data)
        .then(response => {
          //params.success(response.values);
          console.log('Update successfull');
          console.log(response);
          console.log(params);
          notification['success']({
              message: 'Updated entry of Event ' + UAID,
              duration: 2.5,
              maxCount: 15
            }
          );
        })
        .catch(error => {
          console.log(error);
          notification['error']({message: 'Error updating', description: error.message});
        })
    }
  }

  const onResetAllFilters = () => {
    gridRef.current.api.setFilterModel(null);
  }

  function getSimilarityData(payload, showLoading) {
    // Send request to python backend

    if (showLoading) {
      setLoading(true);
    }
    axiosInstance.post('/api/similarity', payload)
      .then(res => {
        if (showLoading) {
          setLoading(false);
        }
        // Set row data based on JSON response
        gridRef.current.api.setRowData(res.data.data);

      })
      .catch(error => {
        if (showLoading) {
          setLoading(false);
        }
        console.log(error);
        notification['error']({
            message: error.message,
            description: error.response.data.message,
            duration: 10,
          }
        );
      })
  }

  const onSearchSimilarity = (value) => {
    console.log(value);

    if (value.includes("UAID")) {
      // Send request to python backend
      const payload = {
        uaid: value,
        n_return: inputNumber,
        database: inputDatabase,
      };

      // Manual user trigger
      getSimilarityData(payload, true)

      // // Automatic refresh trigger
      // setTimeout(async () => {
      //   getSimilarityData(payload, false)
      // }, 20000);

    } else {
      notification['error']({
          message: 'Not a valid UAID',
          duration: 5
        }
      );
    }
  }

  const onChangeInputNumber = (value) => {
    setInputNumber(value);
  }

  const onChangeDatabase = (value) => {
    setInputDatabase(value);
  }

  let emptyRowData = [
    {uaid: 'empty'},
  ];

  const gridOptions = {
    rowData: emptyRowData,
    rowSelection: 'multiple',
    suppressRowClickSelection: false,
    enableRangeSelection: 'true',
    columnDefs: [
      {
        field: 'id',
        hide: true,
        width: 75,
        headerName: 'Id',
        floatingFilter: false,
      },
      {
        field: 'UAID',
        headerTooltip: "Unique Anomaly ID",
        width: 240,
        headerName: 'UAID',
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        suppressFiltersToolPanel: true,
        cellRenderer: ReportURLRenderer,
        suppressMenu: false,
      },
      {
        field: 'similarity',
        width: 120,
        headerTooltip: "Cosine Distance Similarity",
        headerName: 'Similarity',
        floatingFilter: true,
        filter: 'agNumberColumnFilter',
      },
      {
        field: 'event_id',
        headerTooltip: "Event ID",
        headerName: 'Event',
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        cellRenderer: EventURLRenderer,
        suppressFiltersToolPanel: true,
      },
      {
        field: 'customer',
        headerName: 'Customer',
        hide: true,
        floatingFilter: true,
        filter: 'agSetColumnFilter',
      },
      {
        field: 'box_id',
        headerName: 'Box',
        hide: true,
        floatingFilter: true,
        filter: 'agNumberColumnFilter',
      },
      {
        field: 'vehicle_type_installation',
        hide: true,
        headerName: 'Installation',
        floatingFilter: true,
        filter: 'agSetColumnFilter',
      },
      {
        field: 'vehicle_category',
        headerName: 'Category',
        headerTooltip: "Vehicle Category - low detail",
        hide: true,
        floatingFilter: true,
        filter: 'agSetColumnFilter',
      },
      {
        field: 'vehicle_class',
        headerName: 'Class',
        headerTooltip: "Vehicle Class - medium level",
        hide: true,
        floatingFilter: true,
        filter: 'agSetColumnFilter',
      },
      {
        field: 'vehicle_type',
        headerName: 'Type',
        headerTooltip: "Vehicle Type - high level",
        hide: true,
        floatingFilter: true,
        filter: 'agSetColumnFilter',
      },
      {
        field: 'date',
        width: 155,
        headerTooltip: "Filter by day and sort by datetime",
        hide: true,
        headerName: 'Date',
        floatingFilter: true,
        valueFormatter: dateTimeFormatter,
        filter: 'agDateColumnFilter',
        filterParams: {
          inRangeInclusive: true,
          suppressAndOrCondition: true,
        }
      },
      {
        field: 'velocity',
        headerName: 'Velocity',
        headerTooltip: "Velocity based on event GPS coordinates",
        hide: true,
        floatingFilter: true,
        filter: 'agNumberColumnFilter',
      },
      {
        field: 'driving_situation',
        headerName: 'Model Situation',
        headerTooltip: "Model predicted driving situation",
        suppressFiltersToolPanel: true,
        hide: true,
        floatingFilter: true,
        filter: 'agSetColumnFilter',
      },
      {
        field: 'alert_level',
        headerName: 'Score',
        headerTooltip: "Text alert level for anomaly score",
        hide: true,
        suppressFiltersToolPanel: true,
        floatingFilter: true,
        filter: 'agSetColumnFilter',
      },
      {
        field: 'prediction_score',
        headerName: 'Max() Score',
        headerTooltip: "Model predicted anomaly score - maximum score",
        suppressFiltersToolPanel: true,
        width: 90,
        floatingFilter: true,
        hide: true,
        filter: 'agNumberColumnFilter',
      },
      {
        field: 'lfc_tree_6f',
        headerName: 'LFC6f Score',
        headerTooltip: "Model predicted anomaly score - 6f or 6g model",
        suppressFiltersToolPanel: true,
        width: 90,
        floatingFilter: true,
        filter: 'agNumberColumnFilter',
      },
      {
        field: 'lfc_tree_8c',
        headerName: 'LFC8c Score',
        headerTooltip: "Model predicted anomaly score - 8c or 8d model",
        suppressFiltersToolPanel: true,
        width: 90,
        floatingFilter: true,
        filter: 'agNumberColumnFilter',
      },
      {
        field: 'wavenet_1',
        headerName: 'Wavenet Score',
        headerTooltip: "Model predicted anomaly score - wavenet_1 model",
        suppressFiltersToolPanel: true,
        width: 100,
        floatingFilter: true,
        filter: 'agNumberColumnFilter',
      },
      {
        field: 'event_severity',
        width: 110,
        suppressFiltersToolPanel: true,
        headerName: 'Severity',
        headerTooltip: "Text severity level for event",
        floatingFilter: true,
        filter: 'agSetColumnFilter',
      },
      {
        field: 'severity',
        headerTooltip: "Model text severity level for anomaly",
        hide: true,
        suppressFiltersToolPanel: true,
        headerName: 'Model Severity',
        floatingFilter: true,
        filter: 'agSetColumnFilter',
      },
      {
        field: 'event_direction_str',
        headerName: 'Model Direction',
        width: 125,
        hide: true,
        headerTooltip: "Model predicted event direction",
        suppressFiltersToolPanel: true,
        floatingFilter: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: ['vorne', 'vorne rechts', 'vorne links', 'hinten', 'hinten rechts', 'hinten links',
            'rechts vorne', 'rechts', 'rechts hinten', 'links vorne', 'links', 'links hinten'],
        },
      },
      {
        field: 'underbody_impact_str',
        headerName: 'Underbody Impact',
        hide: true,
        width: 125,
        headerTooltip: "Model predicted underbody impact",
        suppressFiltersToolPanel: true,
        floatingFilter: true,
        filter: 'agSetColumnFilter',
        wrapText: true,
      },
      {
        field: 'underbody_impact',
        headerName: 'Underbody Impact*',
        hide: true,
        width: 125,
        suppressFiltersToolPanel: true,
        floatingFilter: true,
        filter: 'agNumberColumnFilter',
      },
      {
        field: 'damage_tag',
        suppressFiltersToolPanel: true,
        width: 140,
        headerName: 'Damage',
        headerTooltip: "Description to tag the type of damage that occurred",
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        cellRenderer: TagRenderer,
        cellRendererParams: {
          columnValues: TagValues.distinctDamageValues,
        },
        wrapText: true,
        autoHeight: true,
      },
      {
        field: 'carPart_id',
        headerName: 'Car Part',
        width: 120,
        headerTooltip: "Visualized car part ids for damage",
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        wrapText: true,
        cellRenderer: CarPartRenderer,
        autoHeight: true,
      },
      {
        field: 'pattern',
        suppressFiltersToolPanel: true,
        width: 140,
        headerName: 'Pattern',
        headerTooltip: "Description to tag the type of signal that occurred",
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        cellRenderer: TagRenderer,
        cellRendererParams: {
          columnValues: TagValues.distinctPatternValues,
        },
        wrapText: true,
        autoHeight: true,
      },
      {
        field: 'comment',
        width: 150,
        suppressFiltersToolPanel: true,
        headerName: 'Comment',
        headerTooltip: "Free flowing comment - no structure",
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        editable: true,
      },
      {
        field: 'matching_confidence',
        headerName: 'Confidence',
        headerTooltip: "Matching confidence: 1-4 low-high",
        hide: true,
        suppressFiltersToolPanel: true,
        floatingFilter: true,
        filter: 'agSetColumnFilter',
        editable: true,
      },
      {
        field: 'UAID_image',
        headerName: 'UAID Image',
        width: 200,
        cellRenderer: PlotRenderer,
        cellRendererParams: {'index': 0},
        autoHeight: true,
        wrapText: true,
        sortable: false,
      },
      {
        field: 'trip_image',
        headerName: 'Trip Image',
        hide: true,
        cellRenderer: PlotRenderer,
        cellRendererParams: {'index': 1},
        autoHeight: true,
        wrapText: true,
        sortable: false,
      },
      {
        field: 'event_image',
        headerName: 'Event Image',
        width: 200,
        cellRenderer: MultiImageRenderer,
        autoHeight: true,
        wrapText: true,
        sortable: false,
      },
      {
        field: 'modified_datetime',
        headerName: 'Modified UTC',
        width: 155,
        hide: true,
        headerTooltip: "Filter by day and sort by datetime",
        floatingFilter: true,
        valueFormatter: dateTimeFormatter,
        filter: 'agDateColumnFilter',
        filterParams: {
          inRangeInclusive: true,
          suppressAndOrCondition: true,
        }
      },
    ],
    defaultColDef: {
      width: 100,
      sortable: true,
      filter: false,
      resizable: true, //minWidth: 100,
      editable: false, //cellEditor: PopupCellEditor
      suppressMenu: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
    },
    sideBar: {
      position: 'right',
      defaultToolPanel: '',
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          minWidth: 225,
          maxWidth: 225,
          width: 225,
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
            suppressPivotMode: true
          }
        },
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
          minWidth: 180,
          maxWidth: 400,
          width: 250
        }
      ],
    },
    onColumnVisible: (event) => {
      updateColumnState()
    },
    onColumnResized: (event) => {
      updateColumnState()
    },
    onColumnMoved: (event) => {
      updateColumnState()
    },
    onColumnPinned: (event) => {
      updateColumnState()
    },
    onRowSelected: (event) => {
      handleRowSelection()
    },
    onRowEditingStarted: (event) => {
      console.log('never called - not doing row editing');
    },
    onRowEditingStopped: (event) => {
      console.log('never called - not doing row editing');
    },
    onCellEditingStarted: (event) => {
      console.log('cellEditingStarted');
    },
    onCellEditingStopped: (event) => {
      console.log('cellEditingStopped');
    },
    onNewColumnsLoaded: (event) => {
      console.log('onNewColumnsLoaded');
      console.log(event);
    },
    onDisplayedColumnsChanged: (event) => {
      console.log('onDisplayedColumnsChanged ');
      console.log(event);
    },
    onCellValueChanged: onCellValueChanged,
    onGridReady: function () {
      // Step 1: Retrieve the column state JSON string from session storage
      const columnStateString = localStorage.getItem("carvalooSimilarityToolColumns");
      if (columnStateString) {
        // Step 2: Convert the JSON string back to an array
        const columnState = JSON.parse(columnStateString);

        // Step 3: Apply the column state to ag-Grid
        gridRef.current.columnApi.applyColumnState({ state: columnState});
      }

      console.log('grid ready');
    },
  }


  return (<>
      <Modal
        open={loading}
        closable={false}
        footer={null}
        centered={true}
        mask={true}
        maskClosable={false}
        bodyStyle={{ backdropFilter: 'blur(3px)' }}
      >
        <Spin size="large" />
      </Modal>
      <Card style={{
        width: '100%',
        height: '100%',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
        border: '1px solid #e8e8e8'
      }} bodyStyle={{padding: '5px', display: 'flex', flexDirection: "column", flex: "1 1 auto"}}>
        <Row gutter={16} style={{marginBottom: "8px"}}>
          <Col span={24}>
            <Card bodyStyle={{padding: "4px"}}>
              <Row gutter={[0, 0]}>
                <Col>
                  <Button onClick={onResetAllFilters}>Reset all filters</Button>
                </Col>
                <Col>
                  <Button onClick={onResetColumns}>Reset columns</Button>
                </Col>
                <Divider type={'vertical'}></Divider>
                <Col>
                  <Search
                    placeholder="Input search UAID"
                    allowClear
                    enterButton="Search"
                    size="default"
                    onSearch={onSearchSimilarity}
                    style={{ width: "360px" }}
                  />
                </Col>
                <Col>
                  <span title="Return Count">
                   <InputNumber min={1} max={100} defaultValue={10} onChange={onChangeInputNumber}/>
                  </span>
                </Col>
                {/*<Col>*/}
                {/*  <span title="Database">*/}
                {/*    <Select*/}
                {/*      defaultValue="anomaly-similarity"*/}
                {/*      style={{ width: 120 }}*/}
                {/*      onChange={onChangeDatabase}*/}
                {/*      options={[*/}
                {/*        {*/}
                {/*          value: 'anomaly-similarity',*/}
                {/*          label: 'default',*/}
                {/*        },*/}
                {/*        {*/}
                {/*          value: 'anomaly-similarity-demo',*/}
                {/*          label: 'demo',*/}
                {/*        },*/}
                {/*      ]}*/}
                {/*    />*/}
                {/*  </span>*/}
                {/*</Col>*/}
                <Col style={{ marginLeft: 'auto' }}>
                  <Switch
                    style={{marginTop: '5px'}}
                    onChange={handleDetailsCardChange}
                    checkedChildren="details"
                    unCheckedChildren="details"
                    checked={detailCardOpen}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row className="ag-theme-alpine" style={{flex: "1 1 auto"}}>
          <Col style={{flex: "1 1 auto"}}>
            <AgGridReact
              ref={gridRef}
              gridOptions={gridOptions}
            />
          </Col>
          {(selectedId) && (detailCardOpen) &&
            <Col>
              <DetailsCard id={selectedId}/>
            </Col>}
        </Row>
      </Card>
    </>

  );
}

export default SimilarityTool;
