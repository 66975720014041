import React, {useState, useRef, useCallback, useEffect} from 'react';

import {
    Button,
    Card,
    Descriptions,
    Select,
    Col,
    Modal,
    notification,
    Row,
    Typography,
    Input,
    Spin,
    Switch,
    Table,
    Divider,
    Alert
} from 'antd';

import Icon from "@ant-design/icons";
import axiosInstance from "services/axios";
import MapComponent from "../MapComponent/MapComponent";
import {FaFile, FaGlobe} from "react-icons/fa";

const {Title} = Typography;


const AnomalyMapCard = (props) => {

    const [anomalySearch, setAnomalySearch] = useState(null);
    const [showMap, setShowMap] = useState(false);
    const [filterValues, setFilterValues] = useState([]);
    const [ignoreActive, setIgnoreActive] = useState(false);
    const [lastGPSTime, setLastGPSTime] = useState(null);

    const UAID = props.UAID

    const onSearchUAID = () => {
        if (UAID && props.customer) {
            if (UAID.includes("UAID")) {
                const request = {UAID: UAID, customer: props.customer,}

                console.log("Request data with params: " + JSON.stringify(request, null, 1));
                axiosInstance.post('/api/map_tool/info', request)
                    .then(res => {
                        if (res.data.message.includes("WARNING")) {

                            if (res.data.details === 'Showing last valid GPS point') {
                                setLastGPSTime(res.data.anomaly.ts)
                                setAnomalySearch(res.data.anomaly)
                                setShowMap(true)
                            }

                            console.log('No map data')
                            // notification['warning']({
                            //         message: res.data.message,
                            //         duration: 5,
                            //         maxCount: 15
                            //     }
                            // );
                        } else {
                            setAnomalySearch(res.data.anomaly)
                            setShowMap(true)
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        notification['error']({message: 'Error in get_anomaly', description: error.message});
                    })

            } else {
                notification['error']({
                        message: 'Not a valid UAID',
                        duration: 5
                    }
                );
            }
        }
    }

    useEffect(() => {
        setShowMap(false)
        onSearchUAID()
    }, [UAID, props.customer, props.reload]);

    const handleToggleIgnore = () => {
        setIgnoreActive(!ignoreActive);
        if (!ignoreActive) {
            setFilterValues(
                [{
                    field: "_id",
                    condition: "equals",
                    value: UAID
                }]
            )
        } else {
            setFilterValues([])
        }
    };

    function generateTrackingLink(anomalyInfo) {
        if (anomalyInfo) {

            // Parse the input timestamp as a Date object
            const date = new Date(anomalyInfo.utc_timestamp + '+00:00');

            // Calculate the 'from' and 'to' timestamps
            const fromDate = new Date(date.getTime() - 60 * 60 * 1000); // 1 hour before
            const toDate = new Date(date.getTime() + 60 * 60 * 1000);   // 1 hour after

            // Format the dates as ISO strings and then URL-encode them
            const fromISO = encodeURIComponent(fromDate.toISOString());
            const toISO = encodeURIComponent(toDate.toISOString());

            // Generate the link
            return `https://incident.carvaloo.com/fleet_tracking?organization=${anomalyInfo.customer_id}&search=${anomalyInfo.box_id}` +
                `&stationary=false&tracking=true&from=${fromISO}&to=${toISO}`;

        } else {
            return ''
        }

    }

    return (
        <>
            <Card
                style={{
                    width: '100%',
                    height: 430,
                    boxSizing: 'border-box',
                    display: 'flex',
                    flexDirection: 'column',
                    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                    border: '1px solid #e8e8e8',
                }}
                title={'Map'}
                bodyStyle={{
                    padding: '5px', display: 'flex', flexDirection: "column", flex: "1 1 auto"
                }}
            >
                <Row style={{marginBottom: 8, display: 'flex', alignItems: 'center'}}>
                    <Col style={{marginRight: 8, marginLeft: 8}}>
                        <div style={{marginTop: 6}}>
                            Hide other Anomalies
                        </div>
                    </Col>
                    <Col>
                        <Switch
                            style={{marginTop: 6}}
                            onChange={handleToggleIgnore}
                            checkedChildren="yes"
                            unCheckedChildren="no"
                            checked={ignoreActive}
                        />
                    </Col>
                    <Col style={{marginLeft: 'auto'}}>
                        <Button
                            type="default"
                            href={`/map_tool?search=${UAID}`}
                            target="_blank"
                            icon={<FaGlobe/>
                            }>
                            {'\xa0'}Map Tool
                        </Button>
                        <Button
                            type="default"
                            href={generateTrackingLink(props.anomalyInfo)}
                            target="_blank"
                            icon={<FaGlobe/>}
                        >
                            {'\xa0'}Tracking Tool
                        </Button>
                    </Col>
                </Row>
                {showMap ? (<>
                        {lastGPSTime &&
                            <div style={{position: "absolute", zIndex: 5, top: 370, left: 20}}>
                                <Alert message={`Showing last valid GPS point location - ${lastGPSTime}`} type="error"/>
                            </div>
                        }
                        <MapComponent
                            anomalySearch={anomalySearch}
                            isAnomaliesActive={true}
                            isClustererActive={false}
                            isPOIActive={false}
                            mapHeight={'320px'}
                            noAutocomplete={true}
                            filterValues={filterValues}
                            miniTooltip={true}
                        />
                    </>
                ) : (
                    <div style={{height: '320px', backgroundColor: '#eeeeee'}}>
                        <div style={{marginLeft: 10, marginTop: 10}}>
                            UAID not found in Map Database
                        </div>
                    </div>
                )}
            </Card>
        </>
    )
        ;
}

export default AnomalyMapCard;
