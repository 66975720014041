import React, {useState, useRef, useCallback, useEffect} from 'react';

import {
    Button, Card, InputNumber, Select, Col, Modal, notification, Row, Typography, Input, Spin, Switch, Table, Image
} from 'antd';

import Icon from "@ant-design/icons";
import {FaInfoCircle} from 'react-icons/fa';
import axiosInstance from "services/axios";
import ImageRenderer from "../ImageRenderer/ImageRenderer";
import MultiImageRenderer from "../MultiImageRenderer/MultiImageRenderer";

const {Search} = Input;
const {Title} = Typography;


const AnomalyPlotCard = (props) => {

    const maxImagesToShow = 9;
    const [data, setData] = useState(null);
    const [alertImageError, setAlertImageError] = useState(false);
    const [directionImageError, setDirectionImageError] = useState(false);

    const UAID = props.UAID

    const getDashboardValues = () => {
        axiosInstance.post(
            '/api/anomaly_tool/plots',
            {
                UAID: UAID,
            })
            .then(res => {
                console.log(res.data)
                setData(res.data)
            })
            .catch(error => {
                console.log(error);
                setData(null)
                notification['error']({
                    message: 'Error in get AnomalyPred',
                    description: (
                        <>
                            {error.message + ':'}<br/>
                            {error.response && error.response.data && error.response.data.message}
                        </>
                    ),
                });
            })
    }

    useEffect(() => {
        if (UAID !== null) {
            getDashboardValues();
        } else {
            setData(null)
        }
    }, [UAID, props.reload]);

    return (<>
            {/*<Modal*/}
            {/*  open={loading}*/}
            {/*  closable={false}*/}
            {/*  footer={null}*/}
            {/*  centered={true}*/}
            {/*  mask={true}*/}
            {/*  maskClosable={false}*/}
            {/*  bodyStyle={{ backdropFilter: 'blur(3px)' }}*/}
            {/*>*/}
            {/*  <Spin size="large" />*/}
            {/*</Modal>*/}
            <Card
                style={{
                    width: '100%',
                    height: '100%',
                    boxSizing: 'border-box',
                    display: 'flex',
                    flexDirection: 'column',
                    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                    border: '1px solid #e8e8e8'
                }}
                title={'Images'}
                bodyStyle={{padding: '5px', display: 'flex', flexDirection: "column", flex: "1 1 auto"}}>
                <Row>
                    <Col span={12}>
                        <div style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}>
                            <Image
                                style={{height: "200px"}}
                                src={data?.uaid_image}
                                name={'uaid_image'}
                            />
                        </div>
                    </Col>
                    <Col span={12}>
                        <div style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}>
                            <Image
                                style={{height: "200px"}}
                                src={data?.trip_image}
                                name={'trip_image'}
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <div style={{
                            height: "160px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}>
                            {!alertImageError ? (
                                <Image
                                    src={data?.alert_image}
                                    name={'alert_image'}
                                    style={{height: 120}}
                                    onError={() => setAlertImageError(true)}
                                />
                            ) : (
                                <div style={{marginLeft: 32}}>
                                        No alert image pre-generated
                                </div>
                            )}
                        </div>
                    </Col>
                    <Col span={8}>
                        <div style={{
                            height: "160px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}>
                            {!directionImageError ? (
                                <Image
                                    src={data?.direction_image}
                                    name={'direction_image'}
                                    style={{height: 150}}
                                    onError={() => setDirectionImageError(true)}
                                />
                            ) : (
                                <div style={{marginLeft: 4}}>
                                        No direction image pre-generated
                                </div>
                            )}
                        </div>
                    </Col>
                    {data?.event_image &&
                        <Col span={8}>
                            <div style={{
                                height: "160px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginRight: 30
                            }}>
                                {data?.event_image.length === 0 &&
                                    <div>
                                        No event images available
                                    </div>
                                }
                                <Image.PreviewGroup
                                    preview={{
                                        onChange: (current, prev) => console.log(`current index: ${current}, prev index: ${prev}`),
                                    }}
                                >
                                    <div style={{
                                        display: 'grid',
                                        gridTemplateColumns: 'repeat(auto-fill, minmax(30px, 1fr))',
                                        gap: '8px', // Adjust the gap between images as needed
                                    }}>
                                        {data?.event_image.slice(0, maxImagesToShow).map((src, index) => (
                                            <Image
                                                key={index}
                                                width="100%"
                                                height="auto"
                                                src={src}
                                                style={{maxWidth: '100%', maxHeight: '100%'}}
                                            />
                                        ))}
                                        {data?.event_image.length > maxImagesToShow && (
                                            <div style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                width: '100%',
                                                height: '100%',
                                                gridColumn: 'span 1',
                                            }}>
                                                ...
                                            </div>
                                        )}
                                    </div>

                                    {/* Hidden images to include them in the preview group */}
                                    {data?.event_image.slice(maxImagesToShow).map((src, index) => (
                                        <Image
                                            key={maxImagesToShow + index}
                                            width="0"
                                            height="0"
                                            src={src}
                                            style={{display: 'none'}}
                                        />
                                    ))}
                                </Image.PreviewGroup>
                            </div>
                        </Col>
                    }
                </Row>
            </Card>
        </>
    );
}

export default AnomalyPlotCard;
