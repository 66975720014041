import React, {useState, useRef, useCallback, useEffect} from 'react';
import 'ag-grid-enterprise';
import {AgGridReact} from 'ag-grid-react';
import './MatchingTool.css';
import dayjs, {Dayjs} from "dayjs";
import {
    Button,
    Card,
    Select,
    Col,
    Dropdown,
    Menu,
    notification,
    Row,
    Space,
    Typography,
    Switch,
    Popover,
    Input,
    Divider,
    DatePicker, Descriptions, Tooltip
} from 'antd';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import Icon, {CheckSquareOutlined, InfoCircleOutlined} from "@ant-design/icons";
import {FaSearch} from 'react-icons/fa';
import axiosInstance from "services/axios";
import MatchingToolAddModal from "./MatchingToolAddModal";
import MatchingToolDetectionModal from "./MatchingToolDetectionModal";
import MatchingCard from "../MatchingCard/MatchingCard";
import PlotRenderer from "../PlotRenderer/PlotRenderer";
import LoggingInfoModal from "../LoggingInfo/LoggingInfoModal";
import TagRenderer from "../TagRenderer/TagRenderer";
import {customLogicFilter} from "../CustomLogicFilter/CustomLogicFilter";

import {
    dateTimeFormatter, ReportURLRenderer
} from "../CellHelpers/CellHelpers";
import TagValues from "../TagValues/TagValues";
import FilterForm from "../MapTool/FilterForm";
import MatchingScoreRenderer from "./MatchingScoreRenderer";
import CustomSelectEditor from "../CustomSelectEditor/CustomSelectEditor";
import MatchingTagRenderer from "./MatchingTagRenderer";
import ReviewRenderer from "./ReviewRenderer";
import CheckboxRenderer from "./CheckboxRenderer";
import CommentRenderer from "./CommentRenderer";
import MatchingEventRenderer from "./MatchingEventRenderer";
import MatchingScoreModal from "./MatchingScoreModal";

const {Search} = Input;
const {Text} = Typography;
const {Option} = Select;
const {RangePicker} = DatePicker;


const MatchingTool = (props) => {

    const gridRef = useRef();

    useEffect(() => {
        document.title = 'Matching Tool';
    }, []);

    const [detectionModalVisible, setDetectionModalVisible] = useState(false);
    const [eventToolAddModalVisible, setEventToolAddModalVisible] = useState(false);
    const [matchingModalVisible, setMatchingModalVisible] = useState(false);
    const [filterOpen, setFilterOpen] = useState(false);
    const [filterActive, setFilterActive] = useState(false);
    const [highlightValues, setHighlightValues] = useState([]);
    const [targetCustomer, setTargetCustomer] = useState(null);
    const [targetEvent, setTargetEvent] = useState(null);
    const [boxId, setBoxId] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [customerId, setCustomerId] = useState(null);
    const [eventData, setEventData] = useState(null);
    const [matchingMode, setMatchingMode] = useState('analysis');
    const targetEventRef = useRef(targetEvent);
    let eventChangeTimer; // Declare the timer variable

    const [pageSize, setPageSize] = useState(50);
    const [selectedData, setSelectedData] = useState({id: null});

    const [selectedId, setSelectedId] = useState(null);
    const [detailCardOpen, setDetailCardOpen] = useState(true);
    const handleDetailsCardChange = useCallback((data) => setDetailCardOpen(data), []);
    const [existReservation, setExistReservation] = useState(false);
    const [dateRange, setDateRange] = useState([null, null]);
    const dateRangeRef = useRef([null, null]);

    let columnState = [];

    useEffect(() => {
        dateRangeRef.current = dateRange;
        if (gridRef.current.api) {
            gridRef.current.api.refreshServerSide();
        }
    }, [dateRange]);

    useEffect(() => {
        const getParams = new URLSearchParams(window.location.search);
        const event_id = getParams.get('event_id');
        if (event_id) {
            setTargetEvent(event_id)
        }
        const boxId = getParams.get('box_id');
        if (boxId) {
            setBoxId(boxId)
        }
        if (getParams.get('start_date')) {
            setStartDate(getParams.get('start_date'))
        }
        if (getParams.get('end_date')) {
            setEndDate(getParams.get('end_date'))
        }
        if (getParams.get('customer_id')) {
            setCustomerId(getParams.get('customer_id'))
        }
    }, []);

    useEffect(() => {
        targetEventRef.current = targetEvent;
    }, [targetEvent]);

    const handleFilterOpen = () => {
        setFilterOpen(!filterOpen);
    };

    const handleFilterChange = (values) => {
        setFilterOpen(!filterOpen);
        console.log('Received values of form:', values)

        if (values.filters.length > 0) {
            setFilterActive(true)
            setHighlightValues(values.filters);
        } else {
            setFilterActive(false)
            setHighlightValues([])
        }
    };

    // Use useEffect to trigger grid redraw after state update is complete
    useEffect(() => {
        if (gridRef.current.api) {
            gridRef.current.api.redrawRows();
        }
    }, [highlightValues]); // Add other state variables if needed

    // Use useEffect to trigger grid redraw after state update is complete
    useEffect(() => {

        if (targetEvent) {

            const payload = {
                event_id: targetEvent,
                box_id: boxId,
                start_date: startDate,
                end_date: endDate,
                customer_id: customerId
            }

            axiosInstance.post('/api/matching_tool/event/details', payload)
                .then(function (response) {
                    setEventData(response.data.data)

                    if (response.data?.data?.ticket_stage === 'In Review') {
                        setMatchingMode('review')
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    setEventData(null)
                    // notification['error']({
                    //     message: 'Error getting event details',
                    // });
                });
        }

    }, [targetEvent]); // Add other state variables if needed

    const filterOptions = [
        {
            label: 'Event Direction',
            value: 'event_direction_str',
        },
        {
            label: 'Driving Direction',
            value: 'driving_direction',
        },
        {
            label: 'Driving Situation',
            value: 'driving_situation',
        },
        {
            label: 'Pattern',
            value: 'pattern',
        }
    ]

    const filterSelectOptions = [
        {
            label: 'equals',
            value: 'equals',
            tooltipContent: 'Equals - include exact matches',
        },
        {
            label: 'contains',
            value: 'contains',
            tooltipContent: 'Contains - include partial matches',
        }
    ];

    function updateColumnState() {
        // Get the current column state
        let columnState = gridRef.current.columnApi.getColumnState();

        // Set the sort key to null for all columns
        columnState = columnState.map(column => ({
            ...column,
            sort: null,
            sortIndex: null // If you want to reset the sort index as well
        }));

        // Store the updated column state in the session storage
        localStorage.setItem("carvalooMatchingToolColumns", JSON.stringify(columnState));
    }

    const onResetColumns = useCallback(() => {
        gridRef.current.columnApi.resetColumnState();
        console.log('column state reset');
    }, []);

    const handleRowSelection = () => {
        const selectedNodes = gridRef.current.api.getSelectedNodes();
        if (selectedNodes.length) {
            setSelectedId(selectedNodes[0].data.id);
        } else {
            setSelectedId(null);
        }
    };

    const datasourceServerSide = {
        getRows(params) {

            // default sort by newest element
            if (params.request.sortModel.length === 0) {
                params.request.sortModel = [
                    {
                        "sort": "desc",
                        "colId": "date"
                    }
                ]
            }

            if (!params.context.eventId || params.context.eventId.length === 0) {
                let sortModel = params.request.sortModel
                params.request.sortModel = sortModel.filter(item => item.colId !== "matching_score");
            }

            // and/or based text filter
            const keys = ['damage_tag', 'pattern', 'carPart_id'];
            params.request.filterModel = customLogicFilter(params.request.filterModel, keys)

            // list based filter for UAIDs
            if ('UAID' in params.request.filterModel) {
                if (params.request.filterModel['UAID'].type === 'contains') {
                    let filter_string = params.request.filterModel['UAID'].filter;
                    if (filter_string.includes(',')) {
                        params.request.filterModel['UAID'].filterType = "list"
                    }
                }
            }
            const currentRange = dateRangeRef.current;
            if (currentRange && currentRange[0] && currentRange[1]) {
                const startDate = dayjs(currentRange[0]).format('YYYY-MM-DD HH:mm:ss');
                const endDate = dayjs(currentRange[1]).format('YYYY-MM-DD HH:mm:ss');

                // Overwrite the current date filter
                params.request.filterModel['date'] = {
                    dateFrom: startDate,
                    dateTo: endDate,
                    filterType: 'date',
                    type: 'inRange'
                };
            }
            if (currentRange === null && params.request.filterModel?.date?.type === 'inRange') {
                delete params.request.filterModel.date;
            }

            params.request.event_id = targetEventRef.current

            console.log("Request data with params: " + JSON.stringify(params.request, null, 1));
            axiosInstance.post('/api/matching_tool/query', params.request)
                .then(res => {
                    // console.log(res.data.data)
                    params.successCallback(res.data.data, res.data.lastRow);
                })
                .catch(error => {
                    console.log(error);
                    params.fail();
                    notification['error']({message: 'Error in getRows', description: error.message});
                })
        }
    };

    const onCellValueChanged = (params) => {
        console.log(params);

        let id = params.data.id;
        let UAID = params.data.UAID

        let col = params.column.getColId();
        if (params.oldValue !== params.newValue) {
            let data = {};
            data['UAID'] = UAID
            data[col] = params.newValue;

            axiosInstance.patch('/api/matching_tool/' + id, data)
                .then(response => {
                    //params.success(response.values);
                    console.log('Update successfull');
                    console.log(response);
                    console.log(params);
                    notification['success']({
                            message: 'Updated entry for ' + UAID,
                            duration: 2.5,
                            maxCount: 15
                        }
                    );
                })
                .catch(error => {
                    console.log(error);
                    notification['error']({message: 'Error updating ' + UAID, description: error.message});
                })
        }
    }

    const onResetAllFilters = () => {
        gridRef.current.api.setFilterModel(null);
    }

    const showAddModal = () => {
        setSelectedData({
            angles_str: '[0, 0, 0]',
            op: 'add'
        })
        setEventToolAddModalVisible(true);
    }

    const showDetectionModal = () => {
        setDetectionModalVisible(true);
    }

    const showMatchingModal = () => {
        setMatchingModalVisible(true);
    }

    const getDistinctValues = (params) => {

        let column = params.column.colId;
        axiosInstance.get('/api/matching_tool/distinct/' + column)
            .then(res => {
                params.success(res.data.data);
            })
            .catch(error => {
                console.log(error);
                notification['error']({
                    message: 'Error in getDistinctValues',
                    description: (
                        <>
                            {error.message + ':'}<br/>
                            {error.response && error.response.data && error.response.data.message}
                        </>
                    ),
                });
            })
    }

    const updateSelectedData = () => {
        let selectedNodes = gridRef.current.api.getSelectedNodes();
        if (selectedNodes.length) {

            let selectedNode = selectedNodes[0];
            let data = {
                id: selectedNode.data.id,
                UAID: selectedNode.data.UAID,
                customer: selectedNode.data.customer,
                box_id: selectedNode.data.box_id,
                date: selectedNode.data.date,
                location: selectedNode.data.location,
                driving_situation: selectedNode.data.driving_situation,
                prediction_score: selectedNode.data.prediction_score,
                severity: selectedNode.data.severity,
                event_direction: selectedNode.data.event_direction,
                damage_tag: selectedNode.data.damage_tag,
                pattern: selectedNode.data.pattern,
                comment: selectedNode.data.comment,
                UAID_image: selectedNode.data.UAID_image,
                trip_image: selectedNode.data.trip_image,
            };
            setSelectedData({
                ...data,
            });
        } else {
            setSelectedData({
                id: null
            });
        }
    }

    function onRefreshData() {
        gridRef.current.api.refreshServerSide();
        console.log("Refreshing data")
    }

    function onUpdateAnalysis(matching_analysis) {
        // Get selected nodes from AG Grid
        let nodesToUpdate = gridRef.current.api.getSelectedNodes();

        // Iterate through selected nodes
        nodesToUpdate.forEach((node) => {
            // Get the current data from the node
            let data = {...node.data};

            // Update matching_analysis for the target event
            data.matching_analysis = {
                ...data.matching_analysis,
                [targetEvent]: matching_analysis
            };

            node.updateData(data);
        });

        // console.log("Refreshing row");
    }


    function onUpdateReview(matching_review) {
        // Get selected nodes from AG Grid
        let nodesToUpdate = gridRef.current.api.getSelectedNodes();

        // Iterate through selected nodes
        nodesToUpdate.forEach((node) => {
            // Get the current data from the node
            let data = {...node.data};

            // Update matching_analysis for the target event
            data.matching_review = {
                ...data.matching_review,
                [targetEvent]: matching_review
            };

            node.updateData(data);
        });

        // console.log("Refreshing row");
    }

    function onUpdateFavorite(matching_favorite) {
        // Get selected nodes from AG Grid
        let nodesToUpdate = gridRef.current.api.getSelectedNodes();

        // Iterate through selected nodes
        nodesToUpdate.forEach((node) => {
            // Get the current data from the node
            let data = {...node.data};

            // Update matching_analysis for the target event
            data.matching_favorite = {
                ...data.matching_favorite,
                [targetEvent]: matching_favorite
            };

            node.updateData(data);
        });
    }

    function onUpdateComment(comment) {
        // Get selected nodes from AG Grid
        let nodesToUpdate = gridRef.current.api.getSelectedNodes();

        // Iterate through selected nodes
        nodesToUpdate.forEach((node) => {
            // Get the current data from the node
            let data = {...node.data};

            // Update matching_analysis for the target event
            data.matching_comment = {
                ...data.matching_comment,
                [targetEvent]: comment
            };

            node.updateData(data);
        });
    }

    const getRowStyle = (params, highlightValues) => {
        const {api, node} = params;

        for (const filter of highlightValues) {
            const {field, condition, value} = filter;

            // Check if the condition matches the value in the current row
            if (api.getValue(field, node)) {
                if (
                    (condition === "equals" && api.getValue(field, node) === value) ||
                    (condition === "contains" && api.getValue(field, node).includes(value))
                ) {
                    return {fontWeight: "bold", backgroundColor: "#bebebe"};
                }
            }
        }

        if (params.context.eventData && params.data) {
            if (params.context.eventData.matching && params.context.eventData.UAID && params.data.UAID === params.context.eventData.UAID) {
                return {
                    background: 'rgba(8,142,215,0.49)', // Set your desired background color for pinned row
                    // fontWeight: 'bold' // Set your desired font weight for pinned row
                };
            }
        }

        return null; // Return null for default styling
    };


    const getColumnDefs = () => {
        return [
            {
                field: 'id',
                hide: true,
                width: 75,
                headerName: 'Id',
                floatingFilter: false,
            },
            {
                field: 'UAID',
                headerTooltip: "Unique Anomaly ID",
                width: 250,
                headerName: 'UAID',
                floatingFilter: true,
                filter: 'agTextColumnFilter',
                suppressFiltersToolPanel: true,
                cellRenderer: function (params) {
                    return <a target="_blank">{params.data?.UAID}</a>
                },
                suppressMenu: false,
            },
            {
                field: 'customer',
                headerName: 'Customer',
                hide: true,
                floatingFilter: true,
                filter: 'agSetColumnFilter',
                filterParams: {
                    values: params => {
                        getDistinctValues(params);
                    }
                },
            },
            {
                field: 'box_id',
                headerName: 'Box',
                floatingFilter: true,
                filter: 'agNumberColumnFilter',
            },
            {
                field: 'vehicle_type_installation',
                hide: true,
                headerName: 'Installation',
            },
            {
                field: 'date',
                width: 155,
                headerTooltip: "Filter by day and sort by datetime",
                headerName: 'Date',
                floatingFilter: true,
                valueFormatter: dateTimeFormatter,
                filter: 'agDateColumnFilter',
                filterParams: {
                    inRangeInclusive: true,
                    suppressAndOrCondition: true,
                }
            },
            {
                field: 'detected',
                headerName: 'Detected',
                headerTooltip: "Detected by which pipeline",
                floatingFilter: true,
                filter: 'agSetColumnFilter',
                filterParams: {
                    values: params => {
                        getDistinctValues(params);
                    }
                },
                // cellRenderer: function (params) {
                //     // Check if the "created_by" field exists in the row data
                //     if (params.data && params.data.anomaly_config && params.data.anomaly_config.created_by) {
                //         // Return the value of the "created_by" field
                //         return params.data.anomaly_config.created_by;
                //     } else {
                //         // Return a blank string if the "created_by" field is not present
                //         return '';
                //     }
                // }
            },
            {
                field: 'location',
                headerName: 'Location',
                headerTooltip: "Location based on event GPS coordinates",
                hide: true,
                floatingFilter: true,
                filter: 'agTextColumnFilter',
            },
            {
                field: 'velocity',
                headerName: 'Velocity',
                headerTooltip: "Velocity based on event GPS coordinates",
                hide: true,
                floatingFilter: true,
                filter: 'agNumberColumnFilter',
            },
            {
                field: 'prediction_score',
                headerName: 'Max() Score',
                headerTooltip: "Model predicted anomaly score - maximum score",
                suppressFiltersToolPanel: true,
                width: 90,
                floatingFilter: true,
                hide: true,
                filter: 'agNumberColumnFilter',
            },
            {
                field: 'matching_score',
                headerName: 'Matching Score',
                headerTooltip: "Matching score based on target event",
                suppressFiltersToolPanel: true,
                width: 100,
                floatingFilter: true,
                filter: 'agNumberColumnFilter',
                cellRenderer: MatchingScoreRenderer,
            },
            {
                field: 'lfc_tree_6f',
                headerName: 'LFC6f Score',
                headerTooltip: "Model predicted anomaly score - 6f or 6g model",
                suppressFiltersToolPanel: true,
                width: 90,
                hide: true,
                floatingFilter: true,
                filter: 'agNumberColumnFilter',
            },
            {
                field: 'lfc_tree_8c',
                headerName: 'LFC8c Score',
                headerTooltip: "Model predicted anomaly score - 8c or 8d model",
                suppressFiltersToolPanel: true,
                width: 90,
                hide: true,
                floatingFilter: true,
                filter: 'agNumberColumnFilter',
            },
            {
                field: 'wavenet_1',
                headerName: 'Wavenet Score',
                headerTooltip: "Model predicted anomaly score - wavenet_1 & wavenet_2.0 & wavenet_2.1 model",
                suppressFiltersToolPanel: true,
                width: 100,
                floatingFilter: true,
                filter: 'agNumberColumnFilter',
            },
            {
                field: 'wavenet_2',
                headerName: 'Wavenet 2.2 Score',
                headerTooltip: "Model predicted anomaly score - wavenet_2.2 model",
                suppressFiltersToolPanel: true,
                width: 100,
                hide: true,
                floatingFilter: true,
                filter: 'agNumberColumnFilter',
            },
            {
                field: 'severity',
                width: 110,
                headerTooltip: "Text severity level for anomaly",
                suppressFiltersToolPanel: true,
                headerName: 'Model Severity',
                floatingFilter: true,
                filter: 'agSetColumnFilter',
                filterParams: {
                    values: params => {
                        getDistinctValues(params);
                    }
                },
            },
            {
                field: 'event_direction_str',
                headerName: 'Model Direction',
                width: 125,
                headerTooltip: "Model predicted event direction",
                suppressFiltersToolPanel: true,
                floatingFilter: true,
                filter: 'agSetColumnFilter',
                filterParams: {
                    values: ['vorne', 'vorne rechts', 'vorne links', 'hinten', 'hinten rechts', 'hinten links',
                        'rechts vorne', 'rechts', 'rechts hinten', 'links vorne', 'links', 'links hinten'],
                },
                wrapText: true,
            },
            {
                field: 'event_direction',
                // valueGetter: AngleToStringGetter,
                headerName: 'Event Direction*',
                hide: true,
                width: 125,
                suppressFiltersToolPanel: true,
                floatingFilter: true,
                filter: 'agNumberColumnFilter',
                // filter: 'agSetColumnFilter',
                // filterParams: {
                //     values: ['vorne', 'vorne rechts', 'vorne links', 'hinten', 'hinten rechts', 'hinten links',
                //              'rechts vorne', 'rechts', 'rechts hinten', 'links vorne', 'links', 'links hinten'],
                // },
            },
            {
                field: 'underbody_impact_str',
                headerName: 'Underbody Impact',
                hide: true,
                width: 125,
                headerTooltip: "Model predicted underbody impact",
                suppressFiltersToolPanel: true,
                floatingFilter: true,
                filter: 'agSetColumnFilter',
                filterParams: {
                    values: params => {
                        getDistinctValues(params);
                    }
                },
                wrapText: true,
            },
            {
                field: 'underbody_impact',
                headerName: 'Underbody Impact*',
                hide: true,
                width: 125,
                suppressFiltersToolPanel: true,
                floatingFilter: true,
                filter: 'agNumberColumnFilter',
            },
            {
                field: 'driving_situation',
                headerName: 'Driving Situation',
                headerTooltip: "Model predicted driving situation",
                suppressFiltersToolPanel: true,
                hide: true,
                floatingFilter: true,
                filter: 'agSetColumnFilter',
                filterParams: {
                    values: params => {
                        getDistinctValues(params);
                    }
                },
            },
            {
                field: 'driving_direction',
                headerName: 'Driving Direction',
                headerTooltip: "Model predicted driving direction",
                suppressFiltersToolPanel: true,
                hide: true,
                floatingFilter: true,
                filter: 'agSetColumnFilter',
                filterParams: {
                    values: params => {
                        getDistinctValues(params);
                    }
                },
            },
            {
                field: 'damage_tag',
                suppressFiltersToolPanel: true,
                hide: true,
                width: 140,
                headerName: 'Damage',
                headerTooltip: "Description to tag the type of damage that occurred",
                floatingFilter: true,
                filter: 'agTextColumnFilter',
                cellRenderer: TagRenderer,
                cellRendererParams: {
                    columnValues: TagValues.distinctDamageValues,
                },
                wrapText: true,
                autoHeight: true,
            },
            {
                field: 'situation_tag',
                suppressFiltersToolPanel: true,
                width: 140,
                hide: true,
                headerName: 'Situation',
                headerTooltip: "Description to tag the type of situation that occurred",
                floatingFilter: true,
                filter: 'agTextColumnFilter',
                cellRenderer: TagRenderer,
                cellRendererParams: {
                    columnValues: TagValues.distinctSituationValues,
                },
                wrapText: true,
                autoHeight: true,
            },
            {
                field: 'pattern',
                suppressFiltersToolPanel: true,
                width: 140,
                headerName: 'Pattern',
                headerTooltip: "Description to tag the type of signal that occurred",
                floatingFilter: true,
                filter: 'agTextColumnFilter',
                cellRenderer: TagRenderer,
                cellRendererParams: {
                    columnValues: TagValues.distinctPatternValues,
                },
                wrapText: true,
                autoHeight: true,
            },
            {
                field: 'matching_confidence',
                headerName: 'Confidence',
                headerTooltip: "Matching confidence: 4: sure TP --> 1: unlikely TP --> 0: unsure --> -1: unlikely FP --> -4: sure FP",
                hide: true,
                suppressFiltersToolPanel: true,
                floatingFilter: true,
                editable: true,
                cellEditor: CustomSelectEditor,
                cellEditorParams: {
                    fixedValues: [
                        {value: 4, label: ": sure TP"},
                        {value: 3, label: ": plausible TP"},
                        {value: 2, label: ": possible TP"},
                        {value: 1, label: ": unlikely TP"},  // Add your new value here
                        {value: 0, label: ": unsure"},
                        {value: -1, label: ": unlikely FP"},
                        {value: -2, label: ": possible FP"},
                        {value: -3, label: ": plausible FP"},
                        {value: -4, label: ": sure FP"}
                    ]
                },
                filter: 'agSetColumnFilter',
                filterParams: {
                    values: params => {
                        getDistinctValues(params);
                    }
                },
            },
            {
                field: 'comment',
                width: 150,
                suppressFiltersToolPanel: true,
                headerName: 'Comment',
                headerTooltip: "Free flowing comment - no structure",
                floatingFilter: true,
                filter: 'agTextColumnFilter',
                editable: true,
                wrapText: true,
            },
            {
                field: 'matching_event',
                width: 140,
                suppressFiltersToolPanel: true,
                headerName: 'Event',
                headerTooltip: "Events that are tagged to fit to this anomaly",
                floatingFilter: true,
                filter: 'agTextColumnFilter',
                cellRenderer: MatchingEventRenderer,
                wrapText: true,
                autoHeight: true,
            },
            {
                field: 'matching_comment',
                width: 140,
                suppressFiltersToolPanel: true,
                headerName: 'Matching Comment',
                headerTooltip: "View only comment column - edit column in anomaly details",
                floatingFilter: true,
                filter: 'agTextColumnFilter',
                cellRenderer: CommentRenderer, // Use TagRenderer as the cell renderer
                wrapText: true,
                sortable: false,
                autoHeight: true,
                filterParams: {
                    filterOptions: ['contains', 'notBlank'],
                    suppressAndOrCondition: true // Only allow one filter condition
                }
            },
            {
                field: 'matching_analysis',
                minWidth: 200,
                suppressFiltersToolPanel: true,
                headerName: 'Analysis Tags',
                headerTooltip: "Analysis tags for this anomaly for the selected event",
                floatingFilter: true,
                filter: 'agTextColumnFilter',
                cellRenderer: MatchingTagRenderer, // Use TagRenderer as the cell renderer
                wrapText: true,
                sortable: false,
                autoHeight: true,
                filterParams: {
                    filterOptions: ['contains', 'notBlank'],
                    suppressAndOrCondition: true // Only allow one filter condition
                }
            },
            {
                field: 'matching_review',
                minWidth: 200,
                suppressFiltersToolPanel: true,
                headerName: 'Review Tags',
                headerTooltip: "Review tags for this anomaly for the selected event",
                floatingFilter: true,
                filter: 'agTextColumnFilter',
                cellRenderer: MatchingTagRenderer, // Use TagRenderer as the cell renderer
                wrapText: true,
                sortable: false,
                autoHeight: true,
                filterParams: {
                    filterOptions: ['contains', 'notBlank'],
                    suppressAndOrCondition: true // Only allow one filter condition
                }
            },
            {
                field: 'matching_review',
                width: 100,
                suppressFiltersToolPanel: true,
                headerName: 'Review Progress',
                headerTooltip: "Review tags for this anomaly for the selected event",
                floatingFilter: false,
                // filter: 'agTextColumnFilter',
                cellRenderer: ReviewRenderer, // Use TagRenderer as the cell renderer
                wrapText: true,
                sortable: false,
                autoHeight: true,
            },
            {
                field: 'matching_favorite',
                headerName: '★',
                width: 60,
                headerTooltip: "Boolean if anomaly is a favorite for matching",
                suppressFiltersToolPanel: true,
                floatingFilter: true,
                filter: 'agTextColumnFilter',
                cellRenderer: CheckboxRenderer,
                sortable: false,
                filterParams: {
                    filterOptions: ['notBlank'],
                    suppressAndOrCondition: true // Only allow one filter condition
                }
            },
            {
                field: 'UAID_image',
                headerName: 'UAID Image',
                cellRenderer: PlotRenderer,
                cellRendererParams: {'index': 0},
                autoHeight: true,
                wrapText: true,
                sortable: false,
            },
            {
                field: 'trip_image',
                headerName: 'Trip Image',
                cellRenderer: PlotRenderer,
                cellRendererParams: {'index': 1},
                autoHeight: true,
                wrapText: true,
                sortable: false,
            },
            {
                field: 'modified_datetime',
                headerName: 'Modified UTC',
                width: 155,
                hide: true,
                headerTooltip: "Filter by day and sort by datetime",
                floatingFilter: true,
                valueFormatter: dateTimeFormatter,
                filter: 'agDateColumnFilter',
                filterParams: {
                    inRangeInclusive: true,
                    suppressAndOrCondition: true,
                }
            },
        ]
    }


    const gridOptions = {
        context: {},
        reactiveCustomComponents: true,
        rowModelType: 'serverSide',
        cacheBlockSize: 50,
        rowSelection: 'multiple',
        suppressRowClickSelection: false,
        enableRangeSelection: 'true',
        pagination: true,
        paginationPageSize: 50, //paginationAutoPageSize: true,
        suppressLastEmptyLineOnPaste: true,
        columnDefs: getColumnDefs(),
        defaultColDef: {
            width: 100,
            sortable: true,
            filter: false,
            resizable: true, //minWidth: 100,
            editable: false, //cellEditor: PopupCellEditor
            suppressMenu: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
        },
        sideBar: {
            position: 'right',
            defaultToolPanel: '',
            toolPanels: [
                {
                    id: 'columns',
                    labelDefault: 'Columns',
                    labelKey: 'columns',
                    iconKey: 'columns',
                    toolPanel: 'agColumnsToolPanel',
                    minWidth: 225,
                    maxWidth: 225,
                    width: 225,
                    toolPanelParams: {
                        suppressRowGroups: true,
                        suppressValues: true,
                        suppressPivotMode: true
                    }
                },
                {
                    id: 'filters',
                    labelDefault: 'Filters',
                    labelKey: 'filters',
                    iconKey: 'filter',
                    toolPanel: 'agFiltersToolPanel',
                    minWidth: 180,
                    maxWidth: 400,
                    width: 250
                }
            ],
        },
        getMainMenuItems(params) {
            // you don't need to switch, we switch below to just demonstrate some different options
            // you have on how to build up the menu to return
            switch (params.column.getId()) {
                // return the defaults, put add some extra items at the end
                // case 'box_id':
                //   const boxmenu: (| MenuItemDef | string)[] = params.defaultItems.slice(0);
                //   boxmenu.push({
                //     name: 'Supply filter list', action: () => {
                //       updateFilterValues(params);
                //     },
                //   });
                //   return boxmenu;

                default:
                    // make no changes, just accept the defaults
                    return params.defaultItems;
            }

        },
        onCellClicked: function (params) {
            if (params.column.colId === 'UAID') {
                notification['info']({message: 'Opening report..'});
                axiosInstance.post(
                    '/api/anomaly_tool/data/report',
                    {
                        UAID: params.data.UAID,
                    })
                    .then(res => {
                        // console.log(res.data)
                        if (res.data.report_url) {
                            window.open(res.data.report_url, '_blank'); // Open in a new tab
                        } else {
                            notification['warning']({
                                message: 'No report URL found - trying generate report now... Please wait ...'
                            });

                            axiosInstance.post(
                                '/api/anomaly_tool/report/generate',
                                {
                                    UAID: params.data.UAID,
                                    customer: params.data.customer,
                                    anomaly_type: 'dev',
                                })
                                .then(res => {
                                    if (res.data.ok) {
                                        notification['success']({message: 'Success generating report'});
                                    } else {
                                        console.log(res.data)
                                        notification['error']({message: 'Failed generating report - see error details in console'});
                                    }
                                })
                                .catch(error => {
                                    console.log(error);
                                    notification['error']({
                                        message: 'Error',
                                        description: (
                                            <>
                                                {error.message + ':'}<br/>
                                                {error.response && error.response.data && error.response.data.message}
                                            </>
                                        ),
                                    });
                                })
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        notification['error']({
                            message: 'Error getting report URL',
                            description: (
                                <>
                                    {error.message + ':'}<br/>
                                    {error.response && error.response.data && error.response.data.message}
                                </>
                            ),
                        });
                    })
            }
        },
        onSelectionChanged: (event) => {
            updateSelectedData();
        },
        onFilterChanged: (event) => {
            const filterModel = gridRef.current.api.getFilterModel();
            //   const filtersMeetCondition =
            //     filterModel.box_id &&
            //     filterModel.box_id.type === "equals" &&
            //     filterModel.box_id.filterType === "number";
            //   setExistReservation(filtersMeetCondition);
        },
        onGridReady: function () {
            gridRef.current.api.setServerSideDatasource(datasourceServerSide);

            const columnStateString = localStorage.getItem("carvalooMatchingToolColumns");
            if (columnStateString) {
                const columnState = JSON.parse(columnStateString);
                gridRef.current.columnApi.applyColumnState({state: columnState});
            }

            // filter by box_id if in url
            const getParams = new URLSearchParams(window.location.search);
            const box_id = getParams.get('box_id');
            const start_date = getParams.get('start_date');
            const end_date = getParams.get('end_date');

            if (box_id) {

                const twoMonthsAgo = new Date();
                twoMonthsAgo.setMonth(twoMonthsAgo.getMonth() - 1);

                gridRef.current.api.setFilterModel({
                    "box_id": {
                        "filterType": "number",
                        "type": "equals",
                        "filter": parseInt(box_id, 10)
                    },
                    "date": {
                        "filterType": "date",
                        "type": "inRange",
                        "dateFrom": start_date + ' 00:00:00',
                        "dateTo": end_date + ' 00:00:00',
                    }
                });

                // notification['info']({message: 'Showing events from ' + start_date + ' to ' + end_date + ' - do not forget to adjust the filter manually. Happy matching :)'});

            }

            console.log('grid ready');
        },
        onColumnVisible: (event) => {
            updateColumnState()
        },
        onColumnResized: (event) => {
            updateColumnState()
        },
        onColumnMoved: (event) => {
            updateColumnState()
        },
        onColumnPinned: (event) => {
            updateColumnState()
        },
        onRowSelected: (event) => {
            handleRowSelection()
        },
        onRowEditingStarted: (event) => {
            console.log('never called - not doing row editing');
        },
        onRowEditingStopped: (event) => {
            console.log('never called - not doing row editing');
        },
        onCellEditingStarted: (event) => {
            console.log('cellEditingStarted');
        },
        onCellEditingStopped: (event) => {
            console.log('cellEditingStopped');
        },
        onModelUpdated: (event) => {
            updateSelectedData();
        },
        onCellValueChanged: onCellValueChanged,
    }

    const handleCustomerChange = (event) => {
        setTargetCustomer(event.target.value);
    };
    const handleEventChange = (event) => {
        // Clear the previous timer
        if (eventChangeTimer) {
            clearTimeout(eventChangeTimer);
        }

        // Set the new value of targetEvent
        setTargetEvent(event.target.value);

        if (event.target.value.length === 0) {
            setEventData(null)
            let filterModel = gridRef.current.api.getFilterModel()
            if (filterModel.hasOwnProperty('matching_score')) {
                delete filterModel['matching_score']
            }
            gridRef.current.api.setFilterModel(filterModel);
        }

        // Start a new timer for 2 seconds
        eventChangeTimer = setTimeout(() => {
            // This code will run after 2 seconds if the event hasn't changed
            gridRef.current.api.refreshServerSide();
            gridRef.current.api.redrawRows();
        }, 2000);
    };

    const handleClickAll = () => {
        const currentPage = gridRef.current.api.paginationGetCurrentPage(); // Get current page number
        const startRow = currentPage * 50; // Calculate start row index
        const endRow = (currentPage + 1) * 50 - 1; // Calculate end row index

        let rowUAID = [];
        gridRef.current.api.forEachNode(node => rowUAID.push(node.data.UAID));
        const rowUAIDPage = rowUAID.slice(startRow, endRow + 1)

        let rowCustomer = [];
        gridRef.current.api.forEachNode(node => rowCustomer.push(node.data.customer));
        const rowCustomerPage = rowCustomer.slice(startRow, endRow + 1)

        notification['info']({message: 'Sending requests to calculate matching score'});
        for (let i = 0; i < rowUAIDPage.length; i++) {
            const payload = {
                uaid: rowUAIDPage[i],
                customer: rowCustomerPage[i],
                event_id: targetEvent
            }

            if (targetEvent) {
                axiosInstance.post('/api/matching_tool/matching_score', payload)
                    .then(res => {
                        console.log('Request processed successfully')
                    })
                    .catch(error => {
                        console.log(error);
                        notification['error']({message: 'Error', description: error.message});
                    })
            } else {
                notification['warning']({message: 'Please set event'});
            }
        }

        const refreshInterval = 15000;
        const totalTime = 60000;
        let elapsedTime = 0;

        const refreshTimer = setInterval(() => {
            if (elapsedTime < totalTime) {
                notification['info']({message: 'Automatic refresh'});
                gridRef.current.api.refreshServerSide();
                elapsedTime += refreshInterval;
            } else {
                clearInterval(refreshTimer); // Stop the timer after 60 seconds
            }
        }, refreshInterval);
    };

    const rangePresets = [
        {label: 'Last 12 hours', value: [dayjs().add(-12, 'hours'), dayjs()]},
        {label: 'Last 24 hours', value: [dayjs().add(-1, 'd'), dayjs()]},
        {label: 'Last 2 days', value: [dayjs().add(-2, 'd'), dayjs()]},
        {label: 'Last 3 days', value: [dayjs().add(-3, 'd'), dayjs()]},
        {label: 'Last 7 days', value: [dayjs().add(-7, 'd'), dayjs()]},
        {label: 'Last 30 days', value: [dayjs().add(-30, 'd'), dayjs()]},
    ];

    const handleDateRangeChange = (data) => {
        setDateRange(data);
    }

    return (<>
            <MatchingToolAddModal
                visible={eventToolAddModalVisible}
                showModal={() => setEventToolAddModalVisible(true)}
                hideModal={() => setEventToolAddModalVisible(false)}
            />
            <MatchingToolDetectionModal
                visible={detectionModalVisible}
                showModal={() => setDetectionModalVisible(true)}
                hideModal={() => setDetectionModalVisible(false)}
            />
            <MatchingScoreModal
                visible={matchingModalVisible}
                showModal={() => setMatchingModalVisible(true)}
                hideModal={() => setMatchingModalVisible(false)}
            />
            {/*<MatchingMapModal*/}
            {/*  showModal={() => setMapModalOpen(true)}*/}
            {/*  hideModal={() => setMapModalOpen(false)}*/}
            {/*  open={mapModalOpen}*/}
            {/*  mapHeight={'700px'}*/}
            {/*  mapLocations={mapLocations}*/}
            {/*  mapLocationsLoading={mapLocationsLoading}*/}
            {/*  selectedMarker={selectedMarker}*/}
            {/*  handleSelectedMarkerChange={handleSelectedMarkerChange}*/}
            {/*  offsetLeft={'0px'}*/}
            {/*  offsetTop={'0px'}*/}
            {/*/>*/}
            <Card style={{
                width: '100%',
                height: '100%',
                boxSizing: 'border-box',
                display: 'flex',
                flexDirection: 'column',
                boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                border: '1px solid #e8e8e8',
            }} bodyStyle={{
                paddingTop: '5px', paddingBottom: '5px', paddingRight: '5px', paddingLeft: '5px',
                display: 'flex', flexDirection: "column", flex: "1 1 auto"
            }}>
                <Row gutter={16} style={{marginBottom: "8px"}}>
                    <Col span={24}>
                        <Card bodyStyle={{
                            paddingTop: '4px',
                            paddingBottom: '4px',
                            paddingRight: '4px',
                            paddingLeft: '4px'
                        }}>
                            <Row gutter={[0, 0]}>
                                <Col>
                                    <Button onClick={onRefreshData}>Refresh data</Button>
                                </Col>
                                <Col>
                                    <Button onClick={onResetAllFilters}>Reset filters</Button>
                                </Col>
                                <Col>
                                    <Button onClick={onResetColumns}>Reset columns</Button>
                                </Col>
                                <Col>
                                    <Popover
                                        trigger="click"
                                        placement="bottom"
                                        content={
                                            <FilterForm
                                                onFinish={handleFilterChange} fieldOptions={filterOptions}
                                                conditionOptions={filterSelectOptions}
                                            >
                                            </FilterForm>}
                                        open={filterOpen}
                                    >
                                        <Button
                                            onClick={handleFilterOpen}
                                            style={{backgroundColor: filterActive ? "lightcoral" : "white"}}
                                        >
                                            Highlight rows
                                        </Button>
                                    </Popover>
                                </Col>
                                <Divider type={'vertical'}></Divider>
                                <RangePicker
                                    showTime={{
                                        use12Hours: false,
                                    }}
                                    presets={rangePresets}
                                    onChange={handleDateRangeChange}
                                    style={{width: 330}}
                                    allowClear={true}
                                    value={dateRange}
                                />
                                <Divider type={'vertical'}></Divider>
                                <Col>
                                    <Button onClick={showAddModal}>Add anomalies</Button>
                                </Col>
                                <Col>
                                    <Button onClick={showDetectionModal}>Run detection</Button>
                                </Col>
                                <Col>
                                    <Button onClick={showMatchingModal}>Run matching model</Button>
                                </Col>
                                <Divider type={'vertical'}></Divider>
                                <Col>
                                    <Input
                                        style={{width: 200}}
                                        value={targetEvent}
                                        placeholder="set target matching event"
                                        allowClear={true}
                                        size={'medium'}
                                        onChange={handleEventChange}
                                    />
                                </Col>
                                {eventData &&
                                    <Descriptions
                                        style={{}}
                                        bordered column={1}
                                        size={'small'}
                                    >
                                        <Descriptions.Item
                                            label="Status"
                                            style={{height: '10px', padding: 4, paddingLeft: 16, paddingRight: 16}}
                                            contentStyle={{
                                                backgroundColor: eventData.assignment_status === 'in_analysis'
                                                    ? '#90ee90'
                                                    : eventData.assignment_status === 'matched' ? 'rgba(8,142,215,0.49)' : '#f08080'
                                            }}
                                        >
                                            <Tooltip title={eventData.UAID ? eventData.UAID : 'No matched UAID'}>
                                                {eventData.assignment_status} - {eventData.ticket_stage}
                                            </Tooltip>
                                        </Descriptions.Item>
                                    </Descriptions>
                                }
                                {/*{eventData === null &&*/}
                                {/*<Text style={{marginTop: 6, marginLeft: 8}}>No event found!</Text>*/}
                                {/*}*/}
                                {/*<Col>*/}
                                {/*    <Input*/}
                                {/*        value={targetCustomer}*/}
                                {/*        placeholder="enter customer"*/}
                                {/*        allowClear={true}*/}
                                {/*        size={'medium'}*/}
                                {/*        style={{width: 150}}*/}
                                {/*        onChange={handleCustomerChange}*/}
                                {/*    />*/}
                                {/*</Col>*/}
                                {/*<Col>*/}
                                {/*  <Popover*/}
                                {/*    title='Reservations'*/}
                                {/*    trigger="click"*/}
                                {/*    content={*/}
                                {/*    "Test"*/}
                                {/*    }*/}
                                {/*  >*/}
                                {/*    <Button disabled={!existReservation}> Reservations</Button>*/}
                                {/*  </Popover>*/}
                                {/*</Col>*/}
                                <Col style={{marginLeft: 'auto'}}>
                                    <Switch
                                        style={{marginTop: '5px'}}
                                        onChange={handleDetailsCardChange}
                                        checkedChildren="details"
                                        unCheckedChildren="details"
                                        checked={detailCardOpen}
                                    />
                                </Col>
                            </Row>
                        </Card>
                    </Col>

                </Row>
                <Row className="ag-theme-alpine" style={{flex: "1 1 auto"}}>
                    <Col style={{flex: "1 1 auto"}}>
                        <AgGridReact
                            ref={gridRef}
                            gridOptions={gridOptions}
                            getRowStyle={params => getRowStyle(params, highlightValues)}
                            context={{eventId: targetEvent, customer: targetCustomer, eventData: eventData}}
                        />
                    </Col>
                    {(selectedId) && (detailCardOpen) &&
                        <Col>
                            <MatchingCard
                                matchingMode={matchingMode}
                                id={selectedId}
                                eventData={eventData}
                                targetEvent={targetEvent}
                                onUpdateAnalysis={onUpdateAnalysis}
                                onUpdateReview={onUpdateReview}
                                onUpdateFavorite={onUpdateFavorite}
                                onUpdateComment={onUpdateComment}
                            />
                        </Col>}
                </Row>
            </Card>
        </>

    );
}

export default MatchingTool;
