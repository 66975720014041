import React from 'react';
import { Form, Input, Button } from 'antd';

const ZoomForm = (props) => {
    const [form] = Form.useForm();

    const handleValuesChange = (changedValues, allValues) => {
        const inputString = allValues.string;

        const regex = /X Extremes: \[(-?\d+\.\d+), (-?\d+\.\d+)\] - Y Extremes: \[(-?\d+\.\d+), (-?\d+\.\d+)\]/;
        const matches = inputString.match(regex);

        if (matches) {
            form.setFieldsValue({
                xmin: parseFloat(matches[1]),
                xmax: parseFloat(matches[2]),
                ymin: parseFloat(matches[3]),
                ymax: parseFloat(matches[4]),
            });
        }
    };

    return (
        <Form
            form={form}
            onFinish={props.onFinish}
            onValuesChange={handleValuesChange}
        >
            <Form.Item name="string" label="String">
                <Input placeholder="Paste Coordinates" />
            </Form.Item>
            <Form.Item name="xmin" label="X Min">
                <Input placeholder="Enter X Min" />
            </Form.Item>
            <Form.Item name="xmax" label="X Max">
                <Input placeholder="Enter X Max" />
            </Form.Item>
            <Form.Item name="ymin" label="Y Min">
                <Input placeholder="Enter Y Min" />
            </Form.Item>
            <Form.Item name="ymax" label="Y Max">
                <Input placeholder="Enter Y Max" />
            </Form.Item>
            <Form.Item style={{ marginBottom: 8 }}>
                <Button type="primary" htmlType="submit" style={{ marginRight: 8 }}>
                    Submit
                </Button>
            </Form.Item>
        </Form>
    );
};

export default ZoomForm;