import React, {useState, useRef, useCallback, useEffect} from 'react';

import {
    Button,
    Card,
    Carousel,
    Descriptions,
    Select,
    Col,
    Modal,
    List,
    notification,
    Row,
    Typography,
    Input,
    Spin,
    Switch,
    Table,
    Timeline,
    Divider,
    Popover, Alert, Tooltip
} from 'antd';

import Icon, {BorderInnerOutlined, EditFilled} from "@ant-design/icons";
import axiosInstance from "services/axios";
import ChartToolModal from "../ChartTool/ChartToolModal";
import ReactJson from "react-json-view";
import {FaCog, FaFile, FaList, FaRedo} from "react-icons/fa";
import TagValues from "../TagValues/TagValues.js"
import HistoryModal from "../DetailsCard/HistoryModal";
import ImageRenderer from "../ImageRenderer/ImageRenderer";
import CarPartRenderer from "../CarPartRenderer/CarPartRenderer";
import MultiImageRendererV2 from "../MultiImageRendererV2/MultiImageRendererV2";
import './custom.css'

const {Title} = Typography;
const {Text} = Typography;


const AnomalyIncidentCard = (props) => {

    const UAID = props.UAID
    const [ignoreCount, setIgnoreCount] = useState(0);
    const [ignore, setIgnore] = useState(null);
    const [data, setData] = useState(null);
    const [showIgnore, setShowIgnore] = useState(false);
    const [reservationData, setReservationData] = useState(null);
    const [customerReservations, setCustomerReservations] = useState(null);
    const [noData, setNoData] = useState(false);
    const [loading, setLoading] = useState(false);
    const [inputNumber, setInputNumber] = useState('9');
    const [inputDatabase, setInputDatabase] = useState('anomaly-similarity');

    function getIncidentData(payload, showLoading) {
        // Send request to python backend

        if (showLoading) {
            setLoading(true);
        }
        axiosInstance.post('/api/anomaly_tool/incident', payload)
            .then(res => {
                if (showLoading) {
                    setLoading(false);
                }

                // Set row data based on JSON response
                if (res.data.data.length === 0) {
                    setNoData(true)
                }

                // res.data.data.push(res.data.data[0])
                // res.data.data.push(res.data.data[0])
                // res.data.data.push(res.data.data[0])
                // res.data.data.push(res.data.data[0])
                // console.log(res.data.data)
                setData(res.data.data)
                setReservationData(res.data.reservation)
            })
            .catch(error => {
                if (showLoading) {
                    setLoading(false);
                }
                console.log(error);
                notification['error']({
                        message: error.message,
                        description: error.response.data.message,
                        duration: 10,
                    }
                );
            })
    }

    useEffect(() => {
        if (UAID !== null) {
            const payload = {
                UAID: UAID,
            };

            getIncidentData(payload, true);
        } else {
            setData(null)
        }
    }, [UAID, props.reload, ignoreCount]);

    const onCustomerReservations = () => {

        const payload = {
            uaid: UAID,
        };

        axiosInstance.post('/api/anomaly_tool/reservations', payload)
            .then(res => {
                setCustomerReservations(res.data.data)
            })
            .catch(error => {
                console.log(error);
                notification['error']({
                        message: error.message,
                        description: error.response.data.message,
                        duration: 10,
                    }
                );
            })
    }

    const columnsReservation = [
        {
            title: 'ID',
            dataIndex: 'reservation_id',
            key: 'reservation_id',
        },
        {
            title: 'Booked Start',
            dataIndex: 'start',
            key: 'start_utc',
        },
        {
            title: 'Start',
            dataIndex: 'actual_start',
            key: 'actual_start_utc',
        },
        {
            title: 'Booked End',
            dataIndex: 'end',
            key: 'end_utc',
        },
        {
            title: 'End',
            dataIndex: 'actual_end',
            key: 'actual_end_utc',
        },
        {
            title: 'Mileage',
            dataIndex: 'total_mileage',
            key: 'total_mileage',
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
        },
        // {
        //   title: 'Dienstfahrtgrund',
        //   dataIndex: 'Dienstfahrtgrund',
        //   key: 'Dienstfahrtgrund',
        // }
    ]

    const getRowClassName = (record) => {
        // Condition to check if the age is greater than 30
        if (record.reservation_id === reservationData?.reservation_id) {
            return 'highlighted-row'; // CSS class name for highlighting
        }
        return ''; // Empty string if the condition is not met
    };

    const formatDate = (datetime) => {
        if (!datetime) return '';

        const [date, time] = datetime.split('T');
        const [hours, minutes] = time.split(':');

        return `${date} ${hours}:${minutes}`;
    };

    const onIgnoreVorfall = (event_id) => {
        setIgnore(event_id)
        setShowIgnore(true)
    }

    const handleCancel = () => {
        setShowIgnore(false)
    };

    const handleRemoveConfirm = () => {
        axiosInstance.post(
            '/api/anomaly_tool/incident/ignore',
            {event_id: ignore, customer: props.customer})
            .then(res => {
                setShowIgnore(false)
                notification['success']({
                    message: 'Labeled incident as ignore',
                });
                setIgnoreCount(prevCounter => prevCounter + 1)
            })
            .catch(error => {
                console.log(error);
                notification['error']({
                    message: 'Error ignoring incident',
                    description: (
                        <>
                            {error.message + ':'}<br/>
                            {error.response && error.response.data && error.response.data.message}
                        </>
                    ),
                });
            })
    };

    return (
        <>
            <Modal
                title="Ignore Vorfall"
                open={showIgnore}
                onCancel={handleCancel}
                width={350}
                footer={null}
            >
                <Row justify="center" style={{marginBottom: 16}}>
                    <p>Are you sure you want to label the incident {ignore} as "ignore"?</p>
                </Row>
                <Row justify="center">
                    <Button type="primary" onClick={handleRemoveConfirm} style={{marginRight: 8}}>
                        Yes
                    </Button>
                    <Button onClick={handleCancel}>
                        No
                    </Button>
                </Row>
            </Modal>
            <Card
                style={{
                    width: '100%',
                    height: '100%',
                    boxSizing: 'border-box',
                    display: 'flex',
                    flexDirection: 'column',
                    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                    border: '1px solid #e8e8e8',
                    minHeight: 450,
                }}
                title={'Incidents'}
                extra={
                    <>
                        <Popover
                            title={'Reservations'}
                            trigger="click"
                            placement="right"
                            content={
                                <Table
                                    size='small'
                                    columns={columnsReservation}
                                    dataSource={customerReservations}
                                    pagination={false}
                                    rowClassName={getRowClassName}
                                />
                            }
                        >
                            <Button size={'small'} onClick={onCustomerReservations}>Reservations</Button>
                        </Popover>
                    </>
                }
                bodyStyle={{
                    padding: '5px', display: 'flex', flexDirection: "column", flex: "1 1 auto"
                }}>
                {loading &&
                    <div className="example" style={{marginLeft: 8}}>
                        <Spin/>
                    </div>
                }
                {data &&
                    <>
                        {/*<Descriptions bordered size={'middle'}>*/}
                        {/*    <Descriptions.Item*/}
                        {/*        label="Reservation ID">{reservationData?.reservation_id}</Descriptions.Item>*/}
                        {/*    <Descriptions.Item label="Type">{reservationData?.type}</Descriptions.Item>*/}
                        {/*</Descriptions>*/}
                        {/*<Descriptions bordered size={'middle'}>*/}
                        {/*    <Descriptions.Item*/}
                        {/*        label="Start">{formatDate(reservationData?.start)}</Descriptions.Item>*/}
                        {/*    <Descriptions.Item label="End">{formatDate(reservationData?.end)}</Descriptions.Item>*/}
                        {/*</Descriptions>*/}
                        {/*<Descriptions bordered size={'middle'}>*/}
                        {/*    <Descriptions.Item*/}
                        {/*        label="Actual Start">{formatDate(reservationData?.actual_start)}</Descriptions.Item>*/}
                        {/*    <Descriptions.Item*/}
                        {/*        label="Actual End">{formatDate(reservationData?.actual_end)}</Descriptions.Item>*/}
                        {/*</Descriptions>*/}

                        {props.UAID !== props.anomalyInfo?.event_id && props.anomalyInfo?.event_id &&
                            <Alert
                                message={`Anomaly has already been matched to an incident - ${props.anomalyInfo?.event_id}`}
                                // description="This action will overwrite the current matching"
                                type="error"
                                showIcon
                            />
                        }

                        <div style={{maxHeight: '360px', overflowY: 'auto', overflowX: 'hidden'}}>
                            <Timeline style={{marginTop: 20, marginLeft: 20}}>
                                {data.map((item) => (
                                    <Timeline.Item
                                        key={item.event_id}
                                        // label={item.Vorfalldatum.replace('T', ' - ').slice(0, 21)}
                                    >
                                        <Row>

                                            <Col span={12}>
                                                <Row style={{marginBottom: 4}}>
                                                    {item.Vorfalldatum.replace('T', ' - ').slice(0, 21)}
                                                    <Popover
                                                        title={'Comment'}
                                                        trigger="click"
                                                        content={
                                                            <div style={{width: 600}}>
                                                                <Text>
                                                                    {item.Comment}
                                                                </Text>
                                                            </div>
                                                        }
                                                        placement={'right'}
                                                    >
                                                        <Button size={"small"} icon={<EditFilled/>}
                                                                style={{marginLeft: 10}}>
                                                            Comment
                                                        </Button>
                                                    </Popover>
                                                </Row>
                                                <div>
                                                    <Text style={{fontWeight: 800}}>
                                                        {item.event_id}
                                                    </Text>
                                                    <Divider type={'vertical'}
                                                             style={{border: '1px solid gray'}}></Divider>
                                                    <Text>
                                                        {item.gemeldetVon}
                                                    </Text>
                                                </div>
                                                <Text>
                                                    {item.VorfallBeurteilung ? 'Status: ' + item.VorfallBeurteilung : 'Status: N/A'}
                                                </Text>
                                                <Divider type={'vertical'}
                                                         style={{border: '1px solid gray'}}></Divider>
                                                <Text>
                                                    {item.Offerte ? 'Offer: ' + item.Offerte : 'Offer: N/A'}
                                                </Text>
                                                <Divider type={'vertical'}
                                                         style={{border: '1px solid gray'}}></Divider>
                                                <Tooltip
                                                    title={item.Schadenort}
                                                    mouseEnterDelay={0.5}  // Show after 0.5 seconds
                                                    mouseLeaveDelay={0}    // Hide immediately>
                                                >
                                                    <Text>
                                                        {'Location: ' + (item.Schadenort ? (item.Schadenort.length > 50 ? item.Schadenort.substring(0, 50) + '...' : item.Schadenort) : 'N/A')}
                                                    </Text>
                                                </Tooltip>
                                            </Col>
                                            <Col span={6} style={{marginTop: -16}}>
                                                <CarPartRenderer value={item?.car_part_ids}/>
                                                <Button size="small"
                                                        style={{marginLeft: 6, marginTop: -16, marginBottom: 4}}
                                                        onClick={() => onIgnoreVorfall(item.event_id)}>
                                                    Ignore Incident
                                                </Button>
                                            </Col>
                                            <Col span={6} style={{marginTop: 14}}>
                                                {item.SchadenFoto ?
                                                    <MultiImageRendererV2 value={item.SchadenFoto} previewCount={2}
                                                                          grid={'repeat(auto-fill, minmax(35px, 1fr))'}/>
                                                    : <div style={{marginTop: 16, marginLeft: 24}}> No Images </div>
                                                }
                                            </Col>
                                        </Row>
                                        {/*<Text style={{color: item.location.lat ? 'black' : 'red'}}>*/}
                                        {/*    Latitude: {item.location.lat ? item.location.lat : 'Not available'}*/}
                                        {/*</Text>*/}
                                        {/*<Divider type={'vertical'} style={{marginTop: 4, marginBottom: 4}}></Divider>*/}
                                        <Divider style={{marginTop: 4, marginBottom: 4}}></Divider>
                                    </Timeline.Item>
                                ))}
                            </Timeline>
                        </div>
                    </>
                }
                {noData && <div style={{marginLeft: 16}}> No incidents available</div>}
            </Card>
        </>
    );
}

export default AnomalyIncidentCard;
