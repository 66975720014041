// import 'antd/dist/antd.css';
import React from "react";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import {ConfigProvider, Layout} from 'antd';
import './App.css';
import {useAuth} from "react-oidc-context";
import {LicenseManager} from 'ag-grid-enterprise';

import {UnauthorizedScreen} from "./common_components/Auth/UnauthorizedScreen";
import {SignOutScreen} from "./common_components/Auth/SignOutScreen";
import {AuthCallback} from "./common_components/Auth/AuthCallback"
import {AuthError} from "./common_components/Auth/AuthError"
import {AuthorizedTemplate} from "./common_components/Auth/Auth";
import Sidebar from './components/Sidebar/Sidebar'

import MatchingTool from './components/MatchingTool/MatchingTool'
import DamageTool from './components/DamageTool/DamageTool'
import SimilarityTool from './components/SimilarityTool/SimilarityTool'
import MonitoringDashboard from "./components/MonitoringDashboard/MonitoringDashboard";
import MonitoringDashboardB from "./components/MonitoringDashboardB/MonitoringDashboardB";
import AnomalyTool from "./components/AnomalyTool/AnomalyTool";
import MapTool from "./components/MapTool/MapTool";
import LabelTool from "./components/LabelTool/LabelTool";
import ConfigTool from "./components/ConfigTool/ConfigTool";

const {Content, Footer} = Layout;

LicenseManager.setLicenseKey("Using_this_{AG_Grid}_Enterprise_key_{AG-052057}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{carValoo_GmbH}_is_granted_a_{Multiple_Applications}_Developer_License_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_{AG_Grid}_Enterprise___This_key_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{20_February_2025}____[v3]_[01]_MTc0MDAwOTYwMDAwMA==a24454a77dce07be57510d6f02d62c20")


function App() {

    const auth = useAuth();

    switch (auth.activeNavigator) {
        case "signoutRedirect":
            return <div>Signing you out...</div>;
    }

    return (<>
            <Router>
                <Routes>
                    <Route path="/*" element={
                        <AuthorizedTemplate hasAnyRoles={['user', 'admin']}
                                            unauthorizedComponent={<UnauthorizedScreen/>}>
                            <ConfigProvider
                                theme={{
                                  token: {
                                    colorPrimary: '#088ed7',
                                    borderRadius: 2
                                  },
                                  components: {
                                        Layout: {
                                            headerBg: '#088ed7',
                                            triggerBg: '#088ed7',
                                            lightSiderBg: '#088ed7',
                                            siderBg: '#088ed7',
                                        },
                                        Menu: {
                                            itemBg: '#088ed7',
                                            subItemBg: '#088ed7',
                                            itemSelectedBg: '#52B0E3',
                                            darkItemBg: '#088ed7',
                                            darkSubMenuItemBg: '#088ed7',
                                            darkItemSelectedBg: '#52B0E3',
                                        },
                                        Tabs: {
                                            titleFontSizeLG: 16,
                                            titleFontSizeSM: 16,
                                            titleFontSize: 16
                                        }
                                    }
                                }}
                            >
                                <Layout style={{minHeight: '100vh'}}>
                                    <Sidebar/>
                                    <Layout className="site-layout" style={{maxHeight: "100vh"}}>
                                        <Content
                                            style={{
                                                padding: '8px',
                                                overflowY: "auto"
                                            }}
                                        >
                                            <Routes>
                                                <Route path="/" element={
                                                    <></>
                                                }/>
                                                <Route path="/config_tool" element={
                                                    <AuthorizedTemplate hasAnyRoles={['monitoring']}
                                                        unauthorizedComponent={<UnauthorizedScreen/>}>
                                                        <ConfigTool/>
                                                    </AuthorizedTemplate>
                                                }/>
                                                <Route path="/business_dashboard" element={
                                                    <AuthorizedTemplate hasAnyRoles={['business']}
                                                        unauthorizedComponent={<UnauthorizedScreen/>}>
                                                        <MonitoringDashboardB/>
                                                    </AuthorizedTemplate>
                                                }/>
                                                <Route path="/monitoring_dashboard" element={
                                                    <AuthorizedTemplate hasAnyRoles={['monitoring']}
                                                        unauthorizedComponent={<UnauthorizedScreen/>}>
                                                        <MonitoringDashboard/>
                                                    </AuthorizedTemplate>
                                                }/>
                                                <Route path="/anomaly_tool/*" element={
                                                    <AuthorizedTemplate hasAnyRoles={['anomaly']}
                                                        unauthorizedComponent={<UnauthorizedScreen/>}>
                                                        <AnomalyTool/>
                                                    </AuthorizedTemplate>
                                                }/>
                                                <Route path="/matching_tool" element={
                                                    <AuthorizedTemplate hasAnyRoles={['matching']}
                                                        unauthorizedComponent={<UnauthorizedScreen/>}>
                                                        <MatchingTool/>
                                                    </AuthorizedTemplate>
                                                }/>
                                                <Route path="/damage_tool" element={
                                                    <AuthorizedTemplate hasAnyRoles={['damage']}
                                                        unauthorizedComponent={<UnauthorizedScreen/>}>
                                                        <DamageTool/>
                                                    </AuthorizedTemplate>
                                                }/>
                                                <Route path="/map_tool" element={
                                                    <AuthorizedTemplate hasAnyRoles={['map']}
                                                        unauthorizedComponent={<UnauthorizedScreen/>}>
                                                        <MapTool/>
                                                    </AuthorizedTemplate>
                                                }/>
                                                <Route path="/similarity_tool" element={
                                                    <AuthorizedTemplate hasAnyRoles={['similarity']}
                                                        unauthorizedComponent={<UnauthorizedScreen/>}>
                                                        <SimilarityTool/>
                                                    </AuthorizedTemplate>
                                                }/>
                                                <Route path="/label_tool" element={
                                                    <AuthorizedTemplate hasAnyRoles={['similarity']}
                                                        unauthorizedComponent={<UnauthorizedScreen/>}>
                                                        <LabelTool/>
                                                    </AuthorizedTemplate>
                                                }/>
                                                <Route path="/other" element={
                                                    <div style={{width: "300px"}}>
                                                    </div>
                                                }/>
                                            </Routes>
                                        </Content>
                                        <Footer style={{textAlign: 'center', padding: '8px'}}>©{new Date().getFullYear()} carvaloo®</Footer>
                                    </Layout>
                                </Layout>
                            </ConfigProvider>
                        </AuthorizedTemplate>
                    }/>
                    <Route path="/auth_callback" element={
                        <AuthCallback/>
                    }/>
                    <Route path="/sign_out" element={
                        <SignOutScreen/>
                    }/>
                </Routes>
            </Router>
        </>
    );
}


export default App;
