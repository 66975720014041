import React, {useState, useRef, useCallback, useEffect} from 'react';

import {
    Button,
    Card,
    InputNumber,
    Select,
    Col,
    Modal,
    notification,
    Row,
    Typography,
    Input,
    Spin,
    Switch,
    Table,
    Descriptions, Divider
} from 'antd';

import Icon, {BorderInnerOutlined} from "@ant-design/icons";
import {FaInfoCircle, FaList} from 'react-icons/fa';
import axiosInstance from "services/axios";
import ImageRenderer from "../ImageRenderer/ImageRenderer";
import DescriptionSelectEdit from "../AnomalyTool/DescriptionSelectEdit";
import HistoryModal from "../DetailsCard/HistoryModal";

const {Search} = Input;
const {Title} = Typography;
const {Text} = Typography;


const AnomalyLabelCard = (props) => {

    const [data, setData] = useState(null);
    const [feedbackData, setFeedbackData] = useState(null);
    const [plotData, setPlotData] = useState(null);
    const [reference, setReference] = useState(null);
    const [referencePlotData, setReferencePlotData] = useState(null);
    const [neighbourPlotData, setNeighbourPlotData] = useState(null);
    const [imageHeight, setImageHeight] = useState(260); // Default height for 1080p
    const [historyModalOpen, setHistoryModalOpen] = useState(false);
    const [historyOpenCount, setHistoryOpenCount] = useState(0)

    const UAID = props.UAID

    useEffect(() => {
        const screenHeight = window.screen.height;
        if (screenHeight >= 1440) {
            // For 2K resolution or higher
            setImageHeight(450);
        }
        // You can add additional conditions for other resolutions if needed
    }, []);


    const handleConfirm = (key) => {

        const payload = {
            UAID: UAID,
            field: key,
        }

        axiosInstance.post('/api/anomaly_tool/feedback/confirm', payload)
            .then(res => {
                const confirmKey = key + '_confirmed'
                const confirmKeyDate = key + '_confirmed_date'
                setFeedbackData({
                    ...feedbackData,
                    [confirmKey]: true,
                    [confirmKeyDate]: new Date().toJSON()
                });
                notification['success']({
                        message: 'Confirmed ' + key,
                        duration: 1.5,
                        maxCount: 15
                    }
                );
            })
            .catch(error => {
                console.log(error);
                notification['error']({
                    message: 'Error confirming for ' + props.UAID,
                    description: error.message
                });
            })
    };

    const handleEditSave = (key, editedData) => {

        const payload = {
            UAID: UAID,
            field: key,
            value: editedData
        }

        notification['info']({
                message: 'Updating content for ' + key,
                duration: 2.5,
                maxCount: 15
            }
        );

        axiosInstance.post('/api/anomaly_tool/feedback/edit', payload)
            .then(res => {
                const confirmKey = key + '_confirmed'
                setFeedbackData({
                    ...feedbackData,
                    [key]: editedData,
                    [confirmKey]: false,
                    // Update other data properties as needed
                });
                notification['success']({
                        message: 'Updated content for ' + key,
                        duration: 2.5,
                        maxCount: 15
                    }
                );
            })
            .catch(error => {
                console.log(error);
                notification['error']({
                    message: 'Error updating content for ' + props.UAID,
                    description: error.message
                });
            })
    };


    const getDataValues = () => {
        axiosInstance.post(
            '/api/anomaly_tool/info',
            {
                UAID: UAID,
            })
            .then(res => {
                setData(res.data)
            })
            .catch(error => {
                console.log(error);
                setData(null)
                notification['error']({
                    message: 'Error in get AnomalyInfo',
                    description: (
                        <>
                            {error.message + ':'}<br/>
                            {error.response && error.response.data && error.response.data.message}
                        </>
                    ),
                });
            })

        axiosInstance.post(
            '/api/anomaly_tool/feedback',
            {
                UAID: UAID,
            })
            .then(res => {
                setFeedbackData(res.data)
            })
            .catch(error => {
                console.log(error);
                setFeedbackData(null)
                notification['error']({
                    message: 'Error in get AnomalyInfo',
                    description: (
                        <>
                            {error.message + ':'}<br/>
                            {error.response && error.response.data && error.response.data.message}
                        </>
                    ),
                });
            })

    }

    useEffect(() => {
        if (UAID !== null) {
            getDataValues();
        } else {
            setData(null)
        }
    }, [UAID]);

    const getDashboardValues = (payload) => {
        axiosInstance.post(
            '/api/anomaly_tool/plots',
            {
                UAID: payload,
                file_type: 'uaid',
            })
            .then(res => {
                setPlotData(res.data)
            })
            .catch(error => {
                console.log(error);
                setPlotData(null)
                notification['error']({
                    message: 'Error in get AnomalyPred',
                    description: (
                        <>
                            {error.message + ':'}<br/>
                            {error.response && error.response.data && error.response.data.message}
                        </>
                    ),
                });
            })
    }

    useEffect(() => {
        if (UAID !== null) {
            getDashboardValues(UAID);
        } else {
            setPlotData(null)
        }
    }, [UAID]);


    const onSearch = (value) => {
        if (value) {
            const valueStr = value.trim()
            const parts = valueStr.split("-");
            if (valueStr.includes("UAID") && parts.length === 3 && parts[2].length === 12) {
                setReference(valueStr)
            } else {
                notification['error']({
                        message: 'Not a valid UAID',
                        duration: 5
                    }
                );
            }
        } else {
            setReference(null)
        }
    }

    const getDashboardRefValues = (payload) => {
        axiosInstance.post(
            '/api/anomaly_tool/plots',
            {
                UAID: payload,
                file_type: 'uaid',
            })
            .then(res => {
                setReferencePlotData(res.data)
            })
            .catch(error => {
                console.log(error);
                setReferencePlotData(null)
                notification['error']({
                    message: 'Error in get AnomalyPred',
                    description: (
                        <>
                            {error.message + ':'}<br/>
                            {error.response && error.response.data && error.response.data.message}
                        </>
                    ),
                });
            })
    }

    useEffect(() => {
        if (reference !== null) {
            getDashboardRefValues(reference);
        } else {
            setReferencePlotData(null)
        }
    }, [reference]);


    const getNeighbourPlots = (uaids) => {
        const requestData = {
            UAID: uaids,
            file_type: 'uaid',
        };

        axiosInstance.post('/api/anomaly_tool/plots/batch', requestData)
            .then((response) => {
                setNeighbourPlotData(response.data);
            })
            .catch((error) => {
                console.log(error);
                setNeighbourPlotData(null);
                notification['error']({
                    message: 'Error in getNeighbourPlots',
                    description: (
                        <>
                            {error.message + ':'}<br/>
                            {error.response && error.response.data && error.response.data.message}
                        </>
                    ),
                });
            });
    };

    useEffect(() => {
        if (props.neighbours !== null) {
            const uaidsArray = props.neighbours.map(item => item.UAID);
            getNeighbourPlots(uaidsArray);
        } else {
            setNeighbourPlotData(null)
        }
    }, [props.neighbours]);

    const showHistoryModal = () => {
        setHistoryOpenCount(historyOpenCount + 1)
        setHistoryModalOpen(true);
    }

    const handleSelect = (event) => {
        props.setSelectedId(event)
    }


    const nameArray = [
        'EV_high', 'EV_none', 'EV_low', 'EV_wrong', 'PP', 'FP_high', 'FP_none', 'FP_low',
        'unsure', 'excluded', 'no_feedback', 'no_label']
    const colorArray = [
        'red', 'gold', 'darkorange', 'royalblue', 'violet', 'darkgreen', 'lightskyblue', 'limegreen',
        'black', 'brown', 'darkgray', 'gray']

    function colorNameToRgba(color, alpha) {
        const tempDiv = document.createElement('div');
        tempDiv.style.color = color;
        document.body.appendChild(tempDiv);
        const rgbaColor = window.getComputedStyle(tempDiv).color;
        document.body.removeChild(tempDiv);
        const [r, g, b] = rgbaColor.match(/\d+/g);
        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }

    return (<>
            {/*<Modal*/}
            {/*  open={loading}*/}
            {/*  closable={false}*/}
            {/*  footer={null}*/}
            {/*  centered={true}*/}
            {/*  mask={true}*/}
            {/*  maskClosable={false}*/}
            {/*  bodyStyle={{ backdropFilter: 'blur(3px)' }}*/}
            {/*>*/}
            {/*  <Spin size="large" />*/}
            {/*</Modal>*/}
            <HistoryModal
                showModal={() => setHistoryModalOpen(true)}
                hideModal={() => setHistoryModalOpen(false)}
                open={historyModalOpen}
                id={UAID}
                count={historyOpenCount}
            />
            <Card
                style={{
                    width: '100%',
                    height: '100%',
                    minHeight: 907,
                    boxSizing: 'border-box',
                    display: 'flex',
                    flexDirection: 'column',
                    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                    border: '1px solid #e8e8e8'
                }}
                bodyStyle={{padding: '5px', display: 'flex', flexDirection: "column", flex: "1 1 auto"}}>
                {/*<Title level={5} style={{marginTop: '4px', marginLeft: '8px'}}>Selected</Title>*/}
                <Descriptions bordered size={'small'}>
                    <Descriptions.Item label="UAID" style={{marginBottom: "0px"}}>
                        <a href={`https://matching.carvaloo.com/anomaly_tool/${props.UAID}`} target="_blank"
                           rel="noopener noreferrer">
                            {props.UAID || ''}
                        </a>
                    </Descriptions.Item>
                    <Descriptions.Item label="Comment">{feedbackData?.comment}</Descriptions.Item>
                </Descriptions>
                <Row>

                    <DescriptionSelectEdit
                        uaid={props.UAID}
                        width={140}
                        onSave={handleEditSave}
                        field={"matching_confidence"}
                        label={"Confidence"}
                        data={feedbackData?.matching_confidence}
                        showConfirm={true}
                        confirm={feedbackData?.matching_confidence_confirmed}
                        confirmDate={feedbackData?.matching_confidence_confirmed_date}
                        onConfirm={handleConfirm}
                        fixedValues={[
                            {value: 4, label: ": sure TP"},
                            {value: 3, label: ": plausible TP"},
                            {value: 2, label: ": possible TP"},
                            {value: 1, label: ": unlikely TP"},  // Add your new value here
                            {value: 0, label: ": unsure"},
                            {value: -1, label: ": unlikely FP"},
                            {value: -2, label: ": possible FP"},
                            {value: -3, label: ": plausible FP"},
                            {value: -4, label: ": sure FP"}
                        ]}
                    />
                    <Button onClick={showHistoryModal} icon={<FaList/>} style={{marginTop: 8, width: 44}}>
                        {'\xa0'}
                    </Button>
                </Row>
                <Row style={{marginTop: 12}}>
                    <Col span={24}>
                        <div style={{height: imageHeight}}>
                            <ImageRenderer value={plotData?.uaid_image} name={'uaid_image'}/>
                        </div>
                    </Col>
                    {/*<Col span={8}>*/}
                    {/*    <div style={{height: "180px"}}>*/}
                    {/*        <ImageRenderer value={plotData?.trip_image} name={'trip_image'}/>*/}
                    {/*    </div>*/}
                    {/*</Col>*/}
                </Row>
                {/*<Title level={5} style={{marginTop: '4px', marginLeft: '8px'}}>Neighbours</Title>*/}
                <Divider style={{marginBottom: "4px", marginTop: "4px", borderTop: '1px solid black'}}/>
                <div>
                    {props.neighbours && neighbourPlotData &&
                        <Row gutter={[16, 8]} style={{marginTop: 6}}>
                            {props.neighbours.map((item, index) => {
                                const labelIndex = nameArray.indexOf(item.label);
                                const borderColor = item.label === 'no_label' ? 'white' : colorArray[labelIndex];
                                const fillBox = neighbourPlotData[index]?.confirm_mc

                                return (
                                    <Col span={8} key={index}>
                                        <div>
                                            <ImageRenderer value={neighbourPlotData[index]?.uaid_image}
                                                           name={'uaid_image'}/>
                                        </div>
                                        <div style={{
                                            marginLeft: 16,
                                            marginRight: 16,
                                            fontSize: 10,
                                            border: `3px solid ${borderColor}`,
                                            background: fillBox ? colorNameToRgba(borderColor, 0.5) : '',
                                            paddingLeft: 8,
                                            borderRadius: 10
                                        }}>
                                            <a
                                                href={`https://matching.carvaloo.com/anomaly_tool/${item.UAID}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {item.UAID || ''}
                                            </a>
                                            {'\n'} {(item.distance * props.scalingFactor).toFixed(6)}
                                            <Button
                                                onClick={() => handleSelect(item.UAID)}
                                                style={{
                                                    marginLeft: 20,
                                                    borderColor: "white",
                                                    background: fillBox ? colorNameToRgba(borderColor, 0.3) : ''
                                                }}
                                                size={"small"}
                                                icon={<BorderInnerOutlined/>}
                                            >
                                                <Text style={{fontSize: 10}}>Select</Text>
                                            </Button>
                                        </div>
                                    </Col>
                                );
                            })}
                        </Row>
                    }
                </div>
                {/*<Title level={5} style={{marginTop: '4px', marginLeft: '8px'}}>Reference</Title>*/}
                {/*<Search*/}
                {/*    placeholder="set reference UAID"*/}
                {/*    onSearch={onSearch}*/}
                {/*    allowClear={true}*/}
                {/*    size={'large'}*/}
                {/*    style={{*/}
                {/*        width: 500,*/}
                {/*        marginTop: 4*/}
                {/*    }}*/}
                {/*/>*/}
                {/*<Row style={{marginTop: 8}}>*/}
                {/*    <Col span={24}>*/}
                {/*        <div style={{height: "280px"}}>*/}
                {/*            <ImageRenderer value={referencePlotData?.uaid_image} name={'uaid_image'}/>*/}
                {/*        </div>*/}
                {/*    </Col>*/}
                {/*    /!*<Col span={8}>*!/*/}
                {/*    /!*    <div style={{height: "180px"}}>*!/*/}
                {/*    /!*        <ImageRenderer value={plotData?.trip_image} name={'trip_image'}/>*!/*/}
                {/*    /!*    </div>*!/*/}
                {/*    /!*</Col>*!/*/}
                {/*</Row>*/}
                {/*<Row>*/}
                {/*  <Col span={16}>*/}
                {/*      <div style={{height:"180px"}}>*/}
                {/*          <ImageRenderer value={data?.alert_image} name={'alert_image'}/>*/}
                {/*      </div>*/}
                {/*  </Col>*/}
                {/*  <Col span={8}>*/}
                {/*    <div style={{height:"180px"}}>*/}
                {/*      <ImageRenderer value={data?.direction_image} name={'direction_image'}/>*/}
                {/*    </div>*/}
                {/*  </Col>*/}
                {/*</Row>*/}
            </Card>
        </>
    );
}

export default AnomalyLabelCard;
